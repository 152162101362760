import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { ISeason } from "src/act-common-web/src";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { SessionActions } from "src/app/state/session.state";
import { SeasonActions, SeasonState } from "src/app/state/season.state";
import { ITeam } from "src/act-common-web/src/models/team";
import { TeamState } from "src/app/state/teams.state";

@Component({
  selector: "app-session-modal",
  templateUrl: "./session-modal.component.html",
  styleUrls: ["./session-modal.component.scss"]
})
export class SessionModalComponent implements OnInit, OnDestroy {
  @Select(TeamState.current) currentTeam$!: Observable<ITeam>;
  @Select(SeasonState.current) currentSeason$!: Observable<ISeason>;

  teamId!: string; // Must be passed when launching
  seasonId!: string; // Must be passed when launching

  sessionForm!: FormGroup;
  showModal = false;

  destroy$ = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.route.params
      .pipe(
        tap(params => {
          this.teamId = params.teamId;
          this.seasonId = params.seasonId;

          if (this.seasonId) {
            this.store.dispatch(new SeasonActions.Select(this.teamId, this.seasonId));
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnInit(): void {
    // Initialize form with default values and validators
    this.sessionForm = new FormGroup({
      name: new FormControl(""),
      location: new FormControl(""),
      begin: new FormControl("0", Validators.required),
      end: new FormControl("60", Validators.required)
    });
    // Subscribe to changes in the 'begin' form control

    this.sessionForm
      .get("begin")
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(beginValue => {
        // Check if 'end' control is already set
        if (!this.sessionForm.get("end")?.value) {
          // Calculate the new end value based on the selected begin value
          const duration = parseInt(this.sessionForm.value.end ?? 60) * 60 * 1000;
          const newEndDate = new Date(Date.now() + duration + parseInt(beginValue) * 60 * 1000);

          // Update the end form control directly with the new value
          this.sessionForm.get("end")?.setValue(newEndDate);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  startSession() {
    const values = this.sessionForm.value;

    // Use the selected times from the dropdown
    this.store.dispatch(
      new SessionActions.Create(
        this.teamId,
        this.seasonId,
        values.name,
        values.location,
        this.beginDate,
        this.endDate
      )
    );
    this.closeModal();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  /** Returns calculated Begin Date Time of session */
  get beginDate(): Date {
    const beginAfter = parseInt(this.sessionForm.value.begin ?? 0) * 60 * 1000;
    return new Date(Date.now() + beginAfter);
  }

  /**
   * Returns calculated End Date Time of session
   *
   * @readonly
   * @type {Date}
   * @memberof SessionModalComponent
   */
  get endDate(): Date {
    const duration = parseInt(this.sessionForm.value.end ?? 60) * 60 * 1000;
    const calculatedDate = new Date(
      Date.now() + duration + parseInt(this.sessionForm.value.begin) * 60 * 1000
    );
    // Check if the calculated date is valid
    if (!isNaN(calculatedDate.getTime())) {
      // This was added due to date was invalid via subscription
      return calculatedDate;
    } else {
      // Use the current date and time as a default value
      return new Date();
    }
  }
}
