import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { BaseActions, BaseState, BaseStateModel } from "./base-state";
import { IDocument } from "src/act-common-web/src/index";
import { AuthService } from "../services/auth.service";
import { DocumentService } from "../services/document.service";

// tslint:disable-next-line: no-namespace
export namespace DocumentActions {
  export class Select extends BaseActions.Select {
    static readonly type = "[Document] Select";
  }

  export class FetchDocuments {
    static readonly type = "[Document] Fetch Documents";
    constructor() {}
  }
}

interface DocumentStateModel extends BaseStateModel<IDocument> {
  current?: string;
  list: Array<IDocument>;
}

@State<DocumentStateModel>({
  name: "document",
  defaults: {
    current: undefined,
    list: [] // null
  }
})
@Injectable()
export class DocumentState extends BaseState<IDocument, DocumentStateModel> {
  constructor(
    public documentService: DocumentService,
    public store: Store,
    public authService: AuthService
  ) {
    super();
  }

  @Selector()
  static list(state: DocumentStateModel): Array<IDocument> {
    return state.list ?? [];
  }

  @Selector()
  static current(state: DocumentStateModel) {
    return state.list.find(d => d.id === state.current);
  }

  @Action(DocumentActions.Select)
  selectDocument(ctx: StateContext<DocumentStateModel>, action: DocumentActions.Select): void {
    this.select(ctx, action.id);
  }

  @Action(DocumentActions.FetchDocuments)
  fetchDocuments(ctx: StateContext<DocumentStateModel>, action: DocumentActions.Select) {
    return this.documentService.listQueryDocuments().then(documents => {
      const docs =
        documents.map(doc => {
          return {
            ...doc,
            id: doc.id
          };
        }) ?? [];
      ctx.patchState({
        list: docs
      });
      console.log("We got documents", docs);
    });
  }
}
