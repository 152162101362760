import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { User } from "firebase/auth";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { IProfile, ISession } from "src/act-common-web/src";
import { ISeason } from "src/act-common-web/src/models/season";
import { AuthService } from "src/app/services/auth.service";
import { ProfileService } from "src/app/services/profile.service";
import { SeasonActions, SeasonState } from "src/app/state/season.state";
import { SessionState } from "src/app/state/session.state";

@Component({
  selector: "app-seasons",
  templateUrl: "./seasons.component.html",
  styleUrls: ["./seasons.component.scss"]
})
export class SeasonsComponent implements OnInit, OnDestroy {
  @Select(SeasonState.current) seasons$?: Observable<ISeason | undefined>;
  sessions$?: Observable<Array<ISession> | undefined>;

  modalRef: BsModalRef | undefined;

  profiles$: Observable<Array<IProfile>> | undefined;
  user$: Observable<User | null> | undefined;
  teamId?: string;
  seasonId?: string;
  season?: ISeason;

  seasonForm: FormGroup;
  editMode = false;
  editing = false;
  nameError?: string;
  startError?: string;
  endError?: string;
  destroy$ = new Subject();

  seasonHasStarted = false;
  seasonHasEnded = false;

  constructor(
    private router: Router,
    public store: Store,
    public authService: AuthService,
    public profileService: ProfileService,
    public route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.route.params
      .pipe(
        tap(params => {
          this.teamId = params.teamId;
          this.seasonId = params.seasonId;
          this.store.dispatch(new SeasonActions.Select(this.teamId, this.seasonId));
          // Select sessions for this season
          if (this.seasonId) {
            this.sessions$ = this.store.select(SessionState.listByParent(this.seasonId));
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Init Form
    this.seasonForm = new FormGroup({
      name: new FormControl("", Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    // Watch for selected season
    this.seasons$
      ?.pipe(
        tap(season => {
          if (season) {
            this.season = season;
            this.editMode = true;
            this.editing = false;
            this.seasonForm?.setValue({
              name: season?.name,
              startDate: season?.begin?.toDate(),
              endDate: season?.end?.toDate()
            });

            this.seasonHasStarted = (this.season.begin?.toMillis() ?? 0) < Date.now();
            this.seasonHasEnded = (this.season.end?.toMillis() ?? 0) < Date.now();
            // Disable Start Date, if season has already begun
            if (this.seasonHasStarted) {
              this.seasonForm.get("startDate")?.disable();
            }
            // Disable End Date, if season has already ended
            if (this.seasonHasEnded) {
              this.seasonForm.get("endDate")?.disable();
            }
            this.seasonForm.disable();
          } else {
            this.editMode = false;
            this.editing = false;
            this.seasonForm.enable();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * If user wants to cancel creating team
   * @returns User back to dasboard page
   */
  cancel() {
    const confirmExit = confirm(
      "Are you sure you want to leave? Any unsaved changes will be lost."
    );
    if (confirmExit) {
      this.router.navigate(["/teams"]);
    } else {
      return;
    }
  }

  /**
   * Season Form Submit
   * Creates or Updates the season
   */

  submit() {
    const values = this.seasonForm.value;

    if (!this.editMode) {
      this.store.dispatch(
        new SeasonActions.Create(this.teamId!, values.name, values.startDate, values.endDate)
      );
    } else if (this.season) {
      this.store.dispatch(
        new SeasonActions.Update(
          this.season.id,
          this.teamId!,
          values.name,
          values.startDate,
          values.endDate
        )
      );
    }
  }

  /**
  * Removes whole season with its sessions
  Opens a modal*/

  openModal(template: TemplateRef<unknown>) {
    if (this.teamId && this.seasonId) {
      const config = {
        initialState: {
          teamId: this.teamId,
          seasonId: this.seasonId
        }
      };
      this.modalRef = this.modalService.show(template, config);
    }
  }

  deleteSeason() {
    if (this.teamId && this.seasonId) {
      this.store.dispatch(new SeasonActions.Remove(this.teamId, this.seasonId));
    }
    this.modalRef?.hide;
  }

  /** Returns relative link to back, if in editmode 2 steps if in create only one step */
  get backLink(): string {
    return this.editMode ? "../.." : "../";
  }

  /** Min Date for Season Begin, Today for edit mode */
  get seasonBeginMinDate() {
    return this.editMode && this.seasonHasStarted
      ? undefined
      : new Date(new Date().setHours(0, 0, 0, 0));
  }

  get seasonEndMinDate() {
    return this.editMode && this.seasonHasEnded
      ? undefined
      : new Date(Math.max(this.seasonBegin?.getTime() ?? 0, Date.now()));
  }

  get seasonBegin() {
    return this.seasonForm?.value?.startDate;
  }

  get seasonEnd() {
    return this.seasonForm?.value?.endDate;
  }

  sessionActive(session: ISession): boolean {
    return !this.sessionEnded(session) && (session.begin?.toMillis() ?? 0) < Date.now();
  }

  sessionEnded(session: ISession): boolean {
    return (session.end?.toMillis() ?? 0) < Date.now();
  }

  toggleEditMode() {
    this.editing = !this.editing;
    if (this.editing) {
      this.seasonForm?.enable(); // Enable the form when toggling to edit mode
    } else {
      this.seasonForm?.disable(); // Disable the form when toggling out of edit mode
    }
  }

  // eslintters suggestion to handle key

  handleKeyDown(event: KeyboardEvent, template: TemplateRef<unknown>) {
    if (event.key === "Enter" || event.key === "Space") {
      // Trigger the same action as the click event
      this.openModal(template);
    }
  }
}
