<div class="card">
  <div class="card-body justify-content-center">
    <p class="card-text d-flex justify-content-center" *ngIf="session && isActiveSession">
      Current session: {{ session.begin?.toDate() | date: "mediumTime" }} -
      {{ session.end?.toDate() | date: "mediumTime" }}
    </p>
    <p class="card-text d-flex justify-content-center" *ngIf="!isActiveSession && isActiveSeason">
      <span
        >No sessions running right now.&nbsp;
        <span *ngIf="nextSession"
          >Next session is scheduled to begin at {{ nextSession.begin | fbdate | date: "medium" }}
        </span>
      </span>
    </p>
    <p class="card-text d-flex justify-content-center" *ngIf="!isActiveSeason">No active season</p>
    <div class="d-flex justify-content-center">
      <!-- Button to Open the Modal -->
      <button
        type="button"
        class="btn btn-primary round-button mx-2"
        (click)="openModal()"
        [disabled]="isActiveSession || !isActiveSeason">
        <i class="bi bi-play-fill"></i>
      </button>
      <!-- Will be implemented later
      <button class="btn btn-primary round-button mx-2" (click)="pause()" [disabled]="!isActiveSession">
        <i class="bi bi-pause-fill"></i>
      </button>
      -->
      <button
        class="btn btn-primary round-button mx-2"
        (click)="stop()"
        [disabled]="!isActiveSession">
        <i class="bi bi-stop-fill"></i>
      </button>
    </div>
    <!--Schedule session, this opens modal!-->

    <!--<p class="card-text" style="text-align: center;">Press play to start session</p> -->
    <!-- Will be implemented later <a [routerLink]="['seasons', season?.id, 'sessions', session?.id]">
      <i class="bi-arrow-right"></i> Jump to live session
    </a> -->
  </div>
</div>
