<div class="card" [routerLink]="profile?.id" style="min-height: 430px">
  <div class="card-header d-flex">
    <strong>{{ profile?.name }}</strong>
  </div>

  <div class="card-body d-flex flex-row justify-content-center">
    <div>
      <div class="force" *ngIf="profile?.lastEntry?.force">
        {{ profile?.lastEntry?.laStats?.initialHigh || profile?.lastEntry?.force | number: "1.0-0"
        }}<span class="force-ind">g</span>
      </div>
      <div class="angular-force" *ngIf="profile?.lastEntry?.avStats?.initialHigh">
        {{ profile?.lastEntry?.avStats?.initialHigh | unitConvert | number: "1.0-0"
        }}<span class="angular-force-ind">rad/s</span>
      </div>
      <div class="timestamp">{{ profile?.lastEntry?.timestamp?.toDate() | date: "medium" }}</div>
      <div class="strong" *ngIf="!profile?.lastEntry?.force">No impacts recorded</div>
    </div>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <div class="sensor-name">{{ profile?.lastEntry?.sensorName }}</div>
    <div class="sport-name">{{ profile?.lastEntry?.sport }}</div>
  </div>
</div>
