<!-- <p>login-email works!</p> -->

<div class="userauth-form">
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-sm-9 col-md-6">
        <div class="card">
          <form [formGroup]="loginForm" (submit)="onLogin()">
            <div class="card-body">
              <h1 class="card-title">Log-in to your ACT-account</h1>

              <!-- Login Form -->

              <!-- Error -->
              <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>

              <div class="form-group">
                <label for="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Enter email"
                  formControlName="email" />
                <!-- aria-describedby="emailHelp" -->
                <!-- hints
                      <small id="emailHelp"
                            class="form-text text-muted">We'll never share your email
                      </small> -->
              </div>

              <div class="form-group">
                <label for="password">Password</label>
                <input
                  type="password"
                  name="password"
                  class="form-control"
                  placeholder="Enter password"
                  formControlName="password" />
              </div>

              <button
                type="submit"
                class="btn btn-primary btn-block"
                [disabled]="loginForm.invalid">
                Log in
              </button>

              <!-- TODO: Forgot password
              <div class="form-group text-center">
                <button href="#" type="submit" class="btn btn-secondary"
                        (click)="onResetPassword('formData')">Forgot password?</button>
              </div> -->

              <div class="mt-4">
                <a routerLink="/register" routerLinkActive="active" class="move-away-link">
                  <div>
                    <strong>
                      <span>Need an ACT-account? </span>
                      <span> Register here!</span>
                    </strong>
                  </div>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
