import { FBTimestamp } from "./../../firebase-types";
// Angular/app
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// AngularFire/base

import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
  browserLocalPersistence,
  User
} from "@angular/fire/auth";
// RXJS
import { of, Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
// Models
import { Store } from "@ngxs/store";
import { OnEvent } from "../state/events";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public actUser$: Observable<User | undefined>; // ACT/user (Obs)
  private authState: any = null; // Fb-auth/authState
  private uid = ""; // Fb-auth/uid

  public userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private afAuth: Auth,
    private router: Router,
    private store: Store
  ) {
    this.actUser$ = of(undefined);

    // Auth persistence
    this.setPersistence("SESSION");
    // Track auth state changes
    this.afAuth.onAuthStateChanged(auth => {
      console.log("Auth State Changed", auth);
      if (auth) {
        this.authState = auth;
        this.uid = auth.uid;
        this.userSubject.next(auth);
        // This could be optimized away?
        this.user$.subscribe(user => {
          if (user) {
            this.userSubject.next(user);
          }
        });
        // Call User Logged In Action to start initialization in states
        this.store.dispatch(new OnEvent.UserLoggedIn(this.uid));
      } else {
        this.authState = null;
        this.actUser$ = of(undefined);
        this.userSubject.next(null);
      }
    });
  }

  /**
   * Auth persistence method defined outside of the constructor
   *
   * @memberof AuthService
   */
  public async setPersistence(type: "SESSION" | "LOCAL"): Promise<void> {
    const persistence = type === "SESSION" ? browserSessionPersistence : browserLocalPersistence;
    try {
      await setPersistence(this.afAuth, persistence);
    } catch (error) {
      console.error("Error setting persistence:", error);
    }
  }

  /**
   * Login with email and password
   * @param email
   * @param password
   * @returns Promise of UserCredential
   */
  async login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password)
      .then(res => {
        const currentUser = res.user;
        if (currentUser) {
          //console.log('Current user UID:', currentUser.uid);
        } else {
          console.log("No authenticated user found");
        }
        return res.user;
      })
      .catch(err => {
        console.error(
          ".login();Could not log in user (w/ email):%s",
          email + "\nerror:" + err.message
        );
        throw err;
      });
  }

  /**
   * Register a new user with email and password
   * @param email
   * @param password
   * @returns Promise of UserCredential
   */
  register(email: string, password: string) {
    return createUserWithEmailAndPassword(this.afAuth, email, password)
      .then(res => {
        return res.user;
      })
      .catch(err => {
        console.log("Could not register user (w/ email):%s", email + "\nerror:", err.message);
        throw err;
      });
  }

  /**
   * Logout from firebase
   * @param email
   * @param password
   * @returns Promise of UserCredential
   */
  logout() {
    this.afAuth.signOut().then(() => {
      this.uid = "";
      this.authState = null;
    });
  }

  // returns a Promise of authState
  async isLoggedIn(): Promise<any> {
    const user = this.afAuth.currentUser;
    return user;
  }

  // Check if user is authenticated
  get isAuthenticated(): boolean {
    return !!this.uuid && !!this.authState;
  }
  // Get user uid
  get uuid(): string {
    return this.uid;
  }

  // Get User
  get user$(): Observable<User | null> {
    return authState(this.afAuth).pipe(
      tap(usr => {
        //console.log(usr);
      })
      /*mergeMap((user) => {
        console.log("Got User", user);
        if (user) {
          const userDoc: DocumentReference<User> = doc(
            this.afs,
            `users/${user.uid}`
          ) as DocumentReference<User>;
          return getDoc(userDoc).then(
            (documentSnapshot: DocumentSnapshot<User>) => {
              return docData(userDoc);
            }
          );
        } else {
          return of(null);
        }
      }),*/
      /*map((data) => {
        return data
        return data ? {
          id: data.uid,
          loginId: data.email,
          name: data.email,
          email: data.email,
          displayName: data.displayName ?? data.email,
          lastAcceptance: null,
          acceptances: null,
          country: null,
          photoURL: data.photoURL,
          emailVerified: data.emailVerified,
          phoneNumber: data.phoneNumber
        } : null;
      }) // Map DocumentData to User type*/
    );
  }

  /** Returns current user as subject */
  get currentUser(): BehaviorSubject<User | null> {
    return this.userSubject;
  }

  /**
   * Reset Password for user
   * @param email
   * @returns
   */
  resetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email, {
      url: "http://localhost:4200/auth"
    })
      .then(() => {
        console.log("Sent password reset email:", email);
        this.router.navigate(["auth/reset-confirm"]);
      })
      .catch(err => {
        console.log("Could not send reset password link", err.message);
      });
  }
}
