import { Pipe, PipeTransform } from "@angular/core";

/** Simple pipe that takes first element */

@Pipe({
  name: "first"
})
export class FirstPipe implements PipeTransform {
  transform<T>(value?: Array<T>, ...args: unknown[]): T | undefined {
    return value?.[0];
  }
}
