import { DocumentActions } from "src/app/state/document-state";
import { Component } from "@angular/core";
import { Store } from "@ngxs/store";

import { environment } from "./../environments/environment";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "ACT-dasboard";
  subTitle = "WebUi (" + (environment.production ? "production" : "development") + ")";

  TAG = "AppComponent";

  constructor(
    private store: Store,
    public authService: AuthService
  ) {
    console.log(
      this.TAG + ".();",
      "title:'" + this.title + "'",
      "subTitle:'" + this.subTitle + "'"
    );

    console.log(
      this.TAG + ".();",
      "appVersion:" + environment.appVersion + ", ",
      "production:" + environment.production
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new DocumentActions.FetchDocuments());
  }
}
