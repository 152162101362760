import { DocumentPageComponent } from "./components/documents/document-page.component";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routing
import { AppRoutingModule } from "./app-routing.module";

// State
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";

import { UserState } from "./state/user-state";
import { ProfileState } from "./state/profile-state";
import { TeamState } from "./state/teams.state";
import { SeasonState } from "./state/season.state";
import { SessionState } from "./state/session.state";

// Misc
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Components
import { AppComponent } from "./app.component";
import { AboutComponent } from "./components/about/about.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { LoginEmailComponent } from "./components/login-email/login-email.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { RegisterComponent } from "./components/register/register.component";
import { UserReportComponent } from "./components/user-report/user-report.component";
import { ExportPageComponent } from "./components/export-page/export-page.component";
import { ProfilePageComponent } from "./components/profile-page/profile-page.component";
import { EntryPageComponent } from "./components/entry-page/entry-page.component";
import { SettingsPageComponent } from "./components/settings/settings-page/settings-page.component";
import { ProfileCardComponent } from "./components/dashboard/profile-card/profile-card.component";
import { TeamsComponent } from "./components/teams/teams/teams.component";
import { SeasonsComponent } from "./components/seasons/seasons/seasons.component";
import { SessionsComponent } from "./components/sessions/sessions/sessions.component";
import { TeamCardComponent } from "./components/dashboard/team-card/team-card.component";
import { TeamsPageComponent } from "./components/teams-page/teams-page/teams-page.component";
import { SessionCardComponent } from "./components/teams-page/teams-page/session-card/session-card/session-card.component";
import { PlayerListComponent } from "./components/teams-page/teams-page/player-list/player-list/player-list.component";
import { StrengthCardComponent } from "./components/teams-page/teams-page/strength-statistic/strength-card/strength-card.component";
import { ImpactCardComponent } from "./components/teams-page/teams-page/impact-statistic/impact-card/impact-card.component";
import { TrendsCardComponent } from "./components/teams-page/teams-page/trends-statistic/trends-card/trends-card.component";
import { TeamStatisticsComponent } from "./components/teams-page/teams-page/team-statistics/team-statistics.component";
import { NewteamcardComponent } from "./components/dashboard/new-team-card/newteamcard/newteamcard.component";
import { LiveSessionComponent } from "./components/teams-page/teams-page/live-session/live-session/live-session.component";
import { ImpactTimelineComponent } from "./components/teams-page/teams-page/impact-statistic/impact-card/impact-timeline/impact-timeline/impact-timeline.component";
import { PlayerPageComponent } from "./components/teams-page/teams-page/player-page/player-page/player-page.component";

// Environment => TODO: not needed here?
import { environment } from "../environments/environment";
import { EntryState } from "./state/entry-state";
import { FbdatePipe } from "./pipes/fbdate.pipe";

import { NgxEchartsModule } from "ngx-echarts";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { DocumentState } from "./state/document-state";
import { UnitConvertPipe } from "./pipes/unit-convert.pipe";
// Bluetooth plugin
import { BluetoothpluginTestComponent } from "./components/bluetooth-test/bluetoothplugin-test/bluetoothplugin-test.component";

// Emulator // AngularFire
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { connectStorageEmulator, getStorage, provideStorage } from "@angular/fire/storage";
import { provideFirestore, getFirestore, connectFirestoreEmulator } from "@angular/fire/firestore";
import { provideFunctions, getFunctions, connectFunctionsEmulator } from "@angular/fire/functions";
import { provideAuth, connectAuthEmulator, getAuth } from "@angular/fire/auth";
import { AuthGuardModule } from "@angular/fire/auth-guard";
import { CustomAuthGuard } from "./auth-guard/custom.auth.guard";

// NGX-BOOTSTRAP
import { ModalModule } from "ngx-bootstrap/modal";
import { SessionModalComponent } from "./components/teams-page/teams-page/modal/session-modal/session-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { FirstPipe } from "./pipes/first.pipe";
import { MaxPipe } from "./pipes/max.pipe";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { ImpactListComponent } from "./components/teams-page/teams-page/player-page/player-page/impact-list/impact-list/impact-list.component";
import { PlayerStatisticsComponent } from "./components/teams-page/teams-page/player-page/player-page/player-statistics/player-statistics/player-statistics.component";

import { RemoveAccountPageComponent } from "./components/settings/remove-account-page/remove-account-page.component";

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    DashboardComponent,
    LoginEmailComponent,
    NavbarComponent,
    RegisterComponent,
    UserReportComponent,
    ExportPageComponent,
    ProfilePageComponent,
    EntryPageComponent,
    SettingsPageComponent,
    ProfileCardComponent,
    DocumentPageComponent,
    FbdatePipe,
    UnitConvertPipe,
    BluetoothpluginTestComponent,
    TeamsComponent,
    SeasonsComponent,
    SessionsComponent,
    TeamCardComponent,
    TeamsPageComponent,
    SessionCardComponent,
    PlayerListComponent,
    StrengthCardComponent,
    ImpactCardComponent,
    TrendsCardComponent,
    TeamStatisticsComponent,
    NewteamcardComponent,
    LiveSessionComponent,
    ImpactTimelineComponent,
    SessionModalComponent,
    FirstPipe,
    MaxPipe,
    PlayerPageComponent,
    ImpactListComponent,
    PlayerStatisticsComponent,
    RemoveAccountPageComponent
  ],

  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, "localhost", 8080);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions(undefined, "europe-west3");
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }
      return functions;
    }),

    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, "localhost", 5555);
      }
      return storage;
    }),

    AuthGuardModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts")
    }),
    NgxsModule.forRoot(
      [UserState, ProfileState, EntryState, DocumentState, TeamState, SeasonState, SessionState],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: ["user" /*, 'profile' */] // Entries & Profiles  cannot be stored.
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TimepickerModule.forRoot()
  ],
  providers: [CustomAuthGuard, BsModalService],
  bootstrap: [AppComponent]
})
export class AppModule {}
