import { Observable, Subscription } from "rxjs";
import { ISeason } from "src/act-common-web/src/index";
import { Injectable } from "@angular/core";
import { BaseModelService } from "./base-model.service";
import { tap } from "rxjs/operators";
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class SeasonService extends BaseModelService<ISeason, { teamId: string }> {
  protected collectionName = "seasons";

  protected converter: FirestoreDataConverter<ISeason> = {
    toFirestore(season: ISeason): DocumentData {
      return season;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): ISeason {
      const data = snapshot.data(options)!;
      return {
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        name: data["name"],
        //description: data['description'],
        begin: data["begin"],
        end: data["end"],
        seasonStats: data["seasonStats"],
        monthStats: data["monthStats"]
      };
    }
  };

  protected extractCollectionPath(parentIds: { teamId: string }): string[] {
    return [`teams/${parentIds.teamId}/seasons`]; //path based on Firestore structure
  }
}
