<div class="container-lg">
  <div class="d-flex justify-content-between align-items-center">
    <a [routerLink]="backLink" class="navigation">
      <i class="bi bi-arrow-left"></i>
      Back to season
    </a>
    <a
      *ngIf="editMode"
      class="navigation"
      (click)="openModal(template)"
      (keydown)="handleKeyDown($event, template)"
      tabindex="0">
      <i class="bi bi-trash3"></i>
    </a>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="mt-4">
        <div class="card">
          <div class="card-body" [formGroup]="sessionForm">
            <h1 class="title">
              {{ editMode ? "Manage session" : "Create a new session" }}
            </h1>
            <!-- Session Name -->
            <div class="row mb-2 mt-3">
              <div class="col">
                <label for="name">Name</label>
                <input type="text" id="name" formControlName="name" class="form-control" />
              </div>
            </div>

            <!-- Location -->
            <div class="row mb-2 mt-2">
              <div class="col">
                <label for="location">Location</label>
                <input type="text" id="location" formControlName="location" class="form-control" />
              </div>
            </div>

            <!-- Day -->
            <div class="row mb-2 mt-2">
              <div class="col">
                <label for="day">Session date</label>
                <input
                  id="day"
                  class="form-control"
                  #datepickerYMD="bsDatepicker"
                  bsDatepicker
                  formControlName="day"
                  placeholder="Select session date:"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                  [minDate]="seasonBegin"
                  [maxDate]="seasonEnd" />
              </div>
            </div>

            <div class="row mb-2 mt-2">
              <!-- Begins at -->
              <div class="col">
                <label for="begin">Start time</label>
                <timepicker
                  formControlName="begin"
                  required
                  ngDefaultControl
                  [min]="dayStart"
                  [max]="sessionEnd"></timepicker>
              </div>

              <!-- Ends at -->
              <div class="col">
                <label for="end">End time</label>
                <timepicker
                  formControlName="end"
                  required
                  ngDefaultControl
                  [min]="sessionBegin"
                  [max]="dayEnd"></timepicker>
              </div>
            </div>
            <!-- Repeat checkboxes
                <h2>Repeat session:</h2>
                <div class="row mb-2">
                  <div class="col col-12 col-md-4">
                    <div class="col col-md-8">
                      <label for="repeatCheckBox">
                        <div *ngFor="let value of repeatCheckValues">
                          <div>
                            <input
                              type="radio"
                              [id]="'repeatCheckBox' + value"
                              formControlName="repeatCheckBox"
                              [value]="value"
                            />
                            {{ value }}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div> -->

            <!-- Buttons inside the card -->
            <div class="row mb-2">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-secondary ml-2" [routerLink]="backLink">Cancel</button>
                <button
                  class="btn btn-primary ml-2"
                  (click)="submit()"
                  [disabled]="sessionForm.invalid">
                  {{ editMode ? "Save session" : "Create a session" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-header">
    <h4 class="header">Delete Session</h4>
  </div>
  <div class="modal-body">
    <p *ngIf="!sessionHasEnded">Are you sure you want to delete this session?</p>
    <p *ngIf="sessionHasEnded">Cannot delete an ended session.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef?.hide()">Close</button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="deleteSession()"
      [disabled]="sessionHasEnded">
      Delete
    </button>
  </div>
</ng-template>
