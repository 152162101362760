import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import {
  patch,
  append,
  removeItem,
  insertItem,
  updateItem,
  StateOperator
} from "@ngxs/store/operators";

export type Identifiable = {
  id: string;
};

// tslint:disable-next-line: no-namespace
export namespace BaseActions {
  export class Clear {
    constructor() {}
  }

  export class Update<I extends Identifiable> {
    constructor(public model: I) {}
  }

  export class Select {
    constructor(public id: string) {}
  }
}

export interface BaseStateModel<I extends Identifiable> {
  current?: string;
  list?: Array<I>;
}

@Injectable()
export abstract class BaseState<T extends Identifiable, S extends BaseStateModel<T>> {
  update(ctx: StateContext<S>, action: BaseActions.Update<T>): void {
    this.modelUpdated(ctx, action.model);
  }

  /** Updates Data in state, called when some model is updated */
  modelUpdated(ctx: StateContext<S>, model: T): void {
    const state = ctx.getState();

    const newList = [...(state.list ?? [])];
    const oldIdx = newList.findIndex(d => d.id === model.id);

    if (oldIdx >= 0) {
      newList.splice(oldIdx, 1, model);
    } else {
      newList.push(model);
    }

    ctx.patchState({
      list: newList
    } as S);
  }

  partialUpdate(ctx: StateContext<S>, id: string, updateFunc: (model: T) => T) {
    const state = ctx.getState();

    const current = state.current;
    if (current === id) {
      //current = updateFunc(current);
    }

    const newList = [...(state.list ?? [])];
    const oldIdx = newList.findIndex(d => d.id === id);
    const old = newList.find(d => d.id === id);

    if (oldIdx >= 0 && old) {
      newList.splice(oldIdx, 1, updateFunc(old));
    }

    ctx.setState({
      current,
      list: newList
    } as S);
  }

  clearList(ctx: StateContext<S>): void {
    ctx.patchState({
      list: undefined
    } as S);
  }

  clearSelection(ctx: StateContext<S>): void {
    ctx.patchState({
      current: undefined
    } as S);
  }

  select(ctx: StateContext<S>, id?: string): void {
    ctx.patchState({
      current: id
    } as S);
  }
}
