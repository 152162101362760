export type GUID = string;

export class Guid {
  static newGuid() {
    return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static isGUID(value: string): boolean {
    if (!value) {
      return false;
    }
    const guidString =
      /^[a-zA-Z-0-9-]{8}[-][a-zA-Z-0-9-]{4}[-][a-zA-Z-0-9-]{4}[-][a-zA-Z-0-9-]{4}[-][a-zA-Z-0-9-]{12}$/;
    return guidString.test(value) ? true : false;
  }
}
