import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { ISeason, ISession } from "src/act-common-web/src";
import { ITeam } from "src/act-common-web/src/models/team";
import { EntryActions } from "src/app/state/entry-state";
import { SeasonState } from "src/app/state/season.state";
import { SessionState } from "src/app/state/session.state";
import { TeamActions, TeamState } from "src/app/state/teams.state";

@Component({
  selector: "app-teams-page",
  templateUrl: "./teams-page.component.html",
  styleUrls: ["./teams-page.component.scss"]
})
export class TeamsPageComponent implements OnInit, OnDestroy {
  @Select(TeamState.current) team$?: Observable<ITeam | undefined>;
  @Select(SeasonState.ongoing) ongoingSeason$?: Observable<ISeason | undefined>;
  seasons$?: Observable<Array<ISeason>>;
  @Select(SessionState.active) session$?: Observable<ISession | undefined>;
  @Select(SessionState.next) nextSession$?: Observable<ISession | undefined>;

  teamId: string | undefined;
  seasonId: string | undefined;
  teamData: ITeam | undefined;
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        tap(params => {
          this.teamId = params?.teamId;
          this.seasonId = params?.seasonId;
          this.store.dispatch(new TeamActions.Select(this.teamId));
          // Select all seasons for Team
          if (this.teamId) {
            this.seasons$ = this.store.select(SeasonState.listByParent(this.teamId));
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  generateImpact() {
    this.store.dispatch(new EntryActions.DeveloperGenerateImpact("sensor"));
  }
}
