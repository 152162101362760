import { IBaseModel } from "src/act-common-web/src/models/base-model";
import { Injectable } from "@angular/core";
import {
  Firestore,
  collection,
  query,
  where,
  limit,
  getDocs,
  QueryDocumentSnapshot,
  onSnapshot,
  Unsubscribe,
  CollectionReference,
  FirestoreDataConverter,
  SnapshotOptions,
  DocumentReference,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  UpdateData,
  DocumentData,
  orderBy
} from "@angular/fire/firestore";
import { AuthService } from "./auth.service";
import { Store } from "@ngxs/store";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export abstract class BaseModelService<T extends IBaseModel, P> {
  /**
   * Converts IBaseModel based model to Document Data
   */
  static toDocumentData<S extends IBaseModel>(model: S): DocumentData {
    const result: any = { ...model };
    delete result.id;
    return result;
  }

  static fromBaseModelFields(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IBaseModel {
    const data = snapshot.data(options)!;

    return {
      id: snapshot.id,
      owner: data["owner"],
      sharedTo: data["sharedTo"],
      parent: data["parent"],
      created: data["created"],
      updated: data["updated"]
    };
  }

  protected abstract collectionName: string;
  private listenSubscription?: Subscription;
  private listenUnsubscribe: Unsubscribe | undefined;

  constructor(
    protected readonly authService: AuthService,
    protected readonly afs: Firestore,
    protected readonly store: Store
  ) {}

  /** Converter that converts Document Data <-> T */
  protected abstract converter: FirestoreDataConverter<T>;

  /**
   * Function that Handles P type of parameters to array
   * @returns array of parent type and it's ids
   */
  protected abstract extractCollectionPath(parentIds: P): Array<string>;

  /** Returns Collection Ref with given parentIds */
  protected getCollectionRef(parentIds: P): CollectionReference<T> {
    const path = this.extractCollectionPath(parentIds);
    return collection(
      this.afs,
      path[0], // Take First as Root Collection
      ...path.slice(1) // Take Rest of Array
    ).withConverter(this.converter);
  }

  /** Returns Document Ref with given parentIds */
  protected getDocumentRef(id: string, parentIds: P): DocumentReference<T> {
    const path = this.extractCollectionPath(parentIds);
    return doc(
      this.afs,
      path[0], // Take First as Root Collection
      ...path.slice(1), // Take Rest of Array
      id // Document Id as last
    ).withConverter(this.converter);
  }

  /** Gets Single Document with Doc Id */
  public get(docId: string, parentIds: P) {
    const docRef = this.getDocumentRef(docId, parentIds);
    return getDoc(docRef);
  }

  /** Queries List of Documents in given path */
  public list(parentIds: P) {
    const collectionRef = this.getCollectionRef(parentIds);
    const q = query(collectionRef, where("sharedTo", "array-contains", this.authService.uuid));
    return getDocs(q);
  }

  /** Creates an new document */
  public add(model: T, parentIds: P) {
    const collectionRef = this.getCollectionRef(parentIds);
    return addDoc(collectionRef, model);
  }

  /** Complete updates a document */
  public set(model: T, parentIds: P) {
    const docRef = this.getDocumentRef(model.id, parentIds);
    return setDoc(docRef, model);
  }

  /** Partial update a document */
  public update(id: string, parentIds: P, fields: UpdateData<T>) {
    const docRef = this.getDocumentRef(id, parentIds);
    return updateDoc(docRef, fields);
  }

  public remove(docId: string, parentIds: P) {
    const docRef = this.getDocumentRef(docId, parentIds);
    return deleteDoc(docRef);
  }

  public async listQuery(
    parentIds?: P,
    //parentId?: string,
    limitValue = 100
  ) {
    const collectionRef = collection(this.afs, this.collectionName); // Use this.afs instead of calling getFirestore

    let q = query(collectionRef, where("sharedTo", "array-contains", this.authService.uuid));

    // TODO: Handle
    /*if (parentId) {
      q = query(q, where('parent', '==', parentId));
    }*/

    // Add the limit to the query
    if (limitValue) {
      q = query(q, limit(limitValue));
    }

    // TODO: Handle
    // Add orderBy
    //q = query(q, orderBy('impactDate', 'desc'));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const data = doc.data();
      // Process the querySnapshot
      return data;
    });
    return document;
  }

  public startListenChanges(
    parentIds: P,
    onChange: (models: Array<T>) => void,
    limitValue = 1 // If not given, limits to last item
  ): void {
    if (this.listenUnsubscribe) {
      this.listenUnsubscribe();
    }

    const collectionRef = this.getCollectionRef(parentIds);
    const q = query(
      collectionRef,
      where("sharedTo", "array-contains", this.authService.uuid),
      orderBy("updated", "desc"),
      limit(limitValue)
    );

    this.listenUnsubscribe = onSnapshot(q, snapshot => {
      const changedDocs = snapshot.docChanges().map(changedDoc => {
        return changedDoc.doc?.data();
      });
      onChange(changedDocs);
    });
  }
}
