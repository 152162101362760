import { IXYZ, XYZ, XYZDataset } from "src/act-common-web/src/models/xyz";
import { IEntry } from "src/act-common-web/src/models/entry";
import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { combineLatest, Observable, Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { EntryActions, EntryState } from "src/app/state/entry-state";
import { tap } from "rxjs/operators";
import { User } from "@angular/fire/auth";
import { EChartsOption } from "echarts";

interface CombinedXYZ {
  ax?: number;
  ay?: number;
  az?: number;
  rx?: number;
  ry?: number;
  rz?: number;
  rax?: number;
  ray?: number;
  raz?: number;
}

interface EntryDataModel {
  linAcc?: XYZDataset;
  rotSpeed?: XYZDataset;
  rotAcc?: XYZDataset;
  linAccAvg?: XYZ;
  rotSpeedAvg?: XYZ;
  rotAccAvg?: XYZ;
  linAccMax?: XYZ;
  rotSpeedMax?: XYZ;
  rotAccMax?: XYZ;
  rotTimeDiff?: number;
}

// Theta, Phi
// Alpha, Beta, Gamma

@Component({
  selector: "app-entry-page",
  templateUrl: "./entry-page.component.html",
  styleUrls: ["./entry-page.component.scss"]
})
export class EntryPageComponent implements OnDestroy {
  @Select(EntryState.current) entry$?: Observable<IEntry | null>;

  private subscription: Subscription;
  private entrySub?: Subscription;

  public accDataset?: Array<IXYZ>;
  public rotDataset?: Array<IXYZ>;
  public origDataset?: EntryDataModel;
  public dataset?: EntryDataModel; // Array<CombinedXYZ>;
  public avg?: CombinedXYZ;

  user?: User;
  inactive?: boolean;
  notes?: string;
  entryId?: string;
  profileId?: string;

  accChart?: EChartsOption;
  rotChart?: EChartsOption;

  chartSelection: { left?: boolean; middle?: boolean; right?: boolean } = {
    left: false,
    middle: true,
    right: false
  };
  selectedChart = "Total";
  selectedAngleUnit = "rad";
  radioModelDisabled = "Middle";
  modelGroupDisabled = false;

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public store: Store
  ) {
    this.subscription = combineLatest({ user: authService.user$, params: this.route.params })
      .pipe(
        tap(result => {
          this.user = result.user ?? undefined;
          const entryId = result.params["id"];
          this.store.dispatch(new EntryActions.Select(entryId));
        })
      )
      .subscribe();

    this.entrySub = this.entry$
      ?.pipe(
        tap(entry => {
          this.entryId = entry?.id;
          this.profileId = entry?.parent;
          this.notes = entry?.notes;
          this.inactive = !(entry?.active ?? true); // Missing values regarded as active

          if (entry) {
            const avg = {
              ax: 0,
              ay: 0,
              az: 0,
              rx: 0,
              ry: 0,
              rz: 0,
              rax: 0,
              ray: 0,
              raz: 0
            };

            if (entry?.accData) {
              // Note this is actually a Blob not Buffer
              const buf = (entry?.accData as any).toUint8Array();
              const view = new DataView(
                buf.buffer.slice(buf.byteOffset, buf.byteOffset + buf.byteLength)
              );

              const res: EntryDataModel = {};
              res.linAcc = XYZDataset.fromFixedIntBuffer(buf);
              res.linAccAvg = res.linAcc.average() ?? undefined;
              res.linAccMax = res.linAcc.max() ?? undefined;

              if (entry?.rotData) {
                const buf = (entry?.rotData as any).toUint8Array();
                res.rotSpeed = XYZDataset.fromFixedIntBuffer(buf);

                const view = new DataView(
                  buf.buffer.slice(buf.byteOffset, buf.byteOffset + buf.byteLength)
                );

                res.rotSpeedAvg = res.rotSpeed.average() ?? undefined;
                res.rotSpeedMax = res.rotSpeed.max() ?? undefined;

                res.rotTimeDiff = Math.floor(entry.rotTimeDiff ?? 0);

                const rotAcc: Array<XYZ> = [];
                res.rotSpeed?.reduce((prev, cur) => {
                  rotAcc.push(
                    new XYZ(
                      Math.abs(prev.x - cur.x),
                      Math.abs(prev.y - cur.y),
                      Math.abs(prev.z - cur.z)
                    )
                  );
                  return cur;
                });
                res.rotAcc = new XYZDataset(...rotAcc);
                res.rotAccAvg = res.rotAcc.average() ?? undefined;
                res.rotAccMax = res.rotAcc.max() ?? undefined;
              }

              this.origDataset = res;
              this.avg = avg;

              this.buildDataset();
            } else {
              this.dataset = undefined;
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.entrySub?.unsubscribe();
  }

  buildDataset() {
    if (this.selectedAngleUnit === "deg") {
      this.dataset = {
        linAcc: this.origDataset?.linAcc?.round(2),
        linAccMax: this.origDataset?.linAccMax?.round(2),
        linAccAvg: this.origDataset?.linAccAvg?.round(2),
        rotSpeed: this.origDataset?.rotSpeed?.round(),
        rotSpeedAvg: this.origDataset?.rotSpeedAvg?.round(),
        rotSpeedMax: this.origDataset?.rotSpeedMax?.round(),
        rotAcc: this.origDataset?.rotAcc?.round(),
        rotAccAvg: this.origDataset?.rotAccAvg?.round(),
        rotAccMax: this.origDataset?.rotAccMax?.round(),
        rotTimeDiff: this.origDataset?.rotTimeDiff
      };
    } else {
      this.dataset = {
        linAcc: this.origDataset?.linAcc?.round(2),
        linAccMax: this.origDataset?.linAccMax?.round(2),
        linAccAvg: this.origDataset?.linAccAvg?.round(2),
        rotSpeed: this.origDataset?.rotSpeed?.degToRad().round(2),
        rotSpeedAvg: this.origDataset?.rotSpeedAvg?.degToRad().round(2),
        rotSpeedMax: this.origDataset?.rotSpeedMax?.degToRad().round(2),
        rotAcc: this.origDataset?.rotAcc?.degToRad().round(2),
        rotAccAvg: this.origDataset?.rotAccAvg?.degToRad().round(2),
        rotAccMax: this.origDataset?.rotAccMax?.degToRad().round(2),
        rotTimeDiff: this.origDataset?.rotTimeDiff
      };
    }
    this.buildSelectedChart();
  }

  buildSelectedChart() {
    switch (this.selectedChart) {
      case "Total":
        this.buildTotalChart();
        break;
      case "Linear acceleration":
        this.buildLinearAccChart();
        break;
      //case "Rotation acceleration": this.buildRotAccChart(); break;
      case "Angular velocity":
        this.buildRotSpeedChart();
        break;
    }
  }

  buildTotalChart() {
    const accData = this.dataset?.linAcc?.round(2)?.squareSum() ?? [];
    const emptyPadding = [].constructor(this.dataset?.rotTimeDiff ?? 0);

    const rotSpeedData = emptyPadding.concat(this.dataset?.rotSpeed?.round(2)?.squareSum());
    // Add one for padding
    emptyPadding.push(null);
    //const rotAccData = emptyPadding.concat(this.dataset?.rotAcc?.round(2)?.squareSum());

    const chart = this.buildChart(
      [
        {
          name: "Linear acceleration",
          data: accData
        },
        /*{
        name: 'Rotation acceleration',
        data: rotAccData || [],
        index: 1,
      },*/
        {
          name: "Angular velocity",
          data: rotSpeedData || [],
          index: 1
        }
      ],
      "g"
    );
    const maxValue = Math.max(...accData);
    // 400g sensor
    if (maxValue > 300) {
      chart.yAxis[0].max = 550;
    } else if (maxValue > 150) {
      // 200g sensor
      chart.yAxis[0].max = 300;
    } else {
      // 100g sensor
      chart.yAxis[0].max = 150;
    }

    //chart.yAxis[0].scale = 1;
    chart.yAxis.push({
      name: this.selectedAngleUnit + "/s",
      type: "value",
      //scale: 20,
      //maxInterval: 250,
      //max: 5000,
      //inverse: true,
      alignTicks: true
    });
    this.accChart = chart;
  }

  get maxEntryCount() {
    return this.dataset?.linAcc?.length;
  }

  buildLinearAccChart() {
    this.accChart = this.buildChart(
      [
        {
          name: "x",
          data: this.dataset?.linAcc?.x ?? []
        },
        {
          name: "y",
          data: this.dataset?.linAcc?.y ?? []
        },
        {
          name: "z",
          data: this.dataset?.linAcc?.z ?? []
        }
      ],
      "g"
    );
  }

  buildRotSpeedChart() {
    const xyzs = this.dataset?.rotSpeed;
    const emptyPadding = [].constructor(this.dataset?.rotTimeDiff ?? 0);
    this.accChart = this.buildChart(
      [
        {
          name: "x",
          data: emptyPadding.concat(xyzs?.x ?? [])
        },
        {
          name: "y",
          data: emptyPadding.concat(xyzs?.y ?? [])
        },
        {
          name: "z",
          data: emptyPadding.concat(xyzs?.z ?? [])
        }
      ],
      this.selectedAngleUnit + "/s"
    );
  }

  buildRotAccChart() {
    const xyzs = this.dataset?.rotAcc;
    const emptyPadding = [].constructor(this.dataset?.rotTimeDiff ?? 0 + 1);

    this.accChart = this.buildChart(
      [
        {
          name: "x",
          data: emptyPadding.concat(xyzs?.x ?? [])
        },
        {
          name: "y",
          data: emptyPadding.concat(xyzs?.y ?? [])
        },
        {
          name: "z",
          data: emptyPadding.concat(xyzs?.z ?? [])
        }
      ],
      this.selectedAngleUnit + "/s²"
    );
  }

  buildChart(
    series: Array<{
      name: string;
      data: Array<number | null>;
      startFromMs?: number;
      type?: string;
      index?: number;
    }>,
    yTitle: string
  ): any {
    const xAxisData = [];
    const maxSerieLength = series.reduce(
      (prev, cur) => (cur.data.length > prev ? cur.data.length : prev),
      0
    );
    for (let i = 0; i < maxSerieLength + 0; i++) {
      xAxisData.push(i + " ms");
    }

    const maxValues = [
      series
        .filter(s => !s.index)
        .reduce((prev, curr) => {
          const max =
            curr.data?.reduce(
              (prev, curr) =>
                Math.abs(prev ?? 0) > Math.abs(curr ?? 0)
                  ? Math.abs(prev ?? 0)
                  : Math.abs(curr ?? 0),
              0
            ) ?? 0;
          return prev > max ? prev : max;
        }, 0),
      series
        .filter(s => s.index === 1)
        .reduce((prev, curr) => {
          const max =
            curr.data?.reduce(
              (prev, curr) =>
                Math.abs(prev ?? 0) > Math.abs(curr ?? 0)
                  ? Math.abs(prev ?? 0)
                  : Math.abs(curr ?? 0),
              0
            ) ?? 0;
          return prev > max ? prev : max;
        }, 0)
    ];

    return {
      darkMode: true,
      textStyle: {
        fontSize: 14,
        color: "#eeeeee"
      },
      legend: [
        {
          data: series.map(s => s.name),
          right: 10,
          textStyle: {
            color: "#ffffff"
          }
        }
      ],
      title: {
        text: this.selectedChart,
        left: "left",
        textStyle: {
          fontSize: 18,
          color: "#ffffff"
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false
        }
      },
      grid: [
        {
          left: 40,
          right: 40,
          height: "80%"
        }
      ],
      dataZoom: [
        {
          show: true,
          realtime: true
          //start: 30,
          //end: 55
        },
        {
          type: "inside",
          realtime: true
          //start: 30,
          //end: 55
        }
      ],
      axisPointer: {
        link: [
          {
            xAxisIndex: "all"
          }
        ]
      },
      xAxis: [
        {
          data: xAxisData,
          silent: false,
          splitLine: {
            show: false
          }
        },
        {
          data: xAxisData,
          silent: true,
          splitLine: {
            show: true
          },
          show: false,
          position: "top"
          //gridIndex: 1,
        }
      ],
      yAxis: [
        {
          name: yTitle, // 'Linear acceleration (g)',
          type: "value",
          //minInterval: 5,
          //maxInterval: 25,
          //min: -100,
          //max: 100,
          //boundaryGap: ['20%', '20%'] //
          axisLine: {
            onZero: true
          }
        }
        /* {
          //gridIndex: 1,
          name: 'Angular velocity (dps)',
          type: 'value',
          maxInterval: 250,
          min: -2000,
          max: 2000,
          //inverse: true,
          alignTicks: true,
        } */
      ],
      series: series.map(s => {
        return {
          name: s.name,
          type: s.type ?? "line",
          data: s.data,
          //xAxisIndex: s.index,
          yAxisIndex: s.index,
          //animationDelay: (idx: number) => idx * 10,
          smooth: true
        };
      }),
      animationEasing: "elasticOut",
      animationDelayUpdate: (idx: number) => idx * 5
    };
  }

  activeClicked($event: any, active: boolean) {
    console.log("Active Clocked", active);
    if (!!this.user?.uid && !!this.entryId) {
      this.store.dispatch(new EntryActions.SetActive(this.user?.uid, this.entryId, active));
    }
  }

  notesChanged($event: any) {
    console.log("Notes changed", this.notes);
    if (!!this.user?.uid && !!this.entryId) {
      this.store.dispatch(
        new EntryActions.SetNotes(this.user?.uid, this.entryId, this.notes ?? "")
      );
    }
  }
}
