<div class="container-lg">
  <div class="d-flex justify-content-between align-items-center">
    <a [routerLink]="backLink" class="navigation">
      <i class="bi bi-arrow-left"></i>
      {{ this.editMode ? "Back" : "Back to dashboard" }}
    </a>
    <a
      *ngIf="editMode"
      class="navigation"
      (click)="openModal(template)"
      (keydown)="handleKeyDown($event, template)"
      tabindex="0">
      <i class="bi bi-trash3"></i>
    </a>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="mt-4">
        <div class="card">
          <div class="card-body" [formGroup]="teamForm">
            <h1 class="title mb-3">
              {{ editMode ? "Manage team" : "Create a new team" }}
            </h1>
            <p *ngIf="editMode">
              You can edit team details if necessary. Then click save to save new details.
            </p>
            <!-- Team Name -->
            <div class="row mb-2 mt-3">
              <div class="col">
                <label for>Team name</label>
                <input type="text" id="name" formControlName="name" class="form-control" />
              </div>
            </div>

            <!-- Subtitle -->
            <div class="row mb-2 mt-2">
              <div class="col">
                <label for>Subtitle</label>
                <input type="text" id="subtitle" formControlName="subtitle" class="form-control" />
              </div>
            </div>

            <!--<div class="row mb-2 mt-2">
              <div class="col-md-2">
                <label class="mb-0">Year:</label>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  id="year"
                  formControlName="year"
                  class="form-control"
                />
              </div>
            </div>-->

            <!-- Description -->
            <div class="row mb-2 mt-2">
              <div class="col">
                <label for="description">Description</label>
                <textarea
                  class="form-control no-resize"
                  formControlName="description"
                  id="description"
                  rows="6"></textarea>
              </div>
            </div>

            <h1 class="mt-4">Team Members</h1>
            <div class="row mt-4">
              <div
                class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4 mb-2"
                *ngFor="let profile of profiles$ | async">
                <div
                  class="card player-card"
                  [ngClass]="{
                    'selected-card': selectedProfiles.includes(profile.id),
                    'unselected-card': !selectedProfiles.includes(profile.id)
                  }">
                  <div class="card-body">
                    <div class="header">
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-square': !selectedProfiles.includes(profile.id),
                          'bi-check-square': selectedProfiles.includes(profile.id)
                        }"
                        (click)="toggleMember(profile.id)"
                        (keypress.enter)="toggleMember(profile.id)"
                        tabindex="0"></i>
                      &nbsp;

                      {{ profile.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col d-flex justify-content-end">
                <button
                  *ngIf="!editing && !editMode"
                  class="btn btn-primary ml-1"
                  (click)="submit()"
                  [disabled]="teamForm.invalid">
                  Create Team
                </button>

                <button
                  *ngIf="editMode || editing"
                  [routerLink]="backLink"
                  class="btn btn-secondary ml-1">
                  Cancel
                </button>

                <button
                  *ngIf="editMode && !editing"
                  class="btn btn-primary ml-1"
                  (click)="toggleEditMode()">
                  Edit Team
                </button>

                <button
                  *ngIf="editing"
                  [disabled]="teamForm.invalid"
                  class="btn btn-primary ml-1"
                  (click)="submit()">
                  Save Team
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="editMode">
    <h1 class="mt-4">Your Seasons</h1>
    <div class="row mt-4">
      <div
        class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4"
        *ngFor="let season of seasons$ | async">
        <div
          class="card season-card"
          [ngClass]="{
            active: seasonActive(season),
            ended: seasonEnded(season)
          }"
          [routerLink]="['seasons', season.id]">
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <div class="header">{{ season.name }}</div>
            <div class="subheader">
              {{ season.begin?.toDate() | date: "mediumDate" }}
            </div>
            <div class="subheader">
              {{ season.end?.toDate() | date: "mediumDate" }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4">
        <div class="card season-card" routerLink="seasons">
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <div class="header"><i class="bi bi-plus-circle-fill"></i></div>
            <div class="subheader">Add new season</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Template for modal pop up-->
<ng-template #template>
  <div class="modal-header">
    <h4 class="header">Delete Team</h4>
  </div>
  <div class="modal-body">
    Are you sure you want to permanently delete this team, including all associated seasons and
    sessions?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef?.hide()">Close</button>
    <button type="button" class="btn btn-danger" (click)="deleteTeam()">Delete</button>
  </div>
</ng-template>
