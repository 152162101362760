import { Component } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { Store } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { ProfileActions } from "src/app/state/profile-state";
// Date picker shorcut ranges
interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: "app-user-report",
  templateUrl: "./user-report.component.html",
  styleUrls: ["./user-report.component.scss"]
})
export class UserReportComponent {
  form: FormGroup;

  minDate = new Date(0);
  maxDate = new Date();

  fetchingReport = false;

  udFile$?: Observable<any>; // storage file obs
  udPath?: string; // storage file path
  udFile?: string; // storage file name
  // User report download URL (signed)
  udSignedURL: any; // signed download link
  udLinkExpires: any; // link expiration time
  udLinkValid: any; // link valid time
  // User report date range (ngx-datepicker)
  bsRanges: IRange[] = [
    {
      value: [this.minDate, new Date()],
      label: "All"
    },
    {
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      label: "This year"
    },
    {
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear() - 1, 11, 31)
      ],
      label: "Last year"
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      label: "Last month"
    }
  ];

  TAG = "UserDownloadDataComponent";

  constructor(
    public authService: AuthService,
    public store: Store
  ) {
    this.form = new FormGroup({
      dateRange: new FormControl()
    });
  }

  public getReport() {
    console.log("Form", this.form);
    const from: Date = this.form.value?.dateRange?.[0];
    const to: Date = this.form.value?.dateRange?.[1];
    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 59, 999);
    this.fetchingReport = true;
    this.store
      .dispatch(new ProfileActions.ExportImpactData(from, to, undefined))
      .pipe(
        tap(data => {
          this.fetchingReport = false;
        })
      )
      .subscribe();
  }
}
