import { DocumentPageComponent } from "./components/documents/document-page.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutComponent } from "./components/about/about.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { EntryPageComponent } from "./components/entry-page/entry-page.component";
import { ProfilePageComponent } from "./components/profile-page/profile-page.component";
import { LoginEmailComponent } from "./components/login-email/login-email.component";
import { RegisterComponent } from "./components/register/register.component";
import { ExportPageComponent } from "./components/export-page/export-page.component";
import { SettingsPageComponent } from "./components/settings/settings-page/settings-page.component";
import { TeamsComponent } from "./components/teams/teams/teams.component";
import { SeasonsComponent } from "./components/seasons/seasons/seasons.component";
import { redirectLoggedInTo } from "@angular/fire/auth-guard";

//import { BluetoothpluginTestComponent } from "./components/bluetooth-test/bluetoothplugin-test/bluetoothplugin-test.component";
import { SessionsComponent } from "./components/sessions/sessions/sessions.component";
import { TeamsPageComponent } from "./components/teams-page/teams-page/teams-page.component";
import { CustomAuthGuard } from "./auth-guard/custom.auth.guard";
import { TeamStatisticsComponent } from "./components/teams-page/teams-page/team-statistics/team-statistics.component";
import { LiveSessionComponent } from "./components/teams-page/teams-page/live-session/live-session/live-session.component";
import { PlayerPageComponent } from "./components/teams-page/teams-page/player-page/player-page/player-page.component";
import { RemoveAccountPageComponent } from "./components/settings/remove-account-page/remove-account-page.component";

//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(["dashboard"]);

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "dashboard/:pid",
    component: ProfilePageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "dashboard/:id/entry/:id",
    component: EntryPageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "export",
    component: ExportPageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "settings",
    component: SettingsPageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "settings/account-removal",
    component: RemoveAccountPageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "login",
    component: LoginEmailComponent,
    canActivate: [CustomAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: "documents/:id",
    component: DocumentPageComponent,
    canActivate: [CustomAuthGuard]
  },
  /*{
    path: "bluetoothtest",
    component: BluetoothpluginTestComponent
    //canActivate: [AngularFireAuthGuard],
    //data: { authGuardPipe: redirectLoggedInToDashboard }
  },*/
  {
    // Create a new team
    path: "manage",
    component: TeamsComponent,
    canActivate: [CustomAuthGuard] //
  },
  {
    // Manage existing team
    path: "manage/:teamId",
    component: TeamsComponent,
    canActivate: [CustomAuthGuard] //
  },
  {
    // Create a new season for team
    path: "manage/:teamId/seasons",
    component: SeasonsComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    // Manage existing season
    path: "manage/:teamId/seasons/:seasonId", // Router for specific seasonId > we can get seasonId then from url
    component: SeasonsComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    // Create a new session for season
    path: "manage/:teamId/seasons/:seasonId/sessions",
    component: SessionsComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    // Manage existing session
    path: "manage/:teamId/seasons/:seasonId/sessions/:sessionId",
    component: SessionsComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "manage/:teamId/season/:seasonId/sessions/:sessionId",
    component: SessionsComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "teams/:teamId",
    component: TeamsPageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "teamstatistics",
    component: TeamStatisticsComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "teams/:teamId/seasons/:seasonId/sessions/:sessionId",
    component: LiveSessionComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "teams/:teamId/players/:playerId",
    component: PlayerPageComponent,
    canActivate: [CustomAuthGuard]
  },
  {
    path: "register",
    component: RegisterComponent
  },
  //{ path: 'forgot-password', component: ForgotPasswordComponent },
  //{ path: 'verify-email-address', component: VerifyEmailComponent }
  {
    path: "about",
    component: AboutComponent
  },
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full"
  }
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
