import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { TimeFrame } from "../../../../team-statistics/team-statistics.component";
import { Observable, Subject, Subscription, takeUntil, tap } from "rxjs";
import {
  IProfileStatistics,
  ISeason,
  ISession,
  IStatisticClassification
} from "src/act-common-web/src";
import { Store } from "@ngxs/store";
import { SessionActions, SessionState } from "src/app/state/session.state";
import { SeasonState } from "src/app/state/season.state";

@Component({
  selector: "app-player-statistics",
  templateUrl: "./player-statistics.component.html",
  styleUrls: ["./player-statistics.component.scss"]
})
export class PlayerStatisticsComponent implements OnDestroy, OnChanges {
  /* TODO: FilteredSession fix
            Check graphic works as intended!
  */

  @Input() playerId?: string;
  @Input() teamId?: string;

  public entries?: IProfileStatistics;

  public combinedData?: number;
  public statistics?: IStatisticClassification;

  public TimeFrame = TimeFrame;
  seasons$?: Observable<Array<ISeason>>;
  sessions$?: Observable<Array<ISession> | undefined>;
  seasonsSub?: Subscription;
  sessionsSub?: Subscription;

  public selectedTimeFrame: TimeFrame = TimeFrame.SEASON;

  public season?: ISeason;
  public session?: ISession;

  public seasons?: Array<ISeason>;
  public sessions?: Array<ISession>;

  public playerName?: string;
  public selectedSession?: string;

  public filteredSessions?: Array<ISession>;

  destroy$ = new Subject();

  constructor(public store: Store) {}

  /*ngOnInit(): void {
    console.log("Test");
  }*/

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  ngOnChanges(): void {
    if (this.playerId && this.teamId) {
      // Fetch seasons for the team
      this.seasons$ = this.store.select(SeasonState.pastSeasonsForTeam(this.teamId));

      this.seasonsSub?.unsubscribe();
      this.seasonsSub = this.seasons$
        ?.pipe(
          takeUntil(this.destroy$),
          tap(seasons => {
            this.seasons = seasons;
            // Check if season is not anymore available
            if (!this.seasons?.some(s => s.id === this.season?.id)) {
              // Find closest season
              let closestSeason: ISeason | undefined;
              this.seasons?.forEach(season => {
                if (
                  (season.begin?.toMillis() ?? 0) > (closestSeason?.begin?.toMillis() ?? 0) &&
                  (season.begin?.toMillis() ?? 0) < Date.now()
                ) {
                  closestSeason = season;
                }
              });
              this.season = closestSeason;
              this.updateSessions();
            }
          })
        )
        .subscribe();
    }
  }

  timeFrameSelected(timeframe: TimeFrame) {
    this.selectedTimeFrame = timeframe;
    this.updateSessions();
    this.updateStatistics();
  }

  /*Update player statistics, depending on which timeframe is selected*/
  updateStatistics() {
    if (this.playerId && this.sessions) {
      if (this.selectedTimeFrame === TimeFrame.SEASON) {
        // Calculate total impact for the season
        const totalImpact = this.combineEntries(this.sessions, this.playerId);
        this.entries = totalImpact;
        console.log("Season data", totalImpact);
        // Access forceCounts specifically for the player from their profile
        this.statistics =
          this.season?.seasonStats?.profiles?.[this.playerId]?.statistics?.forceCounts;
        // Filter sessions for the entire season for the selected player
        const playerSessions = this.sessions.filter(session =>
          session.statistics?.profiles && this.playerId
            ? Object.prototype.hasOwnProperty.call(session.statistics.profiles, this.playerId)
            : false
        );
        this.filteredSessions = playerSessions;
        console.log("Filtered sesssion", this.filteredSessions);
      } else if (this.selectedTimeFrame === TimeFrame.MONTH) {
        //filtering logic for month timeframe
        //this.statistics = this.season?.monthStats;
        //this.filteredSessions = this.sessions?.filter()
      } else if (this.selectedTimeFrame === TimeFrame.SESSION && this.session) {
        // Get player statistics for the selected session
        this.entries = this.session?.statistics?.profiles?.[this.playerId];
        this.statistics =
          this.session?.statistics?.profiles?.[this.playerId]?.statistics?.forceCounts;
        // Filter sessions for the selected session for the selected player
        // Filter sessions for the selected session for the selected player
        this.filteredSessions = this.session.statistics?.profiles?.[this.playerId]
          ? [this.session]
          : [];
        console.log("Filtered sesssion timeframe session", this.filteredSessions);
      }
    }
  }

  next() {
    if (this.selectedTimeFrame === TimeFrame.SEASON) {
      const curIdx = this.seasons?.findIndex(s => s.id === this.season?.id);
      const seasonCount = this.seasons?.length ?? 0;
      if (curIdx !== undefined && curIdx >= 0 && curIdx <= seasonCount - 1) {
        this.season = this.seasons?.[curIdx + 1];
      }
      this.updateSessions();
    } else if (this.selectedTimeFrame === TimeFrame.SESSION) {
      const curIdx = this.sessions?.findIndex(s => s.id === this.session?.id);
      const sessionCount = this.sessions?.length ?? 0;
      if (curIdx !== undefined && curIdx >= 0 && curIdx <= sessionCount - 1) {
        this.session = this.sessions?.[curIdx + 1];
      }
      this.updateStatistics();
    }
  }

  previous() {
    if (this.selectedTimeFrame === TimeFrame.SEASON) {
      const curIdx = this.seasons?.findIndex(s => s.id === this.season?.id);
      if (curIdx !== undefined && curIdx > 0) {
        this.season = this.seasons?.[curIdx - 1];
      }
      this.updateSessions();
    } else if (this.selectedTimeFrame === TimeFrame.SESSION) {
      const curIdx = this.sessions?.findIndex(s => s.id === this.session?.id);
      if (curIdx !== undefined && curIdx > 0) {
        this.session = this.sessions?.[curIdx - 1];
      }
      this.updateStatistics();
    }
  }

  updateSessions() {
    if (this.teamId && this.season?.id) {
      this.sessionsSub?.unsubscribe();
      this.store.dispatch(new SessionActions.FetchSeasonsForSession(this.teamId, this.season.id));
      this.sessions$ = this.store.select(SessionState.pastSessionsForSeason(this.season?.id));
      this.sessionsSub = this.sessions$
        ?.pipe(
          takeUntil(this.destroy$),
          tap(sessions => {
            this.sessions = sessions ?? [];
            console.log("Received sessions:", sessions);
            if (!this.sessions?.some(s => s.id === this.session?.id)) {
              // Find closest session
              let closestSession: ISession | undefined;
              this.sessions?.forEach(session => {
                if (
                  (session.begin?.toMillis() ?? 0) > (closestSession?.begin?.toMillis() ?? 0) &&
                  (session.begin?.toMillis() ?? 0) < Date.now()
                ) {
                  closestSession = session;
                }
              });
              this.session = closestSession;
            }
            this.updateStatistics();
          })
        )
        .subscribe();
    }
  }

  get previousSessionAvailable(): boolean {
    const idx = this.sessions?.findIndex(s => s.id === this.session?.id);
    return idx !== undefined && idx > 0;
  }

  get nextSessionAvailable(): boolean {
    const idx = this.sessions?.findIndex(s => s.id === this.session?.id);
    return idx !== undefined && idx < (this.sessions?.length ?? 0) - 1;
  }

  get previousSeasonAvailable(): boolean {
    const idx = this.seasons?.findIndex(s => s.id === this.season?.id);
    return idx !== undefined && idx > 0;
  }

  get nextSeasonAvailable(): boolean {
    const idx = this.seasons?.findIndex(s => s.id === this.season?.id);
    return idx !== undefined && idx < (this.seasons?.length ?? 0) - 1;
  }

  public combineEntries(sessions: ISession[], playerId: string): IProfileStatistics {
    const aggregatedEntries: IProfileStatistics = {
      id: playerId,
      name: "Season Data",
      playerNumber: 0,
      latestImpact: new Date(),
      statistics: {
        avCounts: {},
        forceCounts: {},
        maxAv: [] // Initialize maxAv as an empty array
      },
      entries: []
    };

    const maxAv: number[] = []; // Array to store every session's maxAv
    sessions.forEach(session => {
      if (session.statistics?.profiles && playerId) {
        const profileData = session.statistics.profiles[playerId];
        console.log("Session", profileData);
        if (profileData && profileData.statistics.entries) {
          // Ensure that aggregatedEntries.statistics.entries is initialized
          aggregatedEntries.statistics.entries = aggregatedEntries.statistics.entries || [];
          // Combine the entries to the aggregatedEntries.statistics.entries array
          aggregatedEntries.statistics.entries = aggregatedEntries.statistics.entries.concat(
            profileData.statistics.entries
          );
          console.log("Aggregated Entries", aggregatedEntries.statistics.entries);
        }
        // Find maxAv for each session and add it to the maxAv array
        if (profileData && profileData.statistics.maxAv) {
          const maxAvSession = Math.max(...profileData.statistics.maxAv);
          console.log("max av", maxAvSession);
          maxAv.push(maxAvSession); // Add maxAv for this session to the array
        }
      }
    });

    // Sort max av >
    maxAv.sort((a, b) => b - a);

    // Store maxAv values in the aggregatedEntries
    aggregatedEntries.statistics.maxAv = maxAv;

    console.log("All entries combined:", aggregatedEntries.statistics.entries);
    console.log("All maxAv values:", maxAv);

    return aggregatedEntries;
  }
}
