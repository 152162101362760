import { Component } from "@angular/core";

@Component({
  selector: "app-trends-card",
  templateUrl: "./trends-card.component.html",
  styleUrls: ["./trends-card.component.scss"]
})
export class TrendsCardComponent {
  constructor() {}
}
