import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { BaseActions, BaseState, BaseStateModel } from "./base-state";
import { IProfile } from "src/act-common-web/src/models/profile";
import { patch, updateItem } from "@ngxs/store/operators";
import { tap } from "rxjs/operators";
import { saveAs } from "file-saver";
import { UserService } from "../services/user.service";
import { Timestamp } from "@angular/fire/firestore";

// tslint:disable-next-line: no-namespace
export namespace ProfileActions {
  export class Update {
    static readonly type = "[Profile] Update";
    constructor(public model: IProfile) {}
  }

  export class Select extends BaseActions.Select {
    static readonly type = "[Profile] Select";
  }

  export class ClearSelection {
    static readonly type = "[Profile] Clear Selection";
    constructor() {}
  }

  export class ExportImpactData {
    static readonly type = "[Profile] Export Impact Data";
    constructor(
      public from: Date,
      public to: Date,
      public profileIds?: Array<string>,
      public wayToDo?: number
    ) {}
  }
}

interface ProfileStateModel extends BaseStateModel<IProfile> {
  current?: string;
  list: Array<IProfile>;
}

@State<ProfileStateModel>({
  name: "profile",
  defaults: {
    current: undefined,
    list: [] // null
  }
})
@Injectable()
export class ProfileState extends BaseState<IProfile, ProfileStateModel> {
  constructor(public userService: UserService) {
    super();
  }

  @Selector()
  static list(state: ProfileStateModel): Array<IProfile> | null {
    return state.list;
  }

  @Selector()
  static current(state: ProfileStateModel) {
    return state.list.find(p => p.id === state.current);
  }

  @Action(ProfileActions.Select)
  selectProfile(ctx: StateContext<ProfileStateModel>, action: ProfileActions.Select): void {
    this.select(ctx, action.id);
  }

  @Action(ProfileActions.ClearSelection)
  clearSelect(ctx: StateContext<ProfileStateModel>, actions: ProfileActions.ClearSelection): void {
    this.clearSelection(ctx);
  }

  @Action(ProfileActions.Update)
  update(ctx: StateContext<ProfileStateModel>, action: ProfileActions.Update): void {
    //console.log("Updating Profile State with action:", action);
    super.update(ctx, action);
  }

  @Action(ProfileActions.ExportImpactData)
  exportImpactData(ctx: StateContext<ProfileStateModel>, action: ProfileActions.ExportImpactData) {
    return this.userService.fetchImpactExport(action.from, action.to, action.profileIds).pipe(
      tap(data => {
        const d: any = data;
        //console.log("Impact Report Fetched", data);
        saveAs(d.data.signedUrl, "file.tsv");
      })
    );
  }
}
