import { DatePipe } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import { EChartsOption } from "echarts";
import { ISession } from "src/act-common-web/src";

@Component({
  selector: "app-impact-card",
  templateUrl: "./impact-card.component.html",
  styleUrls: ["./impact-card.component.scss"]
})
export class ImpactCardComponent implements OnChanges {
  @Input() sessions?: Array<ISession>;

  options?: EChartsOption;

  constructor() {}

  ngOnChanges() {
    this.updateChart(
      this.sessions?.map(session => {
        return {
          time: session.begin?.toDate(),
          low: session.statistics?.details?.forceCounts?.low ?? 0,
          med: session.statistics?.details?.forceCounts?.med ?? 0,
          high: session.statistics?.details?.forceCounts?.high ?? 0,
          veryHigh: session.statistics?.details?.forceCounts?.veryHigh ?? 0
        };
      })
    );
  }

  updateChart(stats?: Array<any>) {
    const type = (stats?.length ?? 0) > 5 ? "line" : "bar";
    // Defaine the chart options
    this.options = {
      darkMode: true,
      textStyle: {
        fontSize: 14,
        color: "#eeeeee"
      },
      xAxis: {
        type: "category",
        data: stats?.map(s => s.time),
        axisLabel: {
          formatter: (axisValue: any) => {
            return new DatePipe("en-US").transform(axisValue, "shortDate") ?? "";
          }
        },
        axisTick: {
          show: true
        },
        axisLine: {
          show: true,
          lineStyle: {
            opacity: 0.5
          }
        }
      },
      yAxis: {
        type: "value",
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        }
      },
      series: [
        {
          data: stats?.map(s => s.low),
          type,
          color: "#FFEE00",
          stack: "session",
          lineStyle: {
            color: "#FFEE00"
          },
          areaStyle: {
            color: "#FFEE00"
          }
        },
        {
          data: stats?.map(s => s.med),
          type,
          color: "#FFB100",
          stack: "session",
          lineStyle: {
            color: "#FFB100"
          },
          areaStyle: {
            color: "#FFB100"
          }
        },
        {
          data: stats?.map(s => s.high),
          color: "#FF4A00",
          type,
          stack: "session",
          lineStyle: {
            color: "#FF4A00"
          },
          areaStyle: {
            color: "#FF4A00"
          }
        },
        {
          data: stats?.map(s => s.veryHigh),
          color: "#FF0000",
          type,
          stack: "session",
          lineStyle: {
            color: "#FF0000"
          },
          areaStyle: {
            color: "#FF0000"
          }
        }
      ]
    };
  }
}
