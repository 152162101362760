<div class="container-lg" *ngIf="team$ | async as team">
  <div class="row">
    <div class="col mb-4">
      <div class="d-flex justify-content-between">
        <a routerLink="/dashboard" class="navigation">
          <i class="bi bi-arrow-left"></i>
          Back to dashboard
        </a>
        <a class="navigation" [routerLink]="['/manage', team.id]">
          <i class="bi bi-gear session-icon"></i>
          Manage
        </a>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-4 mb-4">
      <h1 class="header text-center">{{ team.name }}</h1>
      <div class="subheader text-center">{{ team.subtitle }}</div>
    </div>
  </div>

  <!--conditionally check whether user has season, if has, navigate to that season.id to see sessions-->
  <div class="row">
    <div class="col d-flex align-items-center">
      <h1 class="mr-2">Session</h1>
      <ng-container *ngIf="ongoingSeason$ | async as season; else noSeason">
        <a class="navigation" [routerLink]="['/manage', team.id, 'seasons', season.id]">
          <i class="bi bi-gear session-icon"></i>
          Manage Sessions
        </a>
      </ng-container>
      <ng-template #noSeason>
        <a class="navigation" [routerLink]="['/manage', team.id, 'seasons']">
          <i class="bi bi-gear session-icon"></i>
          Manage Sessions
        </a>
      </ng-template>
    </div>
  </div>

  <app-session-card [team]="team$ | async" [season]="ongoingSeason$ | async"> </app-session-card>

  <app-team-statistics [teamId]="teamId"></app-team-statistics>
</div>
