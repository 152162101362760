import { FBTimestamp } from "../../../firebase-types";
import { IBaseModel, BaseModel } from "./base-model";
import { IStats, IStats2 } from "./stats";
import { XYZ, XYZDataset } from "./xyz";

export interface IEntry extends IBaseModel {
  sensorId: string;
  sensorName?: string;
  sport?: string;
  sportName?: string; ///< Sport Name, filled later
  impact: Buffer; ///< Raw Impact Data
  impactDate: FBTimestamp; ///< Timecorrected Impact Timestamp
  impactIdx: number;
  force: number;
  angularData?: Buffer; // Raw Angular Data
  angularForce?: number;
  // New datamodels for processed data
  dataVersion?: number; ///< Version flag how data is interpreted.
  // 1. Version of data is
  accData?: Buffer; ///< Processed Acceleration Data as Signed Ints 32 bit multiplied by 1000
  rotData?: Buffer; ///< Processed Rotation Data as Signed Ints 32 bit multiplied by 1000
  rotTimeDiff?: number; ///< Time difference of rotation data, in milliseconds
  battery?: number; ///< Battery level when impact was read from the sensor
  gPrimeStats?: IStats; ///< Acceleration Statisics for "best" 25 milliseconds
  aPrimeStats?: IStats; ///< Angular velocity Statisics for "best" 25 milliseconds
  laStats?: IStats2; ///< Linear Acceleartion Stats as G
  avStats?: IStats2; ///< Angular Velocity Stats as DPS
  aaStats?: IStats2; ///< Angular Acceleartion Stats (place holder will not be implemented at least yet)
  active?: boolean; ///< Is Active, can be flagged as not active
  notes?: string; ///< Free Text field for user
}

export class Entry extends BaseModel implements IEntry {
  sensorId: string;
  sensorName?: string;
  sport?: string;
  sportName?: string; ///< Sport Name, filled later
  impact: Buffer; ///< *OBSOLETE* Will be faded away in next releases Raw Impact Data
  impactDate: FBTimestamp; ///< Timecorrected Impact Timestamp
  impactIdx: number; ///< Impact Index
  force: number;
  angularData?: Buffer; ///< *OBSOLETE* Will be faded away in next releases Raw Angular Data
  angularForce?: number; ///< *OBSOLETE* Will be faded away in next releases
  dataVersion?: number; ///< Version flag how data is interpreted.
  accData?: Buffer; ///< Processed Acceleration Data as Signed Ints 32 bit multiplied by 1000
  rotData?: Buffer; ///< Processed Rotation Data as Signed Ints 32 bit multiplied by 1000
  rotTimeDiff?: number; ///< Time difference of rotation data, in milliseconds
  battery?: number; ///< Battery level when impact was read from the sensor
  gPrimeStats?: IStats; ///< *OBSOLETE* Acceleration Statisics for "best" 25 milliseconds
  aPrimeStats?: IStats; ///< *OBSOLETE* Angular velocity Statisics for "best" 25 milliseconds
  laStats?: IStats2; ///< Linear Acceleartion Stats as G
  avStats?: IStats2; ///< Angular Velocity Stats as DPS
  aaStats?: IStats2; ///< Angular Acceleartion Stats (place holder will not be implemented at least yet)
  active?: boolean; ///< Is Active, can be flagged as not active
  notes?: string; ///< Free Text field for user

  constructor(data: IEntry) {
    super(data);
    this.sensorId = data.sensorId;
    this.sensorName = data.sensorName;
    this.sport = data.sport;
    this.sportName = data.sportName;
    this.impact = data.impact;
    this.impactDate = data.impactDate;
    this.impactIdx = data.impactIdx;
    this.force = data.force;
    this.angularData = data.angularData;
    this.angularForce = data.angularForce;
    this.dataVersion = data.dataVersion;
    this.accData = data.accData;
    this.rotData = data.rotData;
    this.rotTimeDiff = data.rotTimeDiff;
    this.battery = data.battery;
    this.gPrimeStats = data.gPrimeStats;
    this.aPrimeStats = data.aPrimeStats;
    this.laStats = data.laStats;
    this.avStats = data.avStats;
    this.aaStats = data.aaStats;
    this.active = data.active;
    this.notes = data.notes;
  }

  private _accelerationData?: XYZDataset;
  private _rotationData?: XYZDataset;

  public get accelerationData(): XYZDataset | undefined {
    if (!this._accelerationData && this.accData) {
      //this._accelerationData = this.parseXYZs(this.bufferToDataView(this.accData), 0, this.accData?.length);
      this._accelerationData = XYZDataset.fromFixedIntBuffer(this.accData);
    }
    return this._accelerationData;
  }

  public get rotationData(): XYZDataset | undefined {
    if (!this._rotationData && this.rotData) {
      this._rotationData = XYZDataset.fromFixedIntBuffer(this.rotData);
    }
    return this._rotationData;
  }
}
