<button (click)="startScanning()" class="btn btn-info">Start scanning</button>
<button (click)="stopScanning()" class="btn btn-danger">Stop scanning</button>
<p>{{ bluetoothStatus }}</p>

<div *ngIf="ble">
  <p style="color: aqua">Bluetooth is enabled</p>
</div>
<div *ngIf="!isScanning && discoveredDevices.length > 0">
  <h2>Available Devices:</h2>
  <ul>
    <li style="color: white" *ngFor="let device of discoveredDevices">
      {{ device.name }} ({{ device.deviceId }})
    </li>
  </ul>
  <p style="color: white">{{ scanData }}</p>
</div>
<div *ngIf="connectedDevice">
  <h2>Connected Device: {{ connectedDevice.name }}</h2>
  <button (click)="readService()" class="btn btn-warning">Get data from ACT-Service!</button>
  <p style="color: white">{{ serviceData }}</p>
</div>
<div>
  <button *ngIf="showStartButton" (click)="startListening()" class="btn btn-light btn-lg btn-block">
    Start listening for impact!
  </button>
  <button
    *ngIf="showStopButton"
    (click)="stopListening()"
    class="btn btn-secondary btn-lg btn-block">
    Stop listening!
  </button>
</div>
