/**
 * Namespace for Global events that can be handled in multiple states
 * Evens are usually dispatches when something is updated and needs some kind of processing in multiple states
 *
 * If processing is needed call for certain event, create @Action handler for that event in state
 */
export namespace OnEvent {
  /**
   * Happens when user is authenticated and logs in.
   * This initializes initial data fetching in many states etc.
   */
  export class UserLoggedIn {
    static readonly type = "[OnEvent] User Logged In";
    constructor(public userId: string) {}
  }

  export class TeamSelected {
    static readonly type = "[OnEvent] Team Selected";
    constructor(public teamId?: string) {}
  }

  export class SeasonSelected {
    static readonly type = "[OnEvent] Season Selected";
    constructor(
      public teamId?: string,
      public seasonId?: string
    ) {}
  }

  export class SessionSelected {
    static readonly type = "[OnEvent] Session Selected";
    constructor(
      public teamId?: string,
      public seasonId?: string,
      public sessionId?: string
    ) {}
  }
}
