import { AuthService } from "src/app/services/auth.service";
import { ITeam } from "src/act-common-web/src/models/team";
import { Injectable } from "@angular/core";
import {
  Firestore,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentData
} from "@angular/fire/firestore";
import { BaseModelService } from "./base-model.service";
import { Store } from "@ngxs/store";

@Injectable({
  providedIn: "root"
})
export class TeamsService extends BaseModelService<ITeam, undefined> {
  protected collectionName = "teams";

  protected converter: FirestoreDataConverter<ITeam> = {
    toFirestore(team: ITeam): DocumentData {
      return BaseModelService.toDocumentData(team);
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): ITeam {
      const data = snapshot.data(options);

      return {
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        name: data["name"],
        subtitle: data["subtitle"],
        description: data["description"],
        //year: data['year'],
        profileIds: data["profileIds"]
      };
    }
  };

  protected extractCollectionPath(parentIds: undefined): Array<string> {
    return [this.collectionName]; // No parentids to handle
  }

  constructor(
    protected authService: AuthService,
    protected afs: Firestore,
    protected store: Store
  ) {
    super(authService, afs, store);
  }
}
