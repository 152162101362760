import { Timestamp, UpdateData } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { ISession } from "src/act-common-web/src/models/session";
import { OnEvent } from "./events";
import { SessionService } from "../services/session.service";
import { BaseState, BaseStateModel } from "./base-state";
import { StateOperator, append, compose, patch, updateItem } from "@ngxs/store/operators";
import { Guid } from "src/act-common-web/src/guid.utils";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

//Actions
export namespace SessionActions {
  export class Init {
    static readonly type = "[Session] Init";
    constructor() {}
  }

  export class Select {
    static readonly type = "[Session] Select";
    constructor(public id?: string) {}
  }

  export class Create {
    static readonly type = "[Session] Create";
    constructor(
      public teamId: string,
      public seasonId: string,
      public name: string,
      public location: string,
      public begin: Date,
      public end: Date,
      public redirect: boolean = true
    ) {}
  }

  export class Update {
    static readonly type = "[Session] Update";
    constructor(
      public id: string,
      public teamId: string,
      public seasonId: string,
      public name: string,
      public location: string,
      public begin?: Date,
      public end?: Date
    ) {}
  }

  export class ClearSelection {
    static readonly type = "[Session] Clear Selection";
    constructor(public payload: ISession) {}
  }

  export class Pause {
    static readonly type = "[Session] Pause";
    constructor(
      public teamId: string,
      public seasonId: string,
      public sessionId: string
    ) {}
  }

  export class Stop {
    static readonly type = "[Session] Stop";
    constructor(
      public teamId: string,
      public seasonId: string,
      public sessionId: string
    ) {}
  }

  export class Next {
    static readonly type = "[Session] Next";
    constructor() {}
  }

  export class Previous {
    static readonly type = "[Session] Previous";
    constructor() {}
  }

  export class Delete {
    static readonly type = "[Session] Delete Session";
    constructor(
      public teamId: string,
      public seasonId: string,
      public sessionId: string,
      public redirect: boolean = true
    ) {}
  }

  export class FetchSeasonsForSession {
    static readonly type = "[Session] Fetch Seasons For Session";
    constructor(
      public teamId: string,
      public seasonId: string
    ) {}
  }
}

/**
 * Nested model for saving season and session for team
 */

export interface SessionStateModel extends BaseStateModel<ISession> {
  list?: ISession[];
  current?: string;
  activeId?: string;
  nextId?: string;
  seasonId?: string;
  teamId?: string;
  active: boolean; // Obsolete?
}

@State<SessionStateModel>({
  name: "session",
  defaults: {
    list: undefined,
    current: undefined,
    active: false
  }
})
@Injectable()
export class SessionState extends BaseState<ISession, SessionStateModel> {
  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private router: Router
  ) {
    super();
  }

  @Selector()
  static list(state: SessionStateModel): Array<ISession> | undefined {
    return state.list?.sort((a, b) => (a.begin?.toMillis() ?? 0) - (b.begin?.toMillis() ?? 0));
  }

  @Selector()
  static current(state: SessionStateModel) {
    return state.list?.find(s => s.id === state.current);
  }

  @Selector()
  static active(state: SessionStateModel) {
    return state.list?.find(s => s.id === state.activeId);
  }

  @Selector()
  static next(state: SessionStateModel) {
    return state.list?.find(s => s.id === state.nextId);
  }

  /**
   * Selector for ongoing ISession for selected team or and ongoing season, undefined if not found.
   */
  @Selector()
  static ongoing(state: SessionStateModel) {
    const now = new Date();
    return state.list?.find(
      s => s.end && s.begin && s.begin.toDate() < now && s.end.toDate() > now
    );
  }

  /**
   * Lists all Sessions by parent
   * Parent is Seasons id in this case
   */
  static listByParent(parent: string) {
    return createSelector([SessionState], (state: SessionStateModel) => {
      return state.list
        ?.filter(s => s.parent === parent)
        ?.sort((a, b) => (a.begin?.toMillis() ?? 0) - (b.begin?.toMillis() ?? 0));
    });
  }

  /**
   * Lists past Sessions by parent, doesn't include sessions that will start in the future
   * Parent is Seasons id in this case
   */
  static pastSessionsForSeason(parent: string) {
    return createSelector([SessionState], (state: SessionStateModel) => {
      return state.list
        ?.filter(s => s.parent === parent && (s.begin?.toMillis() ?? 0) < Date.now())
        ?.sort((a, b) => (a.begin?.toMillis() ?? 0) - (b.begin?.toMillis() ?? 0));
    });
  }

  /** Inits listening when something happens, Team is Selected? */
  @Action(OnEvent.UserLoggedIn)
  initSessionState(ctx: StateContext<SessionStateModel>, action: SessionActions.Init) {
    // Starts listening changes
  }

  /** Inits listening when something happens, Team is Selected? */
  @Action(OnEvent.SeasonSelected)
  onEventSeasonSelected(ctx: StateContext<SessionStateModel>, action: OnEvent.SeasonSelected) {
    if (action.teamId && action.seasonId) {
      this.sessionService.startListenChanges(
        { teamId: action.teamId, seasonId: action.seasonId },
        sessions => {
          const state = ctx.getState();

          const listOperators: Array<StateOperator<Array<ISession>>> = sessions.map(session => {
            return (
              (state.list?.some(t => t.id === session.id)
                ? updateItem<ISession>(t => t.id === session.id, session)
                : append<ISession>([session])) ?? []
            );
          });

          ctx.setState(
            patch({
              current: undefined,
              list: compose(...listOperators),
              teamId: action.teamId,
              seasonId: action.seasonId
            })
          );

          this.updateActiveOrNextSession(ctx);
        },
        100
      ); // Hundred sessions per season is now limit
    } else {
      this.select(ctx);
    }
  }
  /**
   * Fetches sessions for given team and season.
   * Used primary for historical data, checks if history is available
   * Doesn't fetch anything if data is already there
   * For now it will continue listening, this might not be how it will work later
   * @param teamId
   * @param seasonId
   */
  @Action(SessionActions.FetchSeasonsForSession)
  fetchSeasonsForSession(
    ctx: StateContext<SessionStateModel>,
    action: SessionActions.FetchSeasonsForSession
  ) {
    const state = ctx.getState();
    this.sessionService.startListenChanges(
      { teamId: action.teamId, seasonId: action.seasonId },
      sessions => {
        // const sessions = snapshot.docs;
        const listOperators: Array<StateOperator<Array<ISession>>> = sessions.map(session => {
          return (
            (state.list?.some(t => t.id === session.id)
              ? updateItem<ISession>(t => t.id === session.id, session)
              : append<ISession>([session])) ?? []
          );
        });
        // Patch State with new or updated data
        ctx.setState(
          patch({
            list: compose(...listOperators)
          })
        );
        this.updateActiveOrNextSession(ctx);
      },
      100
    ); // Hundred sessions per season is now limit
  }

  @Action(SessionActions.Select)
  selectSession(ctx: StateContext<SessionStateModel>, action: SessionActions.Select): void {
    const state = ctx.getState();
    this.select(ctx, action.id);
    ctx.dispatch(new OnEvent.SessionSelected(state.teamId, state.seasonId, action.id));
  }

  @Action(SessionActions.Create)
  create(ctx: StateContext<SessionStateModel>, action: SessionActions.Create): Promise<void> {
    const uuid = this.authService.uuid;

    const teamId = action.teamId;
    const seasonId = action.seasonId;

    const session: ISession = {
      id: Guid.newGuid(),
      owner: uuid,
      sharedTo: [uuid],
      parent: seasonId,
      created: Timestamp.now(),
      updated: Timestamp.now(),
      name: action.name,
      location: action.location,
      begin: Timestamp.fromDate(action.begin),
      end: Timestamp.fromDate(action.end)
    };

    return this.sessionService.add(session, { teamId, seasonId }).then(docRef => {
      session.id = docRef.id;
      // Update will handle the append also
      this.modelUpdated(ctx, session);
      this.updateActiveOrNextSession(ctx);
    });
  }

  updateActiveOrNextSession(ctx: StateContext<SessionStateModel>) {
    const state = ctx.getState();
    const now = Date.now();
    const activeSession = state.list?.find(
      s => (s.end?.toMillis() ?? 0) > now && (s.begin?.toMillis() ?? 0) < now
    );
    const nextSession = state.list?.filter(s => (s.begin?.toMillis() ?? 0) > Date.now())?.[0]; // First one from the list if available is the next session
    ctx.patchState({
      activeId: activeSession?.id,
      nextId: nextSession?.id
    });
  }

  @Action(SessionActions.Update)
  updateModel(ctx: StateContext<SessionStateModel>, action: SessionActions.Update): Promise<void> {
    const fields: UpdateData<ISession> = {
      name: action.name,
      location: action.location
    };
    // Handle Optionaly updated fields
    if (action.begin) {
      fields.begin = Timestamp.fromDate(action.begin);
    }
    if (action.end) {
      fields.end = Timestamp.fromDate(action.end);
    }
    return this.sessionService
      .update(action.id, { teamId: action.teamId, seasonId: action.seasonId }, fields)
      .then(result => {
        this.partialUpdate(ctx, action.id, session => {
          const result = {
            ...session,
            name: fields.name as string,
            location: fields.location as string
          };
          if (fields.begin) {
            result.begin = fields.begin as Timestamp;
          }
          if (fields.end) {
            result.end = fields.end as Timestamp;
          }
          return result;
        });
        this.updateActiveOrNextSession(ctx);
      });
  }

  @Action(SessionActions.Stop)
  stop(ctx: StateContext<SessionStateModel>, action: SessionActions.Stop): Promise<void> {
    const end = Timestamp.now();
    return this.sessionService
      .update(
        action.sessionId,
        { teamId: action.teamId, seasonId: action.seasonId },
        {
          end
        }
      )
      .then(result => {
        this.partialUpdate(ctx, action.sessionId, session => {
          return {
            ...session,
            end
          };
        });
        this.updateActiveOrNextSession(ctx);
      });
  }

  @Action(SessionActions.Next)
  nextSession(ctx: StateContext<SessionStateModel>, action: SessionActions.Next): void {
    const state = ctx.getState();
    const current = state.list?.find(s => s.id === state.current);
    if (state?.current && (state.list?.length ?? 0) > 1) {
      const newSession = state.list
        ?.filter(s => (s.end?.toMillis() ?? 0) > (current?.end?.toMillis() ?? 0))
        .sort((a, b) => (a.end?.toMillis() ?? 0) - (b.end?.toMillis() ?? 0))?.[0];
      if (newSession) {
        return this.selectSession(ctx, new SessionActions.Select(newSession.id));
      }
    }
  }

  @Action(SessionActions.Previous)
  previousSession(ctx: StateContext<SessionStateModel>, action: SessionActions.Previous): void {
    const state = ctx.getState();
    const current = state.list?.find(s => s.id === state.current);
    if (state?.current && (state.list?.length ?? 0) > 1) {
      const newSession = state.list
        ?.filter(s => (s.end?.toMillis() ?? 0) < (current?.end?.toMillis() ?? 0))
        .sort((a, b) => (b.end?.toMillis() ?? 0) - (a.end?.toMillis() ?? 0))?.[0];
      if (newSession) {
        return this.selectSession(ctx, new SessionActions.Select(newSession.id));
      }
    }
  }

  @Action(SessionActions.Pause)
  pause(ctx: StateContext<SessionStateModel>, action: SessionActions.Pause) {
    const state = ctx.getState();
  }

  @Action(SessionActions.Delete)
  async delete(ctx: StateContext<SessionStateModel>, action: SessionActions.Delete) {
    const state = ctx.getState();
    const teamId = action.teamId;
    const seasonId = action.seasonId;
    const sessionId = action.sessionId;

    await this.sessionService.remove(sessionId, { teamId, seasonId });

    const updatedList = state.list?.filter(session => session.id !== sessionId);
    // Updating state
    ctx.patchState({
      list: updatedList
    });

    console.log("Session list was updated", updatedList);
    if (action.redirect) {
      this.router.navigate(["manage", teamId, "seasons", seasonId]);
    }
  }
}
