import { Injectable } from "@angular/core";
import { IEntry, Entry } from "src/act-common-web/src/models/entry";
import { BaseModelService } from "./base-model.service";
import { AuthService } from "./auth.service";
import {
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  Query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  doc,
  endBefore,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where
} from "@angular/fire/firestore";
import { Store } from "@ngxs/store";

@Injectable({
  providedIn: "root"
})
export class EntryService extends BaseModelService<IEntry, undefined> {
  protected collectionName = "entries";

  protected converter: FirestoreDataConverter<IEntry> = {
    toFirestore(entry: IEntry): DocumentData {
      return entry;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IEntry {
      const data = snapshot.data(options)!;
      return new Entry({
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        sensorId: data["sensorId"],
        sensorName: data["sensorName"],
        sport: data["sport"],
        sportName: data["sportName"],
        impact: data["impact"],
        impactDate: data["impactDate"],
        impactIdx: data["impactIdx"],
        force: data["force"],
        dataVersion: data["dataVersion"],
        accData: data["accData"],
        rotData: data["rotData"],
        rotTimeDiff: data["rotTimeDiff"],
        battery: data["battery"],
        laStats: data["laStats"],
        avStats: data["avStats"],
        aaStats: data["aaStats"],
        active: data["active"],
        notes: data["notes"]
      });
    }
  };

  protected extractCollectionPath(parentIds: undefined): string[] {
    return [this.collectionName];
  }

  constructor(
    protected readonly authService: AuthService,
    protected readonly afs: Firestore,
    protected readonly store: Store
  ) {
    super(authService, afs, store);
  }

  public async listQuery(parentId?: string, limitValue = 100): Promise<Document> {
    const collectionRef = collection(this.afs, this.collectionName);

    let q = query(collectionRef, where("sharedTo", "array-contains", this.authService.uuid));

    if (parentId) {
      q = query(q, where("parent", "==", parentId));
    }

    // Add the limit to the query
    if (limitValue) {
      q = query(q, limit(limitValue));
    }

    // Add orderBy
    q = query(q, orderBy("impactDate", "desc"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: DocumentData) => {
      const data = doc.data();
      // Process the querySnapshot as needed
      return data;
    });

    // Return the result of your query (querySnapshot or something else that makes sense)
    return document;
  }

  public fetchInitialEntries(userId: string, parentId: string, onChange: (entry: IEntry) => void) {
    const collectionRef = collection(this.afs, this.collectionName);

    const q = query(
      collectionRef,
      where("sharedTo", "array-contains", userId),
      where("parent", "==", parentId),
      orderBy("impactDate", "desc"),
      limit(5)
    );

    getDocs(q).then(snapshot => {
      snapshot.docs.forEach(doc => {
        const model = doc.data() as IEntry;
        onChange(model);
      });
    });
  }

  public fetchMoreEntries(
    userId: string,
    parentId: string,
    lastEntry: IEntry,
    onChange: (entry: IEntry) => void
  ) {
    const q = query(
      collection(this.afs, this.collectionName),
      where("sharedTo", "array-contains", userId),
      where("parent", "==", parentId),
      orderBy("impactDate", "desc"),
      startAfter(lastEntry.impactDate), // Use startAfter for pagination
      limit(20)
    );

    getDocs(q).then(snapshot => {
      snapshot.docs.forEach(doc => {
        const model = doc.data() as IEntry;
        onChange(model);
      });
    });
  }

  public fetchEntries(userId: string, parentId: string, after: IEntry, before: IEntry) {
    const q = query(
      collection(this.afs, this.collectionName),
      where("sharedTo", "array-contains", userId),
      where("parent", "==", parentId),
      orderBy("impactDate", "desc"),
      startAfter(after.impactDate),
      endBefore(before.impactDate),
      limit(100)
    );

    return getDocs(q); // Return the QuerySnapshot directly
  }

  /*public update(entryId: string,  entry: Partial<IEntry>) {
    const entryRef = doc(collection(this.afs, this.collectionName), entryId);
    return updateDoc(entryRef, entry);  }
    */
}
