import { Component, Input } from "@angular/core";
import { ITeam } from "src/act-common-web/src/models/team";

@Component({
  selector: "app-newteamcard",
  templateUrl: "./newteamcard.component.html",
  styleUrls: ["./newteamcard.component.scss"]
})
export class NewteamcardComponent {
  @Input() team?: ITeam;
}
