<div class="container-lg">
  <div class="row">
    <div class="col mb-4">
      <div class="d-flex justify-content-between">
        <a [routerLink]="['/teams', teamId]" class="navigation">
          <i class="bi bi-arrow-left"></i>
          Back to Team overview
        </a>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-4 mb-4">
      <h1 class="header text-center">{{ playerName }}</h1>
    </div>
  </div>
  <app-player-statistics [teamId]="teamId" [playerId]="playerId"></app-player-statistics>
</div>
