import { Component, OnDestroy } from "@angular/core";
import { User } from "@angular/fire/auth";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-remove-account-page",
  templateUrl: "./remove-account-page.component.html",
  styleUrls: ["./remove-account-page.component.scss"]
})
export class RemoveAccountPageComponent implements OnDestroy {
  removeAccountForm!: FormGroup;
  user$!: Observable<User | null>;

  userDetailsEditable = false;
  userDownloadEditable = false;

  errorMsg?: string;

  destroy$ = new Subject();

  constructor(
    public store: Store,
    public authService: AuthService,
    public userService: UserService,
    public router: Router
  ) {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  buildForm() {
    this.removeAccountForm = new FormGroup({
      email: new FormControl("", [Validators.required])
    });

    this.removeAccountForm.valueChanges
      .pipe(
        tap(() => {
          this.errorMsg = undefined;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  /**
   * Form is submitted => Remove User Account
   */
  onSubmit() {
    if (this.email.value !== this.authService.currentUser?.value?.email) {
      this.removeAccountForm.reset();
      this.errorMsg = "Invalid email address";
    } else {
      this.userService
        .removeUserAccount()
        .pipe(
          tap(() => {
            this.authService.logout();
            this.router.navigateByUrl("/login");
          })
        )
        .subscribe();
    }
  }

  get email() {
    return this.removeAccountForm.controls["email"];
  }
}
