<div class="container-lg">
  <div class="d-flex justify-content-between align-items-center">
    <a [routerLink]="'/dashboard'" class="navigation">
      <i class="bi bi-arrow-left"></i>
      Back to dashboard
    </a>
  </div>

  <div class="header text-center mt-4 mb-4">User account removal</div>
  <p>
    This operation is will remove account and all of it's personal data. This operation is
    irreversible and will cause sensor hardware bound to user account to be unusable. Activated
    sensors cannot be reactivated to new account.
  </p>
  <p>This means that all:</p>
  <ul>
    <li>created profiles</li>
    <li>activated sensors</li>
    <li>collected impacts</li>
    <li>created teams</li>
  </ul>
  <p>will be removed</p>
  <p>
    Collected data from sensor usage statistic, shared to another account teams statistics will
    remain untouched.
  </p>
  <p class="mb-4">Proceed only if you are sure that this is what you wan't?</p>

  <div class="card">
    <div class="card-body">
      <h2 class="text-center">Remove User Account</h2>

      <p>
        Confirm by typing your account email address. Pressing remove account button will remove the
        account and it's data irreversible. After operation is succesfull you will be redirected to
        login page.
      </p>

      <form [formGroup]="removeAccountForm" (submit)="onSubmit()">
        <div class="form-group row">
          <label for="email" class="col-4 col-form-label">Email</label>
          <div class="col-8">
            <input type="email" formControlName="email" class="form-control" />
            <div *ngIf="errorMsg" class="text-danger">
              {{ errorMsg }}
            </div>
          </div>
        </div>

        <button type="submit" [disabled]="!email.dirty" class="btn btn-danger btn-block">
          Remove account
        </button>
      </form>
    </div>
  </div>
</div>
