import { OnDestroy } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, of, takeUntil, tap } from "rxjs";
//import { Country } from 'src/act-common-web/src/models/country';
import { User } from "@angular/fire/auth";
import { Store } from "@ngxs/store";
import { OnEvent } from "src/app/state/events";
import { UserService } from "src/app/services/user.service";
//import { IUser } from 'src/act-common-web/src/models/user';

@Component({
  selector: "app-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrls: ["./settings-page.component.scss"]
})
export class SettingsPageComponent implements OnInit, OnDestroy {
  settingsForm!: FormGroup;
  user$!: Observable<User | null>;

  userDetailsEditable = false;
  userDownloadEditable = false;

  destroy$ = new Subject();

  constructor(
    public store: Store,
    public authService: AuthService,
    public userService: UserService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    // Get ACT-user (should be authenticated here)
    this.user$ = this.authService.user$ ? this.authService.user$ : of(null);

    if (this.user$) {
      this.user$
        .pipe(
          takeUntil(this.destroy$),
          tap(user => {
            if (user) {
              console.log("User data", user);
              this.store.dispatch(new OnEvent.UserLoggedIn(user.uid));
              this.settingsForm.patchValue({
                name: user.displayName,
                email: user.email
              });
            }
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  buildForm() {
    this.settingsForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      country: new FormControl("")
    });
  }

  // Edit is currently disabled
  public toggleUserDetailsEditable(): void {
    /* if (this.authService.currentUser) {
      this.actUser = this.authService.currentUser.value;
    }

    this.userDetails?.resetForm();                          // TODO: what's this?
    this.userDetailsEditable = !this.userDetailsEditable;  // disabel editing
    this.actUser$ = this.authService.user$;                // reload form from model
    */
  }

  public onSubmit(): void {
    /*this.userDetailsEditable = false;
    this.actUser = this.authService.currentUser.value;

    if (this.actUser.id) {
      this.dbService.updateUser(this.actUser.id, this.userDetails);
    }

    // toggle form uneditable
    this.userDetailsEditable = false;

    // reload form data from model
    this.actUser$ = this.authService.user$;*/
  }

  public removeUserAccount(): void {
    this.userService.removeUserAccount().subscribe();
  }
}
