<div class="container mt-4 pb-4" *ngIf="document$ | async as document">
  <div class="card" [formGroup]="acceptanceForm">
    <div class="card-header">
      <h3 class="card-title">{{ document.title }}</h3>
    </div>

    <div class="card-body" *ngIf="document.hasAcceptance">
      <div class="document-content" [innerHTML]="document.content"></div>
      <div class="form-check mt-4 mb-4">
        <input class="form-check-input" formControlName="accept" type="checkbox" name="accept" />
        <label class="form-check-label" for="accept">I accept these terms</label>
      </div>
      <button type="button" class="btn btn-primary btn-block">Submit</button>
    </div>

    <div class="card-body" *ngIf="!document.hasAcceptance">
      <div class="row">
        <div class="col">
          <div>{{ document.title }}</div>
          <div>These are not acceptable documents</div>
        </div>
      </div>
    </div>
  </div>
</div>
