import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { Subject, takeUntil, tap } from "rxjs";

@Component({
  selector: "app-login-email",
  templateUrl: "./login-email.component.html",
  styleUrls: ["./login-email.component.scss"]
})
export class LoginEmailComponent implements OnDestroy {
  loginForm: FormGroup;

  forgotPassword = true;
  resetPassword = false;

  errorMessage?: string;

  destroy$ = new Subject<undefined>();

  redirectUri?: string;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required])
    });
    this.redirectUri =
      this.router.parseUrl(this.router.url)?.queryParamMap?.get("redirect") ?? undefined;

    this.loginForm.valueChanges
      .pipe(
        tap(() => {
          this.errorMessage = undefined;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  onLogin() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .then(res => {
        if (this.redirectUri) {
          this.router.navigateByUrl(this.redirectUri);
        } else {
          this.router.navigateByUrl("/dashboard");
        }
      })
      .catch(err => {
        this.errorMessage = "Login failed, please check your credentials.";
      });
  }

  onResetPassword(): void {
    let email: any;
    if (this.loginForm.valid) {
      email = this.loginForm.value.email;
    } else {
      alert("Enter email");
    }

    this.authService
      .resetPassword(email)
      .then(() => {
        this.resetPassword = true;
      })
      .catch(err => {
        this.errorMessage = "Password reset failed";
        //alert( err['code'] );
        //alert( ErroAuthEn.convertMessage(error['code']));
      });
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }
}
