<!-- <p>register works!</p> -->

<div class="userauth-form">
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-sm-9 col-md-6">
        <div class="card">
          <form [formGroup]="registerForm" (submit)="onRegister()">
            <div class="card-body">
              <h1 class="card-title">Register new ACT-account</h1>

              <!-- <a routerLink="/login" id="goback">Back to Login</a> -->

              <!-- Register Form -->

              <!-- Error -->
              <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>

              <div class="form-group">
                <label for="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Enter email"
                  formControlName="email"
                  required />
              </div>

              <div class="form-group">
                <label for="password">Password</label>
                <input
                  type="password"
                  name="password"
                  class="form-control"
                  placeholder="Enter password"
                  formControlName="password"
                  required />
              </div>

              <button
                type="submit"
                class="btn btn-primary btn-block"
                [disabled]="registerForm.invalid">
                Register
              </button>

              <div class="mt-4">
                <a routerLink="/login" routerLinkActive="active" class="move-away-link">
                  <strong>Have ACT-account already? Login here!</strong>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
