import { FBTimestamp } from "../../../firebase-types";

export interface IBaseModel {
  id: string;
  owner: string;
  sharedTo: Array<string>;
  parent: string;
  created: FBTimestamp;
  updated: FBTimestamp;
}

export class BaseModel {
  id: string;
  owner: string;
  sharedTo: Array<string>;
  parent: string;
  created: FBTimestamp;
  updated: FBTimestamp;

  constructor(data: IBaseModel) {
    this.id = data.id;
    this.owner = data.owner;
    this.sharedTo = data.sharedTo;
    this.parent = data.parent;
    this.created = data.created;
    this.updated = data.updated;
  }
}
