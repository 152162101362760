import { Pipe, PipeTransform } from "@angular/core";

export enum UnitConversions {
  DPS_TO_RAD,
  DPS_TO_RPM,
  RAD_TO_DPS,
  RAD_TO_RPM,
  RPM_TO_DPS,
  RPM_TO_RAD
}

@Pipe({
  name: "unitConvert"
})
export class UnitConvertPipe implements PipeTransform {
  transform(
    value?: number,
    conversion: UnitConversions = UnitConversions.DPS_TO_RAD
  ): number | null {
    // NOTE THIS DOESN'T WORK. USE toDate()
    if (value === undefined || value === null) {
      return null;
    }
    switch (conversion) {
      case UnitConversions.DPS_TO_RAD:
        return (value * Math.PI) / 180;
      case UnitConversions.DPS_TO_RPM:
        return (value * 60) / 360;
      case UnitConversions.RAD_TO_DPS:
        return (value * 180) / Math.PI;
      case UnitConversions.RAD_TO_RPM:
        return (((value * 180) / Math.PI) * 60) / 360;
      case UnitConversions.RPM_TO_DPS:
        return (value * 360) / 60;
      case UnitConversions.RPM_TO_RAD:
        return (((value * 360) / 60) * 180) / Math.PI;
    }
  }
}
