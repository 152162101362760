import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import {
  Firestore,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentData,
} from "@angular/fire/firestore";
import { BaseModelService } from "./base-model.service";
import { Store } from "@ngxs/store";
import { Observable, from } from "rxjs";
import {
  Functions,
  HttpsCallableResult,
  httpsCallable,
} from "@angular/fire/functions";
import { Storage, getDownloadURL, ref } from "@angular/fire/storage";
import { IUser } from "src/act-common-web/src/models/user";

@Injectable({
  providedIn: "root"
})
export class UserService extends BaseModelService<IUser, undefined> {
  protected collectionName = "users";

  protected converter: FirestoreDataConverter<IUser> = {
    toFirestore(user: IUser): DocumentData {
      return user;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IUser {
      const data = snapshot.data(options);

      return {
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        loginId: data["loginId"],
        name: data["name"],
        acceptances: data["acceptances"],
        country: data["country"],
        lastAcceptance: data["lastAcceptance"]
      };
    }
  };

  protected extractCollectionPath(parentIds: undefined): Array<string> {
    return [this.collectionName]; // No parentids to handle
  }

  constructor(
    protected authService: AuthService,
    protected afs: Firestore,
    protected functions: Functions,
    protected storage: Storage,
    protected store: Store
  ) {
    super(authService, afs, store);
  }

  /** Moved here */
  public getUserProfilePic(uid: string): Observable<any | null> {
    const picName = "profile.png";
    const storagePath = "/private/" + uid + "/files/" + picName;

    // Get a storage reference using AngularFire's ref() method
    const storageRef = ref(this.storage, storagePath);

    // Use 'from' to convert the Promise-based operation to an Observable
    return from(getDownloadURL(storageRef));
  }

  public fetchImpactExport(startDate: Date, to: Date, profileIds?: Array<string>): Observable<any> {
    if (!this.authService.uuid) {
      throw new Error("Not authenticated user: '" + this.authService.uuid + "'");
    }

    const exportFunc = httpsCallable(this.functions, "exportImpactReport");
    const promise: Promise<HttpsCallableResult<unknown>> = exportFunc({
      from: startDate,
      to: to,
      profileIds
    });
    console.log("From:", startDate);
    console.log("From:", to);
    return from(promise);
  }

  public removeUserAccount(): Observable<any> {
    if (!this.authService.uuid) {
      throw new Error("Not authenticated user: '" + this.authService.uuid + "'");
    }

    const exportFunc = httpsCallable(this.functions, "removeAccount");
    const promise: Promise<HttpsCallableResult<unknown>> = exportFunc({
      uid: this.authService.uuid
    });

    return from(promise);
  }

  public developerGenerateImpact(sensorNumber: string): Observable<any> {
    if (!this.authService.uuid) {
      throw new Error("Not authenticated user: '" + this.authService.uuid + "'");
    }

    const callable = httpsCallable(this.functions, "developerGenerateImpact");
    const promise: Promise<HttpsCallableResult<unknown>> = callable({
      sensorId: sensorNumber
    });

    return from(promise);
  }
}
