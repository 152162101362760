<h1>Start a new session!</h1>
<form [formGroup]="sessionForm" (ngSubmit)="startSession()">
  <div class="form-group">
    <label for="name">Name of the session</label>
    <input type="text" class="form-control" id="name" formControlName="name" />
  </div>
  <div class="form-group">
    <label for="location">Location:</label>
    <input type="text" class="form-control" id="location" formControlName="location" />
  </div>

  <div class="row">
    <div class="col-sm-6 mt-2">
      <div>Start Time</div>
      <div class="col-sm-6 mt-2">{{ beginDate | date: "HH:mm:ss" }}</div>
    </div>
    <div class="col-sm-6 mt-2">
      <div>End Time</div>
      <div class="col-sm-6 mt-2">{{ endDate | date: "HH:mm:ss" }}</div>
    </div>
  </div>

  <label for="start-time-button" class="mt-2">Begin after:</label>
  <div btnRadioGroup formControlName="begin" class="row">
    <div class="col-6 col-sm-3 mb-2">
      <button type="button" btnRadio="0" style="height: 100%" class="btn btn-secondary btn-block">
        Now
      </button>
    </div>
    <div class="col-6 col-sm-3 mb-2">
      <button type="button" btnRadio="5" class="btn btn-secondary btn-block">5<br />min</button>
    </div>
    <div class="col-6 col-sm-3 mb-2">
      <button type="button" btnRadio="10" class="btn btn-secondary btn-block">10<br />min</button>
    </div>
    <div class="col-6 col-sm-3 mb-2">
      <button type="button" btnRadio="20" class="btn btn-secondary btn-block">20<br />min</button>
    </div>
  </div>

  <label for="end-time-button">Session duration:</label>
  <div btnRadioGroup formControlName="end" class="row">
    <div class="col-4">
      <button type="button" btnRadio="30" class="btn btn-secondary btn-block mb-2">
        30<br />min
      </button>
    </div>
    <div class="col-4">
      <button type="button" btnRadio="60" class="btn btn-secondary btn-block mb-2">
        60<br />min
      </button>
    </div>
    <div class="col-4">
      <button type="button" btnRadio="90" class="btn btn-secondary btn-block mb-2">
        90<br />min
      </button>
    </div>
    <div class="col-4">
      <button type="button" btnRadio="120" class="btn btn-secondary btn-block mb-2">
        120<br />min
      </button>
    </div>
    <div class="col-4">
      <button type="button" btnRadio="150" class="btn btn-secondary btn-block mb-2">
        150<br />min
      </button>
    </div>
    <div class="col-4">
      <button type="button" btnRadio="180" class="btn btn-secondary btn-block mb-2">
        180<br />min
      </button>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">
      Close
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="sessionForm.invalid">
      Start Session
    </button>
  </div>
</form>
