import { Component, Input, OnChanges } from "@angular/core";
import { EChartsOption } from "echarts";
import { IStatisticClassification } from "src/act-common-web/src";

@Component({
  selector: "app-strength-card",
  templateUrl: "./strength-card.component.html",
  styleUrls: ["./strength-card.component.scss"]
})
export class StrengthCardComponent implements OnChanges {
  //@Input() statistics?: Statistics;

  @Input() statistics?: IStatisticClassification;
  options?: EChartsOption;

  constructor() {}

  /* Nothing here > ngOnInit(): void {
  }*/

  ngOnChanges() {
    this.updateChart(this.statistics);
  }

  updateChart(stats?: IStatisticClassification) {
    const data = [];
    const colors: Array<string> = [];
    stats?.low && data.push({ value: stats?.low, name: "< 40g" }) && colors.push("#FFEE00");
    stats?.med && data.push({ value: stats?.med, name: "40g - 60g" }) && colors.push("#FFB100");
    stats?.high && data.push({ value: stats?.high, name: "61g - 99g" }) && colors.push("#FF4A00");
    stats?.veryHigh &&
      data.push({ value: stats?.veryHigh, name: "> 100g" }) &&
      colors.push("#FF0000");

    this.options = {
      title: {
        left: "right"
      },
      tooltip: {
        trigger: "item"
      },
      color: colors, // ["#FFEE00", "#FFB100", "#FF4A00", "#FF0000"],
      darkMode: true,
      textStyle: {
        fontSize: 18
      },
      series: [
        {
          type: "pie",
          radius: ["20%", "50%"],
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    };
  }
}
