import { Component, OnDestroy } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "@angular/fire/auth";
import { Subject, takeUntil, tap } from "rxjs";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnDestroy {
  registerForm: FormGroup;

  user?: User;
  errorMessage?: string;

  destroy$ = new Subject<undefined>();

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
    this.registerForm = new FormGroup({
      email: new FormControl("", Validators.required),
      password: new FormControl("", [Validators.required, Validators.minLength(9)])
    });
    this.registerForm.valueChanges
      .pipe(
        tap(() => {
          this.errorMessage = undefined;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onRegister(): void {
    if (this.registerForm?.invalid) {
      return;
    }
    this.authService
      .register(this.registerForm?.value.email, this.registerForm?.value.password)
      .then(() => {
        this.router.navigateByUrl("/dashboard");
      })
      .catch(() => {
        this.errorMessage = "Registration failed";
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  get email() {
    return this.registerForm.get("email");
  }

  get password() {
    return this.registerForm.get("password");
  }
}
