import { Observable, Subscription } from "rxjs";
import { IProfile } from "src/act-common-web/src/index";
import { Injectable } from "@angular/core";
import { BaseModelService } from "./base-model.service";
import { tap } from "rxjs/operators";
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class ProfileService extends BaseModelService<IProfile, undefined> {
  protected collectionName = "profiles";

  protected converter: FirestoreDataConverter<IProfile> = {
    toFirestore(team: IProfile): DocumentData {
      return team;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IProfile {
      const data = snapshot.data(options)!;
      return {
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        name: data["name"],
        birthDay: data["birthDay"],
        gender: data["gender"],
        profilePic: data["profilePic"],
        lastEntry: data["lastEntry"]
      };
    }
  };

  protected extractCollectionPath(parentIds: undefined): string[] {
    return [this.collectionName];
  }
}
