<div class="row">
  <div class="col">
    <ul class="navigation">
      <li>
        <a
          [ngClass]="{ active: sortBy === PlayerListSortColumn.LATEST }"
          (click)="navClicked(PlayerListSortColumn.LATEST)"
          (keypress.enter)="navClicked(PlayerListSortColumn.LATEST)"
          tabindex="0">
          Latest events
        </a>
      </li>
      <li>
        <a
          [ngClass]="{ active: sortBy === PlayerListSortColumn.MOST }"
          (click)="navClicked(PlayerListSortColumn.MOST)"
          (keypress.enter)="navClicked(PlayerListSortColumn.MOST)"
          tabindex="0">
          Most events
        </a>
      </li>
      <li>
        <a
          [ngClass]="{ active: sortBy === PlayerListSortColumn.HEAVIEST }"
          (click)="navClicked(PlayerListSortColumn.HEAVIEST)"
          (keypress.enter)="navClicked(PlayerListSortColumn.HEAVIEST)"
          tabindex="0">
          Highest G-FORCE
        </a>
      </li>
      <li>
        <a
          [ngClass]="{ active: sortBy === PlayerListSortColumn.HIGHEST_AV }"
          (click)="navClicked(PlayerListSortColumn.HIGHEST_AV)"
          (keypress.enter)="navClicked(PlayerListSortColumn.HIGHEST_AV)"
          tabindex="0">
          Highest rad/s
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="row">
  <div class="col">
    <div *ngFor="let profile of shownStatistics">
      <div class="card player-card mb-2" [routerLink]="['/teams', teamId, 'players', profile.id]">
        <div class="card-body">
          <div class="header">
            <div>{{ profile.name }}</div>
          </div>
          <div *ngIf="sortBy === PlayerListSortColumn.LATEST" class="subheader">
            {{ profile?.statistics?.lastTime | fbdate | date: "medium" }}
          </div>
          <div *ngIf="sortBy === PlayerListSortColumn.LATEST" class="header text-success">
            <i class="bi bi-lightning-fill"></i>
            <span>{{ profile?.statistics?.lastForce | number: "1.0-0" }}</span>
            <span class="unit"><i>g</i></span>
          </div>

          <div *ngIf="sortBy === PlayerListSortColumn.HEAVIEST" class="header text-success">
            <i class="bi bi-lightning-fill"></i>
            <span>{{ profile?.statistics?.maxForce | max | number: "1.0-0" }}</span>
            <small class="unit"><i>g</i></small>
          </div>

          <div *ngIf="sortBy === PlayerListSortColumn.HIGHEST_AV" class="header text-success">
            <i class="bi bi-lightning-fill"></i>
            <span>{{ profile?.statistics?.maxAv | max | unitConvert | number: "1.0-0" }}</span>
            <span class="unit">rad/s</span>
          </div>

          <div *ngIf="sortBy === PlayerListSortColumn.MOST" class="header text-success">
            <span>{{ profile?.statistics?.totalCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
