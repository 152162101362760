import { Component, Input } from "@angular/core";
import { ISeason } from "src/act-common-web/src/models/season";
import { ISession } from "src/act-common-web/src/models/session";
import { ITeam } from "src/act-common-web/src/models/team";

@Component({
  selector: "app-team-card",
  templateUrl: "./team-card.component.html",
  styleUrls: ["./team-card.component.scss"]
})
export class TeamCardComponent {
  constructor() {}

  createNewTeam = false;

  @Input() team?: ITeam;
  @Input() seasons?: ISeason[];
  @Input() sessions?: ISession[];
}
