import { ActivatedRoute } from "@angular/router";
import { combineLatest, Observable, Subscription, tap } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { AuthService } from "src/app/services/auth.service";
import { DocumentActions, DocumentState } from "src/app/state/document-state";
import { IDocument } from "src/act-common-web/src";
import { User } from "@angular/fire/auth";

@Component({
  selector: "app-document-page",
  templateUrl: "./document-page.component.html"
  //styleUrls: ['./document-page.component.scss']
})
export class DocumentPageComponent implements OnInit, OnDestroy {
  @Select(DocumentState.current) document$?: Observable<IDocument>;

  acceptanceForm: FormGroup;
  showAcceptance!: boolean;
  subscription?: Subscription;

  user?: User;
  documentId?: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private authService: AuthService
  ) {
    this.acceptanceForm = new FormGroup({
      accept: new FormControl(false, Validators.requiredTrue)
    });
  }

  ngOnInit(): void {
    this.subscription = combineLatest({ user: this.authService.user$, params: this.route.params })
      .pipe(
        tap(result => {
          // Fetch the User Details
          this.user = result.user ?? undefined;
          this.documentId = result.params["id"];
          if (this.documentId) {
            this.store.dispatch(new DocumentActions.Select(this.documentId));
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  accept() {}
}
