<div class="card">
  <div class="card-body">
    <h2>TRENDS</h2>

    <ul class="trends-list">
      <li>
        Number of impacts
        <i class="bi bi-arrow-up trend-icon text-success"></i> +6.4%
      </li>
      <li>
        Impact strength
        <i class="bi bi-arrow-down trend-icon text-danger"></i> -2.2%
      </li>
      <li>
        Impact load
        <i class="bi bi-arrow-up trend-icon text-success"></i> +3.1%
      </li>
    </ul>
  </div>
</div>
