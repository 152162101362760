import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "max"
})
export class MaxPipe implements PipeTransform {
  transform(value?: Array<number>, ...args: unknown[]): number | undefined {
    let result: number | undefined = undefined;
    for (const val of value ?? []) {
      if (result === undefined || val > result) {
        result = val;
      }
    }
    return result;
  }
}
