import { IDocument, IEntry, IProfile, IProfileStatistics } from "src/act-common-web/src/index";
import { ProfileState } from "./../../state/profile-state";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Auth } from "@angular/fire/auth";
// Services
import { Select, Store } from "@ngxs/store";
import { EntryState } from "src/app/state/entry-state";
import { Observable, Subject, Subscription, filter, map } from "rxjs";
import { DocumentState } from "src/app/state/document-state";
import { TeamState } from "src/app/state/teams.state";
import { ISeason } from "src/act-common-web/src/models/season";
import { ISession } from "src/act-common-web/src/models/session";
import { ITeam } from "src/act-common-web/src/models/team";
import { SeasonState } from "src/app/state/season.state";
import { SessionState } from "src/app/state/session.state";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

// Environment
//import { environment } from '../../../environments/environment';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  seasons$?: Observable<Array<ISeason>>;

  @Select(ProfileState.current) profile$?: Observable<IProfile>;
  @Select(EntryState.current) entry$?: Observable<IEntry>;
  @Select(DocumentState.list) documents$?: Observable<Array<IDocument>>;
  @Select(TeamState.current) currentTeam$?: Observable<ITeam | null>;
  @Select(SeasonState.current) currentSeason$?: Observable<ISeason | null>;
  @Select(SessionState.current) currentSession$?: Observable<ISession | null>;

  playerId = "";
  teamId: string | undefined = "";
  destroy$ = new Subject();
  playerName?: string;
  player?: IProfileStatistics;

  private subscription?: Subscription;

  constructor(
    public afAuth: Auth,
    public authService: AuthService,
    private router: Router,
    public store: Store,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log("Documents retrieved", this.documents$);
    this.subscription = this.documents$?.subscribe(documents => {
      console.log(
        "Document structure",
        documents.map(doc => doc.title)
      );
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map(route => route.snapshot.params)
      )
      .subscribe(params => {
        this.playerId = params.playerId;
        this.teamId = params.teamId;
        if (this.teamId) {
          this.seasons$ = this.store.select(SeasonState.listByParent(this.teamId));
          /*Go trough seasons, to find whether our playerId is there to get name*/
          this.seasons$.subscribe(seasons => {
            seasons.forEach(season => {
              const player = season.seasonStats?.profiles[this.playerId];
              this.playerName = player?.name;
            });
          });
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
