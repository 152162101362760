import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { ISeason } from "src/act-common-web/src";
import { ISession } from "src/act-common-web/src/models/session";
import { SeasonState } from "src/app/state/season.state";
import { SessionActions, SessionState } from "src/app/state/session.state";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-sessions",
  templateUrl: "./sessions.component.html",
  styleUrls: ["./sessions.component.scss"]
})
export class SessionsComponent implements OnInit, OnDestroy {
  @Select(SeasonState.current) season$?: Observable<ISeason>;
  @Select(SessionState.current) session$?: Observable<ISession>;
  modalRef: BsModalRef | undefined;

  sessionForm: FormGroup;

  // Team and Season should be always available from URL
  teamId!: string;
  seasonId!: string;
  sessionId?: string;
  session?: ISession;

  modalMessage: string | null = null;

  //nameError?: string;
  //startError?: string;
  //endError?: string;

  // Repeat checkbox values
  destroy$ = new Subject();

  repeatCheckValues: string[] = [
    "No repeat",
    "Every day",
    "Every week",
    "Every other week",
    " Every ~ Days"
  ];

  seasonBegin?: Date;
  seasonEnd?: Date;

  sessionHasStarted = false;
  sessionHasEnded = false;

  constructor(
    private router: Router,
    private store: Store,
    public route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.route.params
      .pipe(
        tap(params => {
          this.teamId = params.teamId;
          this.seasonId = params.seasonId;
          this.sessionId = params.sessionId;
          if (this.sessionId) {
            this.store.dispatch(new SessionActions.Select(this.sessionId));
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.sessionForm = new FormGroup({
      name: new FormControl(""),
      location: new FormControl(""),
      day: new FormControl<Date>(new Date(), Validators.required),
      begin: new FormControl(new Date(0), Validators.required),
      end: new FormControl(new Date(0), Validators.required)
    });
  }

  ngOnInit(): void {
    this.session$
      ?.pipe(
        tap(session => {
          if (session) {
            this.session = session;

            // Extract Time from Date
            const beginTime = new Date(session?.begin?.toDate()?.setUTCFullYear(1970, 0, 1) ?? 0);
            const endTime = new Date(session?.end?.toDate()?.setUTCFullYear(1970, 0, 1) ?? 0);

            this.sessionForm?.setValue({
              name: session?.name,
              location: session?.location,
              day: session?.begin?.toDate(),
              begin: beginTime,
              end: endTime
            });
            this.sessionHasStarted = (this.session.begin?.toMillis() ?? 0) < Date.now();
            this.sessionHasEnded = (this.session.end?.toMillis() ?? 0) < Date.now();
            // Disable Start Date, if session has already begun
            if (this.sessionHasStarted) {
              this.sessionForm.get("day")?.disable();
              this.sessionForm.get("begin")?.disable();
            }
            // Disable End Date, if session has already ended
            if (this.sessionHasEnded) {
              this.sessionForm.get("end")?.disable();
            }
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Listen selected season
    this.season$
      ?.pipe(
        takeUntil(this.destroy$),
        tap(season => {
          this.seasonBegin = season.begin?.toDate();
          this.seasonEnd = season.end?.toDate();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  /**
   * Submit Session Form
   * Either creates or updates session
   */
  submit() {
    const values = this.sessionForm.value;

    const day = values?.day
      ? new Date(values.day).setUTCHours(0, 0, 0, 0)
      : this.session?.begin?.toDate().setUTCHours(0, 0, 0, 0);
    const begin =
      values?.begin && day ? new Date(day + new Date(values.begin).getTime()) : undefined;
    const end = values.end ? new Date(day! + new Date(values.end).getTime()) : undefined;

    if (!this.editMode && begin && end) {
      this.store
        .dispatch(
          new SessionActions.Create(
            this.teamId,
            this.seasonId,
            values.name,
            values.location,
            begin,
            end
          )
        )
        .pipe(tap(() => this.router.navigate([this.backLink], { relativeTo: this.route })))
        .subscribe();
    } else if (this.session) {
      this.store
        .dispatch(
          new SessionActions.Update(
            this.session.id,
            this.teamId,
            this.seasonId,
            values.name,
            values.location,
            begin,
            end
          )
        )
        .pipe(tap(() => this.router.navigate([this.backLink], { relativeTo: this.route })))
        .subscribe();
    }
  }

  get editMode(): boolean {
    return !!this.sessionId;
  }

  /**
   * Returns relative link to back, if in editmode 2 steps if in create only one step
   */
  get backLink(): string {
    return this.editMode ? "../.." : "../";
  }

  get sessionBegin() {
    return this.sessionForm?.value?.begin;
  }

  get sessionEnd() {
    return this.sessionForm?.value?.end;
  }

  get dayStart() {
    return new Date(0);
  }

  get dayEnd() {
    return new Date(24 * 60 * 60 * 1000); //
  }

  /** Removes single session */

  openModal(template: TemplateRef<any>) {
    if (this.teamId && this.seasonId && this.sessionId) {
      const config = {
        initialState: {
          teamId: this.teamId,
          seasonId: this.seasonId,
          sessionId: this.sessionId
        }
      };
      this.modalRef = this.modalService.show(template, config);
    }
  }

  deleteSession() {
    if (this.teamId && this.seasonId && this.sessionId) {
      this.store.dispatch(new SessionActions.Delete(this.teamId, this.seasonId, this.sessionId));
    }
    this.modalRef?.hide;
  }

  handleKeyDown(event: KeyboardEvent, template: TemplateRef<unknown>) {
    if (event.key === "Enter" || event.key === "Space") {
      // Trigger the same action as the click event
      this.openModal(template);
    }
  }
}
