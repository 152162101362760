<div class="container-lg">
  <div class="row">
    <div class="col-md-12 text-center mb-4">
      <div>
        <a [routerLink]="['/dashboard']">
          <i class="bi bi-arrow-left"></i> Back to team overview
        </a>
      </div>
    </div>
  </div>

  <!-- Row for "LIVE SESSION" and red dot -->
  <div class="row">
    <div class="col-md-8">
      <div class="d-inline-block align-left pr-3">
        <i class="bi bi-dot"></i>
      </div>
      <h2 class="d-inline-block align-left">LIVE SESSION</h2>
    </div>
    <div class="col-md-4">
      <!-- Position "app-session-card" to top right -->
      <div class="position-absolute top-0 end-0 p-2 text-white mb-20">
        <!--<app-session-card></app-session-card> -->
      </div>
    </div>
  </div>

  <!-- Row for "app-impact-timeline" and "app-strength-card" -->
  <div class="row">
    <div class="col-md-6 mt-4">
      <app-impact-timeline></app-impact-timeline>
    </div>
    <div class="col-md-6 mt-4">
      <app-strength-card></app-strength-card>
    </div>
  </div>

  <!-- Additional content goes here -->
  <!--SESSION LENGTH HERE
  <div class="row">
    <div class="col-md-4">
      <i class="bi  bi-arrow-left" style="10px"></i>{{season.startDate}} <i class="bi bi-arrow-right" style="font-size:10px"></i>{{season.end}}
    </div>
  </div>
  -->

  <div class="row">
    <div class="col-md-12">
      <app-player-list></app-player-list>
    </div>
  </div>
</div>
