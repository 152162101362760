import { EntryState } from "./../../state/entry-state";

import { Component, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable, Subscription, combineLatest, tap } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { ProfileActions, ProfileState } from "src/app/state/profile-state";
import { ActivatedRoute } from "@angular/router";
import { EntryActions } from "src/app/state/entry-state";
import { IProfile, IEntry } from "src/act-common-web/src/index";

@Component({
  selector: "app-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"]
})
export class ProfilePageComponent implements OnDestroy {
  @Select(ProfileState.current) profile$?: Observable<IProfile | null>;
  public entries$?: Observable<Array<IEntry> | null>;
  private subscription: Subscription;
  private userId = "";
  private profileId = "";

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public store: Store
  ) {
    this.subscription = combineLatest({ user: authService.user$, params: this.route.params })
      .pipe(
        tap(result => {
          this.userId = result.user?.uid ?? "";
          this.profileId = result.params["pid"];
          this.store.dispatch(new ProfileActions.Select(this.profileId));
          this.store.dispatch(
            new EntryActions.FetchInitial(result.user?.uid ?? "", this.profileId)
          );
          this.store.dispatch(new EntryActions.ClearSelection());
          this.entries$ = this.store.select(EntryState.listForParent(this.profileId));
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  fetchMore(): void {
    this.store.dispatch(new EntryActions.FetchMoreEntries(this.userId, this.profileId));
  }
}
