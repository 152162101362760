// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// version from package .json
import PackageInfo from "../../package.json";

export const environment = {
  production: false,

  // Testing..
  useEmulators: false,

  // Build params: name, version..
  appDisplayName: "ACT-dashboard",
  appName: PackageInfo.name,
  appVersion: PackageInfo.version,
  buildNumber: "",
  gitTag: "",
  gitCommit: "",

  // ACT-cloud config: firebase
  firebase: {
    apiKey: "AIzaSyB3VyGAA2OpgRSeVR-WpjOBq3AA55PjESQ",
    authDomain: "widian-nsii.firebaseapp.com",
    databaseURL: "https://widian-nsii.firebaseio.com",
    projectId: "widian-nsii",
    storageBucket: "widian-nsii.appspot.com",
    messagingSenderId: "24389928584"
    // TODO: appId: '<your-app-id>',
  },
  emulators: {
    host: undefined,
    firestore: {
      useEmulator: false,
      port: 0
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/plugins/zone-error"; // Included with Angular CLI.
