import { Location } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap, Subscription } from "rxjs";
import { ISession, Statistics } from "src/act-common-web/src";
import { ISeason } from "src/act-common-web/src/models/season";
import { SeasonState } from "src/app/state/season.state";
import { SessionActions, SessionState } from "src/app/state/session.state";

export enum TimeFrame {
  SEASON = "season",
  MONTH = "month",
  WEEK = "week",
  SESSION = "session"
}

@Component({
  selector: "app-team-statistics",
  templateUrl: "./team-statistics.component.html",
  styleUrls: ["./team-statistics.component.scss"]
})
export class TeamStatisticsComponent implements OnDestroy, OnChanges {
  @Input() teamId?: string;

  seasons$?: Observable<Array<ISeason>>;
  sessions$?: Observable<Array<ISession> | undefined>;
  seasonsSub?: Subscription;
  sessionsSub?: Subscription;

  public statistics?: Statistics; ///< Statistics that is passed to showing components

  public TimeFrame = TimeFrame; // Export to template;
  public selectedTimeFrame: TimeFrame = TimeFrame.SEASON;

  public season?: ISeason;
  public session?: ISession;

  public seasons?: Array<ISeason>;
  public sessions?: Array<ISession>;

  public selectedSession?: string;

  public filteredSessions?: Array<ISession>;

  destroy$ = new Subject();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.teamId && this.teamId) {
      this.seasons$ = this.store.select(SeasonState.pastSeasonsForTeam(this.teamId));

      this.seasonsSub?.unsubscribe();
      this.seasonsSub = this.seasons$
        ?.pipe(
          takeUntil(this.destroy$),
          tap(seasons => {
            this.seasons = seasons;
            // Check if season is not anymore available
            if (!this.seasons?.some(s => s.id === this.season?.id)) {
              // Find closest season
              let closestSeason: ISeason | undefined;
              this.seasons?.forEach(season => {
                if (
                  (season.begin?.toMillis() ?? 0) > (closestSeason?.begin?.toMillis() ?? 0) &&
                  (season.begin?.toMillis() ?? 0) < Date.now()
                ) {
                  closestSeason = season;
                }
              });
              this.season = closestSeason;
              this.updateSessions();
            }
          })
        )
        .subscribe();
    }
  }

  updateSessions() {
    if (this.teamId && this.season?.id) {
      this.sessionsSub?.unsubscribe();
      this.store.dispatch(new SessionActions.FetchSeasonsForSession(this.teamId, this.season.id));
      this.sessions$ = this.store.select(SessionState.pastSessionsForSeason(this.season?.id));
      this.sessionsSub = this.sessions$
        ?.pipe(
          takeUntil(this.destroy$),
          tap(sessions => {
            this.sessions = sessions ?? [];

            if (!this.sessions?.some(s => s.id === this.session?.id)) {
              // Find closest session
              let closestSession: ISession | undefined;
              this.sessions?.forEach(session => {
                if (
                  (session.begin?.toMillis() ?? 0) > (closestSession?.begin?.toMillis() ?? 0) &&
                  (session.begin?.toMillis() ?? 0) < Date.now()
                ) {
                  closestSession = session;
                }
              });
              this.session = closestSession;
            }
            this.updateStatistics();
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  goBack(): void {
    this.location.back();
  }

  timeFrameSelected(timeframe: TimeFrame) {
    this.selectedTimeFrame = timeframe;
    this.updateStatistics();
  }

  updateStatistics() {
    if (this.selectedTimeFrame === TimeFrame.SEASON) {
      this.statistics = this.season?.seasonStats;
      this.filteredSessions =
        this.sessions; /* ?.filter(s => s.begin?.toMillis() ?? 0 <= Date.now());*/
    } else if (this.selectedTimeFrame === TimeFrame.MONTH) {
      //this.statistics = this.season?.monthStats;
      //this.filteredSessions = this.sessions?.filter()
    } else if (this.selectedTimeFrame === TimeFrame.SESSION) {
      this.statistics = this.session?.statistics;
      this.filteredSessions = this.session ? [this.session] : []; // Only one session
    }
  }

  next() {
    if (this.selectedTimeFrame === TimeFrame.SEASON) {
      const curIdx = this.seasons?.findIndex(s => s.id === this.season?.id);
      const seasonCount = this.seasons?.length ?? 0;
      if (curIdx !== undefined && curIdx >= 0 && curIdx <= seasonCount - 1) {
        this.season = this.seasons?.[curIdx + 1];
      }
      this.updateSessions();
    } else if (this.selectedTimeFrame === TimeFrame.SESSION) {
      const curIdx = this.sessions?.findIndex(s => s.id === this.session?.id);
      const sessionCount = this.sessions?.length ?? 0;
      if (curIdx !== undefined && curIdx >= 0 && curIdx <= sessionCount - 1) {
        this.session = this.sessions?.[curIdx + 1];
      }
      this.updateStatistics();
    }
  }

  previous() {
    if (this.selectedTimeFrame === TimeFrame.SEASON) {
      const curIdx = this.seasons?.findIndex(s => s.id === this.season?.id);
      if (curIdx !== undefined && curIdx > 0) {
        this.season = this.seasons?.[curIdx - 1];
      }
      this.updateSessions();
    } else if (this.selectedTimeFrame === TimeFrame.SESSION) {
      const curIdx = this.sessions?.findIndex(s => s.id === this.session?.id);
      if (curIdx !== undefined && curIdx > 0) {
        this.session = this.sessions?.[curIdx - 1];
      }
      this.updateStatistics();
    }
  }

  get previousSessionAvailable(): boolean {
    const idx = this.sessions?.findIndex(s => s.id === this.session?.id);
    return idx !== undefined && idx > 0;
  }

  get nextSessionAvailable(): boolean {
    const idx = this.sessions?.findIndex(s => s.id === this.session?.id);
    return idx !== undefined && idx < (this.sessions?.length ?? 0) - 1;
  }

  get previousSeasonAvailable(): boolean {
    const idx = this.seasons?.findIndex(s => s.id === this.season?.id);
    return idx !== undefined && idx > 0;
  }

  get nextSeasonAvailable(): boolean {
    const idx = this.seasons?.findIndex(s => s.id === this.season?.id);
    return idx !== undefined && idx < (this.seasons?.length ?? 0) - 1;
  }
}
