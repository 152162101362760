// Save user input to state > possibility to modify if necessary?

import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, createSelector } from "@ngxs/store";
import { ITeam } from "src/act-common-web/src/models/team";
import { TeamsService } from "../services/teams.service";
import { StateOperator, append, compose, patch, updateItem } from "@ngxs/store/operators";
import { OnEvent } from "./events";
import { Router } from "@angular/router";
import { BaseActions, BaseState } from "./base-state";
import { AuthService } from "../services/auth.service";
import { Timestamp } from "@angular/fire/firestore";

/**
 * Team Actions
 */
export namespace TeamActions {
  export class Init {
    static readonly type = "[Team] Init";
    constructor() {}
  }

  export class Select {
    static readonly type = "[Team] Select";
    constructor(public teamId?: string) {}
  }

  export class Create {
    static readonly type = "[Team] Create Team";
    constructor(
      public name: string,
      public subtitle: string,
      public description: string,
      //public year: number,
      public profileIds: string[],
      public redirect: boolean = true
    ) {}
  }

  export class Update implements BaseActions.Update<ITeam> {
    static readonly type = "[Team] Update Team";
    constructor(public model: ITeam) {}
  }

  export class Delete {
    static readonly type = "[Team] Delete team";
    constructor(
      public teamId: string,
      public redirect: boolean = true
    ) {}
  }
}

/**
 * Team State
 */
export interface TeamStateModel {
  current?: string;
  list: Array<ITeam>;
  selectedProfileIds: string[];
}

@State<TeamStateModel>({
  name: "team",
  defaults: {
    current: undefined,
    list: [],
    selectedProfileIds: []
  }
})
@Injectable()
export class TeamState extends BaseState<ITeam, TeamStateModel> {
  constructor(
    private teamService: TeamsService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
  }

  // Selectors for retrieving specific team/season/session data to display to UI
  static getTeam(id: string) {
    return createSelector([TeamState], (state: TeamStateModel) => {
      return state?.list?.find(team => team?.id === id);
    });
  }

  @Selector()
  static current(state: TeamStateModel) {
    return state.list.find(l => l.id === state.current);
  }

  @Selector()
  static list(state: TeamStateModel) {
    return state.list ?? [];
  }

  @Selector()
  static getProfileIds(state: TeamStateModel) {
    return state.selectedProfileIds;
  }

  @Action(OnEvent.UserLoggedIn)
  initTeamsState(ctx: StateContext<TeamStateModel>, action: TeamActions.Init) {
    return this.teamService.startListenChanges(
      undefined,
      teams => {
        const state = ctx.getState();

        // Update current, or leave it
        //const current = teams.find(t => state.current?.id) ?? state.current;

        const listOperators: Array<StateOperator<Array<ITeam>>> = teams.map(team => {
          return state.list.some(t => t.id === team.id)
            ? updateItem<ITeam>(t => t.id === team.id, team)
            : append<ITeam>([team]);
        });

        ctx.setState(
          patch({
            list: compose(...listOperators)
          })
        );
      },
      30
    ); // 30 teams is maxinum limit
  }

  /** Called when team is selected, it will dispatch event start to fetch team statistics */
  @Action(TeamActions.Select)
  selectTeam(ctx: StateContext<TeamStateModel>, action: TeamActions.Select) {
    super.select(ctx, action.teamId);
    ctx.dispatch(new OnEvent.TeamSelected(action.teamId));
  }

  /**
   *
   * @param ctx new team
   * @param action Create new team
   * TODO: Add to Firestore
   */

  @Action(TeamActions.Create)
  createTeam(ctx: StateContext<TeamStateModel>, action: TeamActions.Create) {
    const uuid = this.authService.uuid;
    const state = ctx.getState();

    const teamData: ITeam = {
      id: "",
      name: action.name,
      subtitle: action.subtitle,
      description: action.description,
      //year: action.year,
      owner: uuid,
      sharedTo: [uuid],
      parent: "",
      created: Timestamp.now(),
      updated: Timestamp.now(),
      profileIds: action.profileIds
    };
    return this.teamService.add(teamData, undefined).then(docRef => {
      teamData.id = docRef.id;
      // Update will handle the append also
      super.update(ctx, new TeamActions.Update(teamData));

      if (action.redirect) {
        this.router.navigate(["manage", docRef.id]);
      }
    });
  }

  @Action(TeamActions.Update)
  updateTeam(ctx: StateContext<TeamStateModel>, action: TeamActions.Update) {
    const updateFields = {
      name: action.model.name,
      subtitle: action.model.subtitle,
      description: action.model.description,
      updated: Timestamp.now(),
      profileIds: action.model.profileIds
    };
    return this.teamService.update(action.model.id, undefined, updateFields).then(result => {
      super.update(ctx, action);
    });
  }

  @Action(TeamActions.Delete)
  async deleteTeam(ctx: StateContext<TeamStateModel>, action: TeamActions.Delete) {
    const state = ctx.getState();
    const teamId = action.teamId;

    await this.teamService.remove(teamId, undefined);
    const updatedTeams = state.list.filter(team => team.id !== teamId);
    ctx.setState({ ...state, list: updatedTeams });
    if (action.redirect) {
      this.router.navigate(["/dashboard"]);
    }
  }
}
