import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { IProfile, IProfileStatistics } from "src/act-common-web/src";
import { PlayerListSortColumn } from "../../../../player-list/player-list/player-list.component";
import { Subject } from "rxjs";
import { Store } from "@ngxs/store";
import { ActivatedRoute } from "@angular/router";
import { IStatisticEntry } from "src/act-common-web/src/models/statistics/statistic-entry";

@Component({
  selector: "app-impact-list",
  templateUrl: "./impact-list.component.html",
  styleUrls: ["./impact-list.component.scss"]
})
export class ImpactListComponent implements OnChanges, OnDestroy {
  //@Input() statistics?: Statistics;

  /*TODO: rad/s*/

  @Input() entries?: IProfileStatistics;
  players!: Array<IProfile>;
  teamId?: string;
  private destroy$ = new Subject();
  sortedEntries?: Array<IStatisticEntry>;

  public PlayerListSortColumn = PlayerListSortColumn;
  sortBy: PlayerListSortColumn = PlayerListSortColumn.LATEST;

  constructor(
    public store: Store,
    public route: ActivatedRoute
  ) {}

  ngOnChanges(): void {
    this.refresh();
  }

  /* ngOnInit(): void {
  }*/

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  navClicked(column: PlayerListSortColumn) {
    this.sortBy = column;
    this.refresh();
  }

  refresh() {
    console.log("Entries updated", this.entries?.statistics.maxAv);
    if (this.entries?.statistics?.entries) {
      if (this.sortBy === PlayerListSortColumn.LATEST) {
        console.log(this.entries.statistics.entries);
        // Sort entries based on timestamp in descending order (newest to oldest)
        this.sortedEntries = [...this.entries.statistics.entries].sort((a, b) => {
          const timestampA = a.timestamp?.toMillis() ?? 0;
          const timestampB = b.timestamp?.toMillis() ?? 0;
          return timestampB - timestampA;
        });
      } else if (this.sortBy === PlayerListSortColumn.HEAVIEST) {
        // Implement logic for sorting by HEAVIEST
        this.sortedEntries = [...this.entries.statistics.entries].sort((a, b) => {
          if (a.la !== undefined && b.la !== undefined) {
            return b.la - a.la;
          }
          // If a / b is undefined return 0
          return 0;
        });
      } else if (this.sortBy === PlayerListSortColumn.HIGHEST_AV) {
        // Implement logic for sorting by HEAVIEST
        this.sortedEntries = [...this.entries.statistics.entries].sort((a, b) => {
          if (a.av !== undefined && b.av !== undefined) {
            return b.av - a.av;
          } else if (a.av !== undefined) {
            return -1;
          } else if (b.av !== undefined) {
            return 1;
          }
          // If a & b is undefined return 0
          return 0;
        });
      }
    } else {
      this.sortedEntries = undefined;
    }
  }

  maxValue(values: Array<number> | undefined): number {
    let result: number | undefined = undefined;
    if (values !== undefined) {
      for (const val of values) {
        if (result === undefined || val > result) {
          result = val;
        }
      }
    }
    return result ?? 0; // No need to support negative values
  }
}
