<ul class="navigation">
  <li>
    <a
      [ngClass]="{ active: sortBy === PlayerListSortColumn.LATEST }"
      (click)="navClicked(PlayerListSortColumn.LATEST)"
      (keypress.enter)="navClicked(PlayerListSortColumn.LATEST)"
      tabindex="0">
      Latest events
    </a>
  </li>
  <li>
    <a
      [ngClass]="{ active: sortBy === PlayerListSortColumn.HEAVIEST }"
      (click)="navClicked(PlayerListSortColumn.HEAVIEST)"
      (keypress.enter)="navClicked(PlayerListSortColumn.HEAVIEST)"
      tabindex="0">
      Highest G-Force
    </a>
  </li>
  <li>
    <a
      [ngClass]="{ active: sortBy === PlayerListSortColumn.HIGHEST_AV }"
      (click)="navClicked(PlayerListSortColumn.HIGHEST_AV)"
      (keypress.enter)="navClicked(PlayerListSortColumn.HIGHEST_AV)"
      tabindex="0">
      Highest rad/s
    </a>
  </li>
</ul>

<!-- Display entries if available -->

<ng-container *ngFor="let entry of sortedEntries">
  <div class="card player-card mb-2">
    <div class="card-body">
      <!-- Display individual entry data -->
      <div class="subheader">
        <span>{{ entry.timestamp.toDate() | date: "short" }}</span>
      </div>
      <div *ngIf="this.sortBy !== PlayerListSortColumn.HIGHEST_AV" class="header text-success">
        <i class="bi bi-lightning-fill"></i>
        <span>{{ entry.la | number: "1.0-0" }}</span>
        <span class="unit"><i>g</i></span>
      </div>
      <div
        *ngIf="this.sortBy === PlayerListSortColumn.HIGHEST_AV && entry.av"
        class="header text-success">
        <i class="bi bi-lightning-fill"></i>
        <span>{{ entry.av | unitConvert | number: "1.0-0" }}</span>
        <span class="unit"><i>rad/s</i></span>
      </div>
    </div>
  </div>
</ng-container>

<!-- Display message if no entries recorded -->
<div *ngIf="!sortedEntries">
  <p>No entries recorded</p>
</div>
<!-- <div *ngIf="sortBy === PlayerListSortColumn.LATEST" class="header text-success">

          <div *ngIf="sortBy === PlayerListSortColumn.HEAVIES" class="header text-success">
            <i class="bi bi-lightning-fill"></i>
            <span>{{ profileData.statistics.maxForce | max | number : "1.0-0" }}</span>
            <small class="unit"><i>g</i></small>
          </div>
          <div *ngIf="sortBy === PlayerListSortColumn.HIGHEST_AV" class="header text-success">
            <i class="bi bi-lightning-fill"></i>
            <span>{{ profileData.statistics.maxAv | max | number : "1.0-0" }}</span>
            <span class="unit">rad/s</span>
          </div>
          <div *ngIf="sortBy === PlayerListSortColumn.MOST" class="header text-success">
            <span>{{ profileData.statistics.totalCount }}</span>
          </div>
        </div>
      </div> -->
