import { EntryActions } from "src/app/state/entry-state";
import { EntryService } from "./../../services/entry.service";
import { ProfileService } from "./../../services/profile.service";
import { ProfileActions } from "./../../state/profile-state";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Observable, Subject, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Select, Store } from "@ngxs/store";
import { IProfile } from "src/act-common-web/src/index";
import { TeamActions, TeamState } from "src/app/state/teams.state";
import { ITeam } from "src/act-common-web/src/models/team";
import { TeamsService } from "src/app/services/teams.service";
import { User } from "@angular/fire/auth";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Select(TeamState.list) teams$!: Observable<Array<ITeam>>;

  actUser$: Observable<User | null>;
  profiles$: Observable<Array<IProfile>>;

  showTeamIntroduction = true;
  showNewTeamCard = false;

  destroy$ = new Subject();

  constructor(
    public authService: AuthService,
    public profileService: ProfileService,
    public entryService: EntryService,
    public teamsService: TeamsService,
    public store: Store,
    public teamState: TeamState
  ) {
    this.actUser$ = this.authService.user$;
    // Get Profiles based on user
    this.profiles$ = this.actUser$.pipe(
      mergeMap(user => {
        if (user && user.uid) {
          profileService.startListenChanges(
            undefined,
            profiles =>
              // Map array of changed profiles to array
              this.store.dispatch(profiles.map(p => new ProfileActions.Update(p))),
            100
          );
          entryService.startListenChanges(undefined, entries =>
            this.store.dispatch(entries.map(e => new EntryActions.Update(e)))
          );

          return this.store.select(state => state.profile.list);
        } else {
          return of([]);
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.store.dispatch([
      new ProfileActions.ClearSelection(),
      new TeamActions.Select() // Selects undefined,
    ]);

    // When the first team is created, make create new team card visible and add the possibility to create new team
    this.teams$?.subscribe(teams => {
      if (teams) {
        this.showTeamIntroduction = true; // Update here
        this.showNewTeamCard = false; // Update here
      }
    });
  }
}
