import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";

// tslint:disable-next-line: no-namespace
export namespace UserActions {
  export class Update {
    static readonly type = "Update";
    constructor(public name: string) {}
  }
}

interface UserStateModel {
  name: string | null;
}

@State<UserStateModel>({
  name: "user",
  defaults: {
    name: null
  }
})
@Injectable()
export class UserState {
  @Action(UserActions.Update)
  update(ctx: StateContext<UserStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state
    });
  }
}
