import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BleClient, BleDevice, BleService, ScanResult } from "@capacitor-community/bluetooth-le";
import { ConnectedDevice } from "./connecteddevice.interface";

const ActServiceUuidStr = "84080000-9176-4993-a491-b6ccecc55bdc";
const ImpactDataServiceUuidStr = "84081000-9176-4993-a491-b6ccecc55bdc";
const UnActivatedServiceUuidStr = "84082000-9176-4993-a491-b6ccecc55bdc";

@Component({
  selector: "app-bluetoothplugin-test",
  templateUrl: "./bluetoothplugin-test.component.html",
  styleUrls: ["./bluetoothplugin-test.component.scss"]
})
export class BluetoothpluginTestComponent implements OnInit {
  device: ConnectedDevice = { deviceId: "", name: "" };
  ble = false;
  bluetoothStatus = "";
  connectedDevice: ConnectedDevice | null = null;
  isListening = false;
  showStopButton = false;
  showStartButton = true;
  scannedDevice: ConnectedDevice[] = [];
  discoveredDevices: ConnectedDevice[] = [];
  isScanning = false;
  scanData = "";
  serviceData = "";

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    BleClient.initialize().then(() => {
      BleClient.isEnabled().then(res => {
        this.ble = res;
      });
    });
  }

  /**
   *  Method to receive bluetooth connection and the device
   */
  async startScanning() {
    this.bluetoothStatus = "Connecting";
    try {
      await this.main(); // Call the main function
      this.bluetoothStatus = "Connected";
    } catch (error) {
      console.error(error);
      this.bluetoothStatus = "Connection error";
    }
  }

  //Main function to connect to a bluetooth device
  // Using  requestBleScan
  public async main(): Promise<void> {
    try {
      await BleClient.requestLEScan(
        {
          services: [ActServiceUuidStr, ImpactDataServiceUuidStr, UnActivatedServiceUuidStr]
        },
        device => this.onDeviceFound(device)
      );

      console.log("Scanning for devices...");
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Callback function to add found device to list > HTML
   * @returns Service
   */

  async onDeviceFound(device: ScanResult): Promise<void> {
    // Check if device is already scanned
    const deviceIsFound = this.discoveredDevices.some(
      discoveredDevice => discoveredDevice.deviceId === device.device.name
    );
    console.log("device is already added!", deviceIsFound);
    if (deviceIsFound) {
      return;
    }
    // Handle found device
    console.log("Found device");
    const scannedDevice: ConnectedDevice = {
      deviceId: device.uuids?.[0] || "",
      name: device.localName || ""
    };
    this.discoveredDevices.push(scannedDevice);
    // Get data from the scanning
    const scanData = `Local Name: ${device.localName}, RSSI: ${device.rssi}, Tx Power: ${device.txPower}, UUIDS: ${device.uuids}, Service data: ${device.serviceData}`;

    this.scanData = scanData;
    await BleClient.connect(device.device.deviceId);

    this.connectedDevice = scannedDevice;

    this.changeDetectorRef.detectChanges();

    if (scannedDevice) {
      try {
        const services = await BleClient.getServices(device.device.deviceId);
        // this.serviceData = services
        console.log("Here are available services:", services);
      } catch (error) {
        console.error(error);
      }
    }
  }

  // Method for reading the device and desired service
  async readService() {
    if (!this.connectedDevice) {
      return;
    }
    try {
      const deviceId = this.connectedDevice.deviceId;
      const serviceUUID = ActServiceUuidStr;
      const services = await BleClient.getServices(deviceId);
      console.log(" Here are available services!:", services);
      const service = services.find(s => s.uuid === serviceUUID);
      if (service) {
        const characteristics = service.characteristics;
        const characteristicValues: { [uuid: string]: any } = {};
        for (const characteristic of characteristics) {
          const value = await BleClient.read(deviceId, serviceUUID, characteristic.uuid);
          console.log("Device id is :", deviceId);
          console.log("service uuid is:", serviceUUID);
          console.log("Characteristic UUUId is", characteristic.uuid);
          characteristicValues[characteristic.uuid] = value;
        }
        this.updateUI(characteristicValues);
      } else {
        console.log("Service not found");
      }
    } catch (error) {
      console.error("Error while reading the service", error);
    }
  }

  /**
   *
   * @param characteristicValues Return characteristic values from ACT- service
   */

  updateUI(characteristicValues: { [uuid: string]: any }) {
    console.log("");
  }

  /**
   *
   * @param deviceId
   */

  onDisconnect(deviceId: string): void {
    console.log("Device $(deviceId) disconnected", deviceId);
  }

  /**
   * Stop scanning
   */
  async stopScanning() {
    await BleClient.stopLEScan();
  }

  /**
   * Start listening for possible impacts
   */
  startListening() {
    this.isListening = true;
    this.showStopButton = true;
    this.showStartButton = false;

    // Call for impact
    // this.listenForImpact();
  }
  /**
   * Stop listening to impacts
   */

  stopListening() {
    this.isListening = false;
    this.showStopButton = false;
    this.showStartButton = true;
  }

  // Handle impact detection
  async listenForImpact(): Promise<void> {
    this.isListening = true;

    this.isListening = false;
  }

  conditionToStopListening(): boolean {
    return false;
  }
}
