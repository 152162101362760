<div class="container-lg">
  <div class="d-flex justify-content-between align-items-center">
    <a [routerLink]="backLink" class="navigation">
      <i class="bi bi-arrow-left"></i>
      Back to team
    </a>
    <a
      *ngIf="editMode"
      class="navigation"
      (click)="openModal(template)"
      (keydown)="handleKeyDown($event, template)"
      tabindex="0">
      <i class="bi bi-trash3"></i>
    </a>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="mt-4">
        <div class="card">
          <div class="card-body" [formGroup]="seasonForm">
            <h1 class="title mb-3">
              {{ editMode ? "Manage season" : "Create a new season" }}
            </h1>
            <p *ngIf="editMode">
              You can edit season details if necessary. Then click save to save new details.
            </p>
            <!-- Season Name -->
            <div class="row mb-2 mt-3">
              <div class="col">
                <label for="name">Season Name:</label>
                <input type="text" id="name" formControlName="name" class="form-control" />
              </div>
            </div>

            <div class="row mb-2 mt-2">
              <!-- Season Starts -->
              <div class="col-md-6">
                <label for="startDate">Season starts</label>
                <input
                  id="startDate"
                  class="form-control"
                  #datepickerYMD="bsDatepicker"
                  bsDatepicker
                  formControlName="startDate"
                  placeholder="Select start date:"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                  [minDate]="seasonBeginMinDate"
                  [maxDate]="seasonEnd" />
              </div>

              <!-- Season Ends -->
              <div class="col-md-6">
                <label for="endDate">Season ends</label>
                <input
                  id="endDate"
                  class="form-control"
                  #datepickerYMD="bsDatepicker"
                  bsDatepicker
                  formControlName="endDate"
                  placeholder="Select end date:"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                  [minDate]="seasonEndMinDate" />
              </div>
            </div>

            <!-- Buttons inside the card -->
            <div class="row mt-4 mb-2">
              <div class="col d-flex justify-content-end">
                <div>
                  <button class="btn btn-secondary ml-1" [routerLink]="backLink">Cancel</button>

                  <button
                    *ngIf="!editing && !editMode"
                    class="btn btn-primary ml-1"
                    (click)="submit()"
                    [disabled]="seasonForm.invalid">
                    Create Season
                  </button>

                  <button
                    *ngIf="editMode && !editing"
                    class="btn btn-primary ml-1"
                    (click)="toggleEditMode()">
                    Edit Season
                  </button>

                  <button
                    *ngIf="editing"
                    class="btn btn-primary ml-1"
                    (click)="submit()"
                    [disabled]="seasonForm.invalid">
                    Save Season
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="editMode">
    <h1 class="mt-4">Your Sessions</h1>
    <div class="row mt-4">
      <div
        class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4 manage-card"
        *ngFor="let session of sessions$ | async">
        <div
          class="card session-card"
          [ngClass]="{
            active: sessionActive(session),
            ended: sessionEnded(session)
          }"
          [routerLink]="['sessions', session.id]">
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <div class="header">{{ session.name }}</div>
            <div class="subheader">
              {{ session.begin?.toDate() | date: "mediumDate" }}
            </div>
            <div class="subheader">
              {{ session.begin?.toDate() | date: "shortTime" }} -
              {{ session.end?.toDate() | date: "shortTime" }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4">
        <div class="card session-card" routerLink="sessions">
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <div class="header"><i class="bi bi-plus-circle-fill"></i></div>
            <div class="subheader">Add new session</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-header">
    <h4 class="header">Delete Season</h4>
  </div>
  <div class="modal-body">
    This action will delete the selected season and all its associated sessions. Do you want to
    proceed?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef?.hide()">Close</button>
    <button type="button" class="btn btn-danger" (click)="deleteSeason()">Delete</button>
  </div>
</ng-template>
