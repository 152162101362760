<div class="card">
  <div class="card-body">
    <h1>Impact Report</h1>

    <div>
      <p>
        Export impact data as tab separated values file for each own profile on given date range.
      </p>
    </div>

    <form [formGroup]="form">
      <div class="form-group row">
        <label for="udDateRange" class="col-4 col-form-label">Date range</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            id="dateRange"
            name="dateRange"
            formControlName="dateRange"
            placeholder="Pick date range"
            #drp="bsDaterangepicker"
            bsDaterangepicker
            [minDate]="minDate"
            [maxDate]="maxDate"
            [bsConfig]="{
              containerClass: 'theme-blue',
              minMode: 'day',
              ranges: bsRanges,
              showTodayButton: true,
              todayPosition: 'right',
              rangeInputFormat: 'DD.MM.YYYY',
              dateInputFormat: 'DD.MM.YYYY',
              showWeekNumbers: true,
              adaptivePosition: true
            }" />
        </div>
      </div>

      <div class="ml-auto">
        <button
          [disabled]="fetchingReport"
          type="submit"
          class="btn btn-primary"
          (click)="getReport()">
          <i class="bi bi-download"></i>
          Download
        </button>
      </div>
    </form>
  </div>
</div>
