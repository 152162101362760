import { concatMap, debounceTime, groupBy, tap } from "rxjs/operators";
import { EntryService } from "./../services/entry.service";
import { Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext, Store } from "@ngxs/store";
import { BaseActions, BaseState, BaseStateModel } from "./base-state";
import { IEntry } from "src/act-common-web/src/index";
import { from, Subject, Subscription } from "rxjs";
import { AuthService } from "../services/auth.service";
import { isArray } from "ngx-bootstrap/chronos";
import { UserService } from "../services/user.service";

// tslint:disable-next-line: no-namespace
export namespace EntryActions {
  export class Update {
    static readonly type = "[Entry] Update";
    constructor(
      public model: IEntry,
      public checkForMissingEntries: boolean = true
    ) {}
  }

  export class Select extends BaseActions.Select {
    static readonly type = "[Entry] Select";
  }

  export class FetchInitial {
    static readonly type = "[Entry] Fetch Initial";
    constructor(
      public uid: string,
      public pid: string
    ) {}
  }

  export class FetchMoreEntries {
    static readonly type = "[Entry] Fetch More Entries";
    constructor(
      public uid: string,
      public pid: string
    ) {}
  }

  export class ClearSelection {
    static readonly type = "[Entry] Clear Selection";
    constructor() {}
  }

  export class SetActive {
    static readonly type = "[Entry] Set Active";
    constructor(
      public uid: string,
      public eid: string,
      public active: boolean
    ) {}
  }

  export class SetNotes {
    static readonly type = "[Entry] Set Notes";
    constructor(
      public uid: string,
      public eid: string,
      public notes: string
    ) {}
  }

  export class DeveloperGenerateImpact {
    static readonly type = "[Entry] Dev Generate Impact";
    constructor(public sensorNumber: string) {}
  }
}

interface MissingEntryTimeline {
  profile: any;
  sensor: any;
  after: any;
  before: any;
}

interface EntryStateModel extends BaseStateModel<IEntry> {
  current?: string;
  list: Array<IEntry>;
}

@State<EntryStateModel>({
  name: "entry",
  defaults: {
    current: undefined,
    list: [] // null
  }
})
@Injectable()
export class EntryState extends BaseState<IEntry, EntryStateModel> {
  missingEntryCheck: Subject<void>;
  missingEntryCheckSubscription: Subscription;

  constructor(
    public entryService: EntryService,
    public store: Store,
    public authService: AuthService,
    public userService: UserService
  ) {
    super();
    this.missingEntryCheck = new Subject<void>();
    this.missingEntryCheckSubscription = this.missingEntryCheck
      .pipe(
        debounceTime(5000),
        concatMap(() => {
          const missingEntryTimelines = this.collectMissingEntryTimelines();
          return from(missingEntryTimelines).pipe(
            concatMap(timeline => {
              return this.entryService.fetchEntries(
                authService.uuid,
                timeline.profile,
                timeline.after,
                timeline.before
              );
            }),
            tap(querySnapshot => {
              // Call Update For Each entry got
              querySnapshot.docs.forEach(entry => {
                const entryData = entry.data() as IEntry; // Cast to IEntry
                this.store.dispatch(new EntryActions.Update(entryData));
              });
            })
          );
        })
      )
      .subscribe();
  }

  @Selector()
  static list(state: EntryStateModel): Array<IEntry> | null {
    return state.list?.sort((a, b) => {
      return b.impactDate.seconds - a.impactDate.seconds;
    });
  }

  static listForParent(parentId: string) {
    return createSelector([EntryState], (state: EntryStateModel) => {
      return state.list
        ?.filter(e => e.parent === parentId)
        ?.sort((a, b) => {
          const res = b.impactDate.seconds - a.impactDate.seconds;
          if (res === 0) {
            return b.impactDate.nanoseconds - b.impactDate.nanoseconds;
          }
          return res;
        });
    });
  }

  @Selector()
  static current(state: EntryStateModel) {
    return state.list.find(e => e.id === state.current);
  }

  @Action(EntryActions.Select)
  selectEntry(ctx: StateContext<EntryStateModel>, action: EntryActions.Select): void {
    this.select(ctx, action.id);
  }

  @Action(EntryActions.ClearSelection)
  clearSelect(ctx: StateContext<EntryStateModel>, action: EntryActions.ClearSelection): void {
    this.clearSelection(ctx);
  }

  @Action(EntryActions.Update)
  update(ctx: StateContext<EntryStateModel>, action: EntryActions.Update): void {
    //console.log("Updating Entry State with action:", action);
    super.update(ctx, action);
    // Missing entry check is kind of pointless when fething a more items
    if (action.checkForMissingEntries) {
      // This might cause issues
      this.missingEntryCheck.next();
    }
  }

  @Action(EntryActions.FetchInitial)
  fetchInitial(ctx: StateContext<EntryStateModel>, action: EntryActions.FetchInitial): void {
    this.entryService.fetchInitialEntries(action.uid, action.pid, e =>
      ctx.dispatch(new EntryActions.Update(e, false))
    );
  }

  @Action(EntryActions.FetchMoreEntries)
  fetchMoreEntries(
    ctx: StateContext<EntryStateModel>,
    action: EntryActions.FetchMoreEntries
  ): void {
    const list = ctx.getState().list;
    const lastEntry = list
      .filter(e => e.parent === action.pid)
      .reduce((prev, cur) => {
        return prev.impactDate.seconds < cur.impactDate.seconds ? prev : cur;
      });
    this.entryService.fetchMoreEntries(action.uid, action.pid, lastEntry, e =>
      ctx.dispatch(new EntryActions.Update(e, false))
    );
  }

  @Action(EntryActions.SetActive)
  setActive(ctx: StateContext<EntryStateModel>, action: EntryActions.SetActive): Promise<void> {
    return this.entryService.update(action.eid, undefined, { active: action.active });
  }

  @Action(EntryActions.SetNotes)
  setNotes(ctx: StateContext<EntryStateModel>, action: EntryActions.SetNotes): Promise<void> {
    return this.entryService.update(action.eid, undefined, { notes: action.notes });
  }

  @Action(EntryActions.DeveloperGenerateImpact)
  developerGenerateImpact(
    ctx: StateContext<EntryStateModel>,
    action: EntryActions.DeveloperGenerateImpact
  ) {
    this.userService.developerGenerateImpact(action.sensorNumber);
  }

  /** Function that finds time ranges that might have entries added
   * Logic:
   * Group By Sensor Id
   * Sort By Created (Server timestamp)
   * Find timelines between missing indexes, make sure that index can go around. Prevent check on those items that go around
   */
  collectMissingEntryTimelines(): Array<MissingEntryTimeline> {
    const entries = this.store.selectSnapshot<Array<IEntry>>(state => state.entry.list);

    const result: Array<MissingEntryTimeline> = [];

    const grouppedArray = this.groupArrayBy(entries, entry => entry.sensorId);
    grouppedArray.forEach(group => {
      group.sort((a, b) => {
        return (
          (a.created?.seconds ?? 0 - b.created?.seconds ?? 0) ||
          (a.created?.nanoseconds ?? 0 - b.created.nanoseconds ?? 0)
        );
      });
      // Uses reduce to check the pairs of entries
      group.reduce((prev, curr, index) => {
        // Check if impact indexes are missing indexes between, and indexes are not overflown
        const idxDiff = Math.abs(prev.impactIdx - curr.impactIdx);
        if (idxDiff > 1 && idxDiff < 128) {
          const smallerIdx = Math.min(prev.impactIdx, curr.impactIdx);
          const biggerIdx = Math.max(prev.impactIdx, curr.impactIdx);
          for (let idx = smallerIdx + 1; idx < biggerIdx; idx++) {
            // Take entries 128 around this one to check for missing indexes to work even if entry index is overflown
            const nearItems = group.slice(
              Math.max(index - 64) + Math.min(index + 64, group.length)
            );
            if (!nearItems.find(e => e.impactIdx === idx)) {
              console.log(
                "Found missing entry between " + prev.impactIdx + " and " + curr.impactIdx
              );
              result.push({
                profile: prev.parent,
                sensor: null,
                before: prev,
                after: curr
              });
              break;
            }
          }
        }
        return curr; // return current value to continue iteration
      });
    });

    return result;
  }

  groupArrayBy<T>(array: Array<T>, func: (item: T) => string): Array<Array<T>> {
    const result = {};
    array.forEach(i => {
      const key = func(i) as keyof {}; // eslint-disable-line @typescript-eslint/ban-types
      if (isArray(result[key])) {
        (result[key] as Array<T>).push(i);
      } else {
        (result[key] as Array<T>) = [i];
      }
    });
    return Object.entries(result).map(([key, value]) => {
      return value;
    }) as Array<Array<T>>;
  }
}
