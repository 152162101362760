import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngxs/store";
import { ISeason, ISession } from "src/act-common-web/src";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject, Subscription, combineLatest, interval, tap } from "rxjs";
import { SessionActions, SessionState } from "src/app/state/session.state";
import { SessionModalComponent } from "../../modal/session-modal/session-modal.component";
import { ITeam } from "src/act-common-web/src/models/team";

@Component({
  selector: "app-session-card",
  templateUrl: "./session-card.component.html",
  styleUrls: ["./session-card.component.scss"]
})
export class SessionCardComponent implements OnDestroy, OnChanges {
  @Input() team?: ITeam | null;
  @Input() season?: ISeason | null;
  session?: ISession | null;
  nextSession?: ISession | null;

  sessions$?: Observable<Array<ISession> | undefined>;

  sessionForm?: FormGroup;
  showModal = false;
  bsModalRef?: BsModalRef;
  destroy$ = new Subject();

  private timerSub?: Subscription;

  constructor(
    private store: Store,
    private modalService: BsModalService,
    private route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.season?.currentValue?.id) {
      this.sessions$ = this.store.select(SessionState.listByParent(changes.season.currentValue.id));
      this.timerSub?.unsubscribe();
      this.timerSub = combineLatest({ timer: interval(1000), sessions: this.sessions$ })
        .pipe(
          //takeUntil(this.destroy$),
          tap(result => {
            // Update current session
            const now = Date.now();

            this.session = result.sessions?.find(
              s => (s.begin?.toMillis() ?? 0) <= now && (s.end?.toMillis() ?? 0) > now
            );
            this.nextSession = result.sessions?.find(s => (s.begin?.toMillis() ?? 0) > now);
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  openModal() {
    this.bsModalRef = this.modalService.show(SessionModalComponent, {
      initialState: { teamId: this.team?.id, seasonId: this.season?.id }
    });
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  get isActiveSeason(): boolean {
    const now = Date.now();
    return now > (this.season?.begin?.toMillis() ?? 0) && now < (this.season?.end?.toMillis() ?? 0);
  }

  get isActiveSession(): boolean {
    const now = Date.now();
    return (
      now > (this.session?.begin?.toMillis() ?? 0) && now < (this.session?.end?.toMillis() ?? 0)
    );
  }

  pause() {
    if (this.session && this.season && this.team?.id) {
      this.store.dispatch(new SessionActions.Pause(this.team.id, this.season.id, this.session.id));
    }
  }

  stop() {
    if (this.session && this.season && this.team?.id) {
      this.store.dispatch(new SessionActions.Stop(this.team.id, this.season.id, this.session.id));
    }
  }
}
