import { Observable, Subscription } from "rxjs";
import { ISession } from "src/act-common-web/src/index";
import { Injectable } from "@angular/core";
import { BaseModelService } from "./base-model.service";
import { tap } from "rxjs/operators";
import {
  FirestoreDataConverter,
  CollectionReference,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class SessionService extends BaseModelService<
  ISession,
  { teamId: string; seasonId: string }
> {
  protected collectionName = "sessions";

  protected converter: FirestoreDataConverter<ISession> = {
    toFirestore(session: ISession): DocumentData {
      return session;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): ISession {
      const data = snapshot.data(options)!;
      return {
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        name: data["name"],
        location: data["location"],
        begin: data["begin"],
        end: data["end"],
        statistics: data["statistics"]
      };
    }
  };

  protected extractCollectionPath(parentIds: { teamId: string; seasonId: string }): string[] {
    return ["teams", parentIds?.teamId, "seasons", parentIds?.seasonId, "sessions"];
  }
}
