<div class="container-lg" *ngIf="profile$ | async as profile">
  <div class="row">
    <div class="col-md mb-4" style="height: 100%">
      <app-profile-card [profile]="profile"></app-profile-card>
    </div>
    <div class="col-md mb-4" *ngIf="profile?.lastEntry?.laStats || profile.lastEntry?.avStats">
      <div class="card" style="height: 100%">
        <div class="card-header d-flex">
          <strong>Last impact</strong>
        </div>

        <div class="card-body">
          <div class="row">
            <table
              class="statistic-table table table-sm table-striped text-right"
              *ngIf="profile?.lastEntry as lastEntry">
              <tbody>
                <tr *ngIf="lastEntry.laStats?.initialHigh">
                  <th>Maximum initial <i>g</i>-force</th>
                  <td>{{ lastEntry.laStats?.initialHigh | number: "1.0-0" }}</td>
                  <td><i>g</i>-force</td>
                </tr>
                <tr *ngIf="lastEntry.laStats?.totalHigh">
                  <th>Maximum <i>g</i>-force</th>
                  <td>{{ lastEntry.laStats?.totalHigh | number: "1.0-0" }}</td>
                  <td><i>g</i>-force</td>
                </tr>
                <tr *ngIf="lastEntry.laStats?.initialAUC">
                  <th>Initial impact g-load</th>
                  <td>{{ lastEntry.laStats?.initialAUC | number: "1.0-0" }}</td>
                  <td>AUC</td>
                </tr>
                <tr *ngIf="lastEntry.laStats?.totalAUC">
                  <th>Impact g-load</th>
                  <td>{{ lastEntry.laStats?.totalAUC | number: "1.0-0" }}</td>
                  <td>AUC</td>
                </tr>
                <tr *ngIf="lastEntry.avStats?.initialHigh">
                  <th>Maximum initial angular velocity</th>
                  <td>{{ lastEntry.avStats?.initialHigh | unitConvert | number: "1.0-0" }}</td>
                  <td>rad/s</td>
                </tr>
                <tr *ngIf="lastEntry.avStats?.totalHigh">
                  <th>Maximum angular velocity</th>
                  <td>{{ lastEntry.avStats?.totalHigh | unitConvert | number: "1.0-0" }}</td>
                  <td>rad/s</td>
                </tr>
                <tr *ngIf="lastEntry.avStats?.initialAUC">
                  <th>Initial accumulation of angular velocity</th>
                  <td>{{ lastEntry.avStats?.initialAUC | unitConvert | number: "1.0-0" }}</td>
                  <td>AUC</td>
                </tr>
                <tr *ngIf="lastEntry.avStats?.totalAUC">
                  <th>Accumulation of angular velocity</th>
                  <td>{{ lastEntry.avStats?.totalAUC | unitConvert | number: "1.0-0" }}</td>
                  <td>AUC</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header d-flex">
      <strong>Previous impacts</strong>
    </div>

    <div class="card-body">
      <table class="impact-history-table table table-responsive-md table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Force</th>
            <th scope="col">Angular velocity</th>
            <th scope="col">Sensor</th>
            <th scope="col">Index</th>
            <th scope="col">Impact Date</th>
            <th scope="col">Create Date</th>
            <th scope="col">Battery</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let entry of entries$ | async"
            [routerLink]="['entry', entry?.id]"
            [ngClass]="{ inactive: entry.active === false }">
            <td>{{ entry.laStats?.initialHigh || entry.force | number: "1.0-0" }}g</td>
            <td>
              {{ entry.avStats?.initialHigh | unitConvert | number: "1.0-0"
              }}<span *ngIf="entry.avStats?.initialHigh"> rad/s</span>
            </td>
            <td>{{ entry.sensorName }}</td>
            <td>#{{ entry.impactIdx }}</td>
            <td>{{ entry.impactDate.toDate() | date: "medium" }}</td>
            <td>{{ entry.created.toDate() | date: "medium" }}</td>
            <td>{{ entry.battery | number: "1.3-3" }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer d-flex justify-content-center">
      <button type="button" class="btn btn-link" (click)="fetchMore()">Fetch more...</button>
    </div>
  </div>
</div>
