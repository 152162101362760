import { Component } from "@angular/core";
import * as echarts from "echarts";

@Component({
  selector: "app-impact-timeline",
  templateUrl: "./impact-timeline.component.html",
  styleUrls: ["./impact-timeline.component.scss"]
})
export class ImpactTimelineComponent {
  constructor() {}

  ngOnInit() {
    // Generat esample data for timeline chart
    const data = this.generateSampleData(9);
    const chart = echarts.init(document.getElementById("impact-timeline"));

    // Define the chart options

    const options = {
      title: {
        text: "Impact-timeline",
        subtext: "Example Data"
      },
      tooltip: {
        trigger: "axis"
      },
      legend: {
        data: ["Impact"]
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          // prettier-ignore
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
      ],
      yAxis: [
        {
          type: "value"
        }
      ],
      series: [
        {
          name: "Rainfall",
          type: "bar",
          data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" }
            ]
          },
          markLine: {
            data: [{ type: "average", name: "Avg" }]
          }
        },
        {
          name: "Evaporation",
          type: "bar",
          data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
          markPoint: {
            data: [
              { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
              { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 }
            ]
          },
          markLine: {
            data: [{ type: "average", name: "Avg" }]
          }
        }
      ]
    };
    chart.setOption(options);
  }

  // Function to generate sample data with fluctuations
  private generateSampleData(count: number): number[] {
    const data = [];
    let value = 50; // Starting value

    for (let i = 0; i < count; i++) {
      // Simulate fluctuations by adding random values
      value += Math.random() * 20 - 10; // Random value between -10 and 10
      data.push(value);
    }

    return data;
  }
}
