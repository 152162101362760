<div class="container-lg">
  <div class="d-flex justify-content-between align-items-center">
    <a [routerLink]="'/dashboard'" class="navigation">
      <i class="bi bi-arrow-left"></i>
      Back to dashboard
    </a>
  </div>
  <div class="header text-center mt-4 mb-4">User Settings</div>

  <p>Settings are currently unavailable</p>
  <!--
  <form [formGroup]="settingsForm" (submit)="onSubmit()">

    <div class="container-md" *ngIf="user$ | async as user">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header d-flex">
              <div>
                <strong>User info</strong>
              </div>
              <div class="ml-auto">
                <button
                  [disabled]="userDetailsEditable"
                  class="btn btn-info"
                  (click)="toggleUserDetailsEditable()">
                  Edit
                </button>
              </div>
            </div>

            <div class="card-body">
              <div class="form-group row">
                <label for="name" class="col-4 col-form-label">Name</label>
                <div class="col-8">
                  <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                    [readonly]="!userDetailsEditable"
                    required />
                </div>
              </div>
              <div class="form-group row">
                <label for="email" class="col-4 col-form-label">Email</label>
                <div class="col-8">
                  <input
                    type="email"
                    formControlName="email"
                    class="form-control"
                    [readonly]="true" />
                </div>
              </div>

              <div class="form-group row">
                <label for="country" class="col-4 col-form-label">Country</label>
                <div class="col-8">
                  <select
                    type="text"
                    formControlName="country"
                    class="form-control"
                    id="country"
                    [disabled]="!userDetailsEditable">
                    <option *ngFor="let country of countries"
                              [value]="country.code">
                        {{country.name}} ({{country.code}})
                      </option>
                  </select>
                </div>
              </div>


                <div>
                  <button type="submit"
                          [disabled]="!userDetailsEditable || !userDetails.dirty"
                          class="btn btn-primary btn-block">Save</button>
                </div>

            </div>

            <div class="card-footer d-flex" *ngIf="userDetailsEditable">
              <div class="ml-left">
                <button
                  *ngIf="userDetailsEditable"
                  class="btn btn-warning"
                  (click)="toggleUserDetailsEditable(); settingsForm.markAsPristine()">
                  Cancel
                </button>
              </div>

              <div class="ml-auto">
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="!userDetailsEditable || settingsForm.pristine">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </form>
  -->

  <h2 class="text-center mt-4">User Account removal</h2>
  <p>
    Remove whole user account and it's data. Warning, you will lose all your account data and
    sensors are not activatable to another account.
    <button type="button" class="btn btn-link" routerLink="account-removal">
      Proceed to user account removal
    </button>
  </p>
</div>
