<div class="row mt-4">
  <div class="col">
    <ul class="navigation">
      <li>
        <a
          [ngClass]="{ active: selectedTimeFrame === TimeFrame.SEASON }"
          (click)="timeFrameSelected(TimeFrame.SEASON)"
          (keypress.enter)="timeFrameSelected(TimeFrame.SEASON)"
          tabindex="0">
          Season
        </a>
      </li>
      <!--<li> will be implemented later
        <a [ngClass]='{ "active": selectedTimeFrame===TimeFrame.MONTH }' (click)="timeFrameSelected(TimeFrame.MONTH)"
        (keypress.enter)="timeFrameSelected(TimeFrame.MONTH)"
        tabindex="0">
          Month
        </a>
      </li>
      <li>
        <a [ngClass]='{ "active": selectedTimeFrame===TimeFrame.WEEK }' (click)="timeFrameSelected(TimeFrame.WEEK)"
        (keypress.enter)="timeFrameSelected(TimeFrame.WEEK)"
        tabindex="0">
          Week
        </a>
      </li>-->
      <li>
        <a
          [ngClass]="{ active: selectedTimeFrame === TimeFrame.SESSION }"
          (click)="timeFrameSelected(TimeFrame.SESSION)"
          (keypress.enter)="timeFrameSelected(TimeFrame.SESSION)"
          tabindex="0">
          Session
        </a>
      </li>
    </ul>
  </div>
</div>

<!--SESSION LENGTH HERE -->
<div class="timeframe-selector" *ngIf="season">
  <div *ngIf="selectedTimeFrame === TimeFrame.SEASON">
    <i
      class="bi bi-arrow-left"
      tabindex="0"
      *ngIf="previousSeasonAvailable"
      (click)="previous()"
      (keypress.enter)="previous()"></i>
    {{ season.begin?.toDate() | date: "mediumDate" }} -
    {{ season.end?.toDate() | date: "mediumDate" }}
    <i
      class="bi bi-arrow-right"
      tabindex="0"
      *ngIf="nextSeasonAvailable"
      (click)="next()"
      (keypress.enter)="next()"></i>
  </div>

  <div *ngIf="selectedTimeFrame === TimeFrame.MONTH">
    <i class="bi bi-arrow-left" tabindex="0" (click)="previous()" (keypress.enter)="previous()"></i>
    {{ season.begin?.toDate() | date: "mediumDate" }} -
    {{ season.end?.toDate() | date: "mediumDate" }}
    <i class="bi bi-arrow-right" tabindex="0" (click)="next()" (keypress.enter)="next()"></i>
  </div>
  <!--
  <div *ngIf="selectedTimeFrame === TimeFrame.WEEK">
    <i class="bi bi-arrow-left" tabindex="0" (click)="previous()" (keypress.enter)="previous()"></i>
    {{ season.begin?.toDate() | date:'mediumDate' }} -  {{ season.end?.toDate() | date:'mediumDate' }}
    <i class="bi bi-arrow-right" tabindex="0" (click)="next()" (keypress.enter)="next()"></i>
  </div>
  -->
</div>
<div class="timeframe-selector" *ngIf="session">
  <div *ngIf="selectedTimeFrame === TimeFrame.SESSION">
    <i
      class="bi bi-arrow-left"
      tabindex="0"
      *ngIf="previousSessionAvailable"
      (click)="previous()"
      (keypress.enter)="previous()"></i>
    {{ session.begin?.toDate() | date: "medium" }} - {{ session.end?.toDate() | date: "medium" }}
    <i
      class="bi bi-arrow-right"
      tabindex="0"
      *ngIf="nextSessionAvailable"
      (click)="next()"
      (keypress.enter)="next()"></i>
  </div>
</div>

<div class="row mt-4 mb-2">
  <div class="col">
    <h1>Team Statistics</h1>
  </div>
  <!-- Will be implemented later <div class="col">
    <a routerLink="/teamstatistics">
      <i class="bi bi-arrow-right" tabindex="0"></i> View All
    </a>
  </div> -->
</div>

<div class="row">
  <div class="col-md-6 mb-4" style="height: 100">
    <app-impact-card [sessions]="filteredSessions"></app-impact-card>
  </div>
  <div class="col-md-6" style="height: 100">
    <app-strength-card [statistics]="statistics"></app-strength-card>
  </div>
  <!--<div class="col-md-4" >
    <app-trends-card style="height:100%"></app-trends-card>
  </div>-->
</div>

<div class="row mt-4 mb-2">
  <div class="col">
    <h1>Athletes</h1>
  </div>
</div>

<app-impact-list [entries]="entries"></app-impact-list>
