// Creating own auth guard to replace AngularFireAuthGuard

import { Injectable } from "@angular/core";
import { Auth, user } from "@angular/fire/auth";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { User } from "firebase/auth";
import { Observable, UnaryFunction, of, pipe } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

export type AuthPipeGenerator = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => AuthPipe;

export type AuthPipe = UnaryFunction<Observable<User | null>, Observable<boolean | string | any[]>>;

export const loggedIn: AuthPipe = map(user => !!user);

@Injectable({
  providedIn: "any"
})
export class CustomAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: Auth
  ) {}

  canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authPipeFactory = (next.data.authGuardPipe as AuthPipeGenerator) || (() => loggedIn);
    return user(this.auth).pipe(
      take(1),
      authPipeFactory(next, state),
      map(can => {
        console.log("can", can);
        if (typeof can === "boolean") {
          if (can) {
            return can;
          } else {
            // If not logged in => redirect to login with url we tried to.
            return this.router.createUrlTree(["/login"], { queryParams: { redirect: state.url } });
          }
        } else if (Array.isArray(can)) {
          return this.router.createUrlTree(can);
        } else {
          return this.router.parseUrl(can);
        }
      })
    );
  };
}

export const canActivate = (pipe: AuthPipeGenerator) => ({
  canActivate: [CustomAuthGuard],
  data: { authGuardPipe: pipe }
});

export const isNotAnonymous: AuthPipe = map(user => !!user && !user.isAnonymous);

export const idTokenResult = switchMap((user: User | null) =>
  user ? user.getIdTokenResult() : of(null)
);

export const emailVerified: AuthPipe = map(user => !!user && user.emailVerified);

export const customClaims = pipe(
  idTokenResult,
  map(idTokenResult => (idTokenResult ? idTokenResult.claims : []))
);

export const hasCustomClaim: (claim: string) => AuthPipe = claim =>
  pipe(
    customClaims,
    map(claims => claims.hasOwnProperty(claim)) // eslint-disable-line no-prototype-builtins
  );

export const redirectUnauthorizedTo: (redirect: string | any[]) => AuthPipe = redirect =>
  pipe(
    loggedIn,
    map(loggedIn => loggedIn || redirect)
  );

export const redirectLoggedInTo: (redirect: string | any[]) => AuthPipe = redirect =>
  pipe(
    loggedIn,
    map(loggedIn => (loggedIn && redirect) || true)
  );
