<div class="container-lg entry-page" *ngIf="entry$ | async as entry">
  <div class="row">
    <div class="col-12 col-sm-9">
      <div
        class="btn-group btn-block"
        btnRadioGroup
        [(ngModel)]="selectedChart"
        [disabled]="modelGroupDisabled">
        <button
          type="button"
          class="btn btn-primary mb-0"
          (keypress.enter)="buildDataset()"
          (click)="buildDataset()"
          btnRadio="Total">
          Total
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          (keypress.enter)="buildDataset()"
          (click)="buildDataset()"
          btnRadio="Linear acceleration">
          Linear acceleration
        </button>
        <!--<button type="button" class="btn btn-primary mb-0" btnRadio="Rotation acceleration">Rotation acceleration</button>-->
        <button
          type="button"
          class="btn btn-primary mb-0"
          (keypress.enter)="buildDataset()"
          (click)="buildDataset()"
          btnRadio="Angular velocity">
          Angular velocity
        </button>
      </div>
    </div>
    <div class="col-3 d-none d-sm-block">
      <div class="btn-group btn-block" btnRadioGroup [(ngModel)]="selectedAngleUnit">
        <button
          type="button"
          class="btn btn-primary mb-0"
          btnRadio="rad"
          (keypress.enter)="buildDataset()"
          (click)="buildDataset()">
          rad
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          btnRadio="deg"
          (keypress.enter)="buildDataset()"
          (click)="buildDataset()">
          deg
        </button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="accChart">
    <div class="col-12">
      <div class="card mt-2">
        <div class="card-body">
          <div echarts [options]="accChart" class="impact-chart"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md mt-4">
      <div class="entry-card card" style="height: 100%; min-height: 330px">
        <!--<div class="card-header d-flex">
          <strong>{{profile?.name}}</strong>
        </div>-->

        <div class="card-body d-flex flex-row justify-content-center">
          <div>
            <div class="force" *ngIf="entry?.force">
              {{ entry?.laStats?.initialHigh || entry?.force | number: "1.0-0"
              }}<span class="force-ind">g</span>
            </div>
            <div class="angular-force" *ngIf="entry?.avStats?.initialHigh">
              {{ entry?.avStats?.initialHigh | unitConvert | number: "1.0-0"
              }}<span class="angular-force-ind">rad/s</span>
            </div>
            <div class="timestamp">{{ entry?.impactDate | fbdate | date: "medium" }}</div>
          </div>
        </div>

        <!--<div class="card-footer d-flex justify-content-between">
          <div class="sensor-name">{{profile?.lastEntry?.sensorName}}</div>
          <div class="sport-name">{{profile?.lastEntry?.sport}}</div>
        </div>-->
      </div>
    </div>

    <div class="col-md mt-4" *ngIf="entry?.laStats || entry?.avStats">
      <div class="card" style="height: 100%">
        <!--<div class="card-header d-flex justify-content-between">
          <strong>#{{ entry?.impactIdx }} {{entry?.impactDate?.toDate() | date: 'medium'}}</strong>
          <strong>{{ entry?.gPrimeStats?.max || entry?.force | number: '1.0-0'}}g</strong>
          <strong><span *ngIf="entry?.aPrimeStats?.max">{{ entry?.aPrimeStats?.max | number: '1.0-0' }}rad/s</span></strong>
        </div>-->

        <div class="card-body">
          <table class="statistic-table table table-sm table-striped text-right">
            <tbody>
              <tr *ngIf="entry.laStats?.initialHigh">
                <th>Maximum initial <i>g</i>-force</th>
                <td>{{ entry.laStats?.initialHigh | number: "1.0-0" }}</td>
                <td><i>g</i>-force</td>
              </tr>
              <tr *ngIf="entry.laStats?.totalHigh">
                <th>Maximum <i>g</i>-force</th>
                <td>{{ entry.laStats?.totalHigh | number: "1.0-0" }}</td>
                <td><i>g</i>-force</td>
              </tr>
              <tr *ngIf="entry.laStats?.initialAUC">
                <th>Initial impact g-load</th>
                <td>{{ entry.laStats?.initialAUC | number: "1.0-0" }}</td>
                <td>AUC</td>
              </tr>
              <tr *ngIf="entry.laStats?.totalAUC">
                <th>Impact g-load</th>
                <td>{{ entry.laStats?.totalAUC | number: "1.0-0" }}</td>
                <td>AUC</td>
              </tr>
              <tr *ngIf="entry.avStats?.initialHigh">
                <th>Maximum initial angular velocity</th>
                <td>{{ entry.avStats?.initialHigh | unitConvert | number: "1.0-0" }}</td>
                <td>rad/s</td>
              </tr>
              <tr *ngIf="entry.avStats?.totalHigh">
                <th>Maximum angular velocity</th>
                <td>{{ entry.avStats?.totalHigh | unitConvert | number: "1.0-0" }}</td>
                <td>rad/s</td>
              </tr>
              <tr *ngIf="entry.avStats?.initialAUC">
                <th>Initial accumulation of angular velocity</th>
                <td>{{ entry.avStats?.initialAUC | unitConvert | number: "1.0-0" }}</td>
                <td>AUC</td>
              </tr>
              <tr *ngIf="entry.avStats?.totalAUC">
                <th>Accumulation of angular velocity</th>
                <td>{{ entry.avStats?.totalAUC | unitConvert | number: "1.0-0" }}</td>
                <td>AUC</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="entry?.owner === user?.uid">
    <div class="col mt-4">
      <div class="card">
        <!--<div class="card-header d-flex justify-content-between">
            <strong>#{{ entry?.impactIdx }} {{entry?.impactDate?.toDate() | date: 'medium'}}</strong>
            <strong>{{ entry?.gPrimeStats?.max || entry?.force | number: '1.0-0'}}g</strong>
            <strong><span *ngIf="entry?.aPrimeStats?.max">{{ entry?.aPrimeStats?.max | number: '1.0-0' }}rad/s</span></strong>
          </div>-->

        <div class="card-body">
          <div class="row">
            <div class="col-4">Flag as faulty impact</div>
            <div class="col-8">
              <div class="form-check" style="height: 30px">
                <input
                  class="form-check-input"
                  style="height: 20px; width: 20px"
                  [(ngModel)]="inactive"
                  type="checkbox"
                  (click)="activeClicked($event, !!inactive)" />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4">Notes</div>
            <div class="col-8">
              <textarea
                #notefield
                class="form-control"
                [(ngModel)]="notes"
                [ngModelOptions]="{ standalone: true }"
                (change)="notesChanged($event)"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card mt-4">
        <div class="card-body">
          <table class="table table-responsive-sm table-sm table-striped text-right">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" class="text-center" colspan="3">Acceleration (g)</th>
                <th scope="col" class="text-center" colspan="3">
                  Rotation {{ selectedAngleUnit }}/s
                </th>
                <!--<th scope="col" class="text-center" colspan=3>Abs Rotation Change {{ selectedAngleUnit }}/s&#178;</th>-->
              </tr>
              <tr>
                <th scope="col">Time (ms)</th>
                <th scope="col">x</th>
                <th scope="col">y</th>
                <th scope="col">z</th>
                <th scope="col">x</th>
                <th scope="col">y</th>
                <th scope="col">z</th>
                <!--<th scope="col">x</th>
                <th scope="col">y</th>
                <th scope="col">z</th>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of [].constructor(maxEntryCount); index as i">
                <th scope="row" style="border-right: 1px solid #777">{{ i }}</th>
                <td>{{ dataset?.linAcc?.[i]?.x }}</td>
                <td>{{ dataset?.linAcc?.[i]?.y }}</td>
                <td style="border-right: 1px solid #777">{{ dataset?.linAcc?.[i]?.z }}</td>
                <td>{{ dataset?.rotSpeed?.[i - (dataset?.rotTimeDiff ?? 0)]?.x }}</td>
                <td>{{ dataset?.rotSpeed?.[i - (dataset?.rotTimeDiff ?? 0)]?.y }}</td>
                <td style="border-right: 1px solid #777">
                  {{ dataset?.rotSpeed?.[i - (dataset?.rotTimeDiff ?? 0)]?.z }}
                </td>
                <!--<td>{{ dataset?.rotAcc?.[i - ((dataset?.rotTimeDiff ?? 0) + 1)]?.x }}</td>
                <td>{{ dataset?.rotAcc?.[i - ((dataset?.rotTimeDiff ?? 0) + 1)]?.y }}</td>
                <td>{{ dataset?.rotAcc?.[i - ((dataset?.rotTimeDiff ?? 0) + 1)]?.z }}</td>-->
              </tr>
              <tr style="border-top: 2px solid black">
                <th scope="row">Max</th>
                <th scope="col">{{ dataset?.linAccMax?.x }}</th>
                <th scope="col">{{ dataset?.linAccMax?.y }}</th>
                <th scope="col">{{ dataset?.linAccMax?.z }}</th>
                <th scope="col">{{ dataset?.rotSpeedMax?.x }}</th>
                <th scope="col">{{ dataset?.rotSpeedMax?.y }}</th>
                <th scope="col">{{ dataset?.rotSpeedMax?.z }}</th>
                <!--<th scope="col">{{ dataset?.rotAccMax?.x }}</th>
                <th scope="col">{{ dataset?.rotAccMax?.y }}</th>
                <th scope="col">{{ dataset?.rotAccMax?.z }}</th>-->
              </tr>
              <tr style="border-top: 2px solid black">
                <th scope="row">Avg</th>
                <th scope="col">{{ dataset?.linAccAvg?.x }}</th>
                <th scope="col">{{ dataset?.linAccAvg?.y }}</th>
                <th scope="col">{{ dataset?.linAccAvg?.z }}</th>
                <th scope="col">{{ dataset?.rotSpeedAvg?.x }}</th>
                <th scope="col">{{ dataset?.rotSpeedAvg?.y }}</th>
                <th scope="col">{{ dataset?.rotSpeedAvg?.z }}</th>
                <!--<th scope="col">{{ dataset?.rotAccAvg?.x }}</th>
                <th scope="col">{{ dataset?.rotAccAvg?.y }}</th>
                <th scope="col">{{ dataset?.rotAccAvg?.z }}</th>-->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
  </div>
</div>
