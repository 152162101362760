export interface IXYZ {
  x: number;
  y: number;
  z: number;
}

export class XYZ implements IXYZ {
  constructor(
    public x: number = 0.0,
    public y: number = 0.0,
    public z: number = 0.0
  ) {}

  public scale(scale: number) {
    return new XYZ(this.x * scale, this.y * scale, this.z * scale);
  }

  public squareSum(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }

  public degToRad(): XYZ {
    return this.scale(Math.PI / 180);
  }

  public round(digits = 0): XYZ {
    const mult = digits > 1 ? Math.pow(10, digits) : 1;
    return new XYZ(
      Math.round(this.x * mult) / mult,
      Math.round(this.y * mult) / mult,
      Math.round(this.z * mult) / mult
    );
  }
}

/**
 * Common XYZDataset used to handle measured XYZ values
 * Extends Array to get the major functionality from there
 */
export class XYZDataset extends Array<XYZ> {
  public static parseXYZs(view: DataView, offset: number, maxLength: number) {
    const result = new XYZDataset();
    for (let l1 = offset; l1 < Math.min(maxLength + offset, view.byteLength) - 5; l1 += 6) {
      const x = view.getInt16(l1, true);
      const y = view.getInt16(l1 + 2, true);
      const z = view.getInt16(l1 + 4, true);
      //console.log(`C: ${(l1-offset)} X: ${x} Y: ${y} Z: ${z}`)
      result.push(new XYZ(x, y, z));
    }
    return result;
  }

  public get x(): Array<number> {
    return this.map(xyz => xyz.x);
  }

  public get y(): Array<number> {
    return this.map(xyz => xyz.y);
  }

  public get z(): Array<number> {
    return this.map(xyz => xyz.z);
  }

  public scale(scale: number) {
    return new XYZDataset(...this.map(xyz => xyz.scale(scale)));
  }

  public squareSum(): Array<number> {
    return this.map(xyz => xyz.squareSum());
  }

  public round(digits = 0): XYZDataset {
    return new XYZDataset(...this.map(xyz => xyz.round(digits)));
  }

  public average(): XYZ | null {
    if (!this || this.length === 0) return null;
    const result = this.reduce((a, b) => {
      return new XYZ(a.x + b.x, a.y + b.y, a.z + b.z); // Sum the values together
    });
    // Divide result with lenght to get average
    return new XYZ(result.x / this.length, result.y / this.length, result.z / this.length);
  }

  public max(): XYZ | null {
    if (!this || this.length === 0) return null;
    return this.reduce(
      (prev, cur) => {
        return new XYZ(
          cur.x > prev.x ? cur.x : prev.x,
          cur.y > prev.y ? cur.y : prev.y,
          cur.z > prev.z ? cur.z : prev.z
        );
      },
      new XYZ(-Infinity, -Infinity, -Infinity)
    );
  }

  public min(): XYZ | null {
    if (!this || this.length === 0) return null;
    return this.reduce(
      (prev, cur) => {
        return new XYZ(
          cur.x < prev.x ? cur.x : prev.x,
          cur.y < prev.y ? cur.y : prev.y,
          cur.z < prev.z ? cur.z : prev.z
        );
      },
      new XYZ(Infinity, Infinity, Infinity)
    );
  }

  public degToRad(): XYZDataset {
    return new XYZDataset(...this.map(xyz => xyz.degToRad()));
  }

  /** Creates a Fixed Int Buffer from XYZDataset values with 1000 as default multiplicator */
  public toFixedIntBuffer(multiplicator = 1000): Buffer {
    const buf = Buffer.alloc(this.length * 3 * 4); // Twelve bytes for
    let offset = 0;
    this.forEach(xyz => {
      buf.writeInt32LE(Math.round(xyz.x * multiplicator), offset + 0);
      buf.writeInt32LE(Math.round(xyz.y * multiplicator), offset + 4);
      buf.writeInt32LE(Math.round(xyz.z * multiplicator), offset + 8);
      offset += 12;
    });
    return buf;
  }

  /** Constructs Dataset from Fixed Int Buffer with 1000 as default multiplicator */
  public static fromFixedIntBuffer(buf: Buffer, multiplicator = 1000): XYZDataset {
    const view = new DataView(buf.buffer.slice(buf.byteOffset, buf.byteOffset + buf.byteLength));
    const result = new XYZDataset();
    for (let l1 = 0; l1 < view.byteLength - 11; l1 += 12) {
      const x = view.getInt32(l1, true) / multiplicator;
      const y = view.getInt32(l1 + 4, true) / multiplicator;
      const z = view.getInt32(l1 + 8, true) / multiplicator;
      //console.log(`C: ${(l1-offset)} X: ${x} Y: ${y} Z: ${z}`)
      result.push(new XYZ(x, y, z));
    }
    return result;
  }
}
