import { Component, Input, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { User } from "@angular/fire/auth";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { IProfile, ISeason } from "src/act-common-web/src";
import { ITeam } from "src/act-common-web/src/models/team";
import { AuthService } from "src/app/services/auth.service";
import { ProfileService } from "src/app/services/profile.service";
import { ProfileState } from "src/app/state/profile-state";
import { TeamActions, TeamState } from "src/app/state/teams.state";
import { SeasonState } from "src/app/state/season.state";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-teams",
  templateUrl: "./teams.component.html",
  styleUrls: ["./teams.component.scss"]
})
export class TeamsComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef | undefined;

  @Select(ProfileState.list) profiles$?: Observable<Array<IProfile>>;
  @Select(TeamState.current) team$?: Observable<ITeam | undefined>;

  /*// Alert properties
  alerts: any[] = [];
  alertConfig: AlertConfig = {
    dismissible: true,
    type: 'success', // you can change this to 'info', 'warning', 'danger', etc.
    timeout: 5000, // auto-dismiss the alert after 5 seconds
  }; */

  seasons$?: Observable<Array<ISeason>>;

  teamId: string | undefined;
  editMode = false;
  editing = false;
  selectedProfiles: string[] = [];
  teamForm: FormGroup;
  destroy$ = new Subject();

  team?: ITeam;

  @Input() user?: User;

  constructor(
    private router: Router,
    private store: Store,
    public authService: AuthService,
    public profileService: ProfileService,
    public route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.route.params
      .pipe(
        tap(params => {
          this.teamId = params?.teamId;
          if (this.teamId) {
            this.store.dispatch(new TeamActions.Select(this.teamId));
            this.seasons$ = this.store.select(SeasonState.listByParent(this.teamId));
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Init Form
    this.teamForm = new FormGroup({
      name: new FormControl("", Validators.required),
      subtitle: new FormControl(""),
      //year: new FormControl("", [this.numericValidator]),
      description: new FormControl("")
    });
  }
  ngOnInit(): void {
    console.log("EditMode", this.editMode);
    this.team$
      ?.pipe(
        tap(team => {
          if (team) {
            this.team = team;
            this.editMode = true;
            this.editing = false;
            this.teamForm?.setValue({
              name: team?.name,
              subtitle: team?.subtitle,
              description: team?.description
            });
            this.selectedProfiles = this.team?.profileIds ?? [];
            this.teamForm.disable();
          } else {
            this.editMode = false;
            this.editing = false;
            this.teamForm.enable();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  /**
   * TeamForm Submit
   * Stores team either by create or update action
   */
  submit() {
    const values = this.teamForm?.value;

    if (!this.editMode) {
      this.store.dispatch(
        new TeamActions.Create(
          values.name,
          values.subtitle,
          values.description,
          this.selectedProfiles
        )
      );
      this.editing = false;
    } else if (this.team) {
      const team: ITeam = {
        ...this.team,
        name: values.name,
        subtitle: values.subtitle,
        description: values.description,
        profileIds: this.selectedProfiles
      };
      this.store.dispatch(new TeamActions.Update(team));
      this.editing = false;
    }
  }

  /**
   * If user wants to cancel creating team
   * @returns User back to dasboard page
   */
  cancel() {
    const confirmExit = confirm(
      "Are you sure you want to leave? Any unsaved changes will be lost."
    );
    if (confirmExit) {
      this.router.navigate(["../"]);
    } else {
      return;
    }
  }

  /**
   * Function to add and remove profiles from team
   */
  toggleMember(profileId: string) {
    this.selectedProfiles = [...this.selectedProfiles]; // Create a shallow copy
    const index = this.selectedProfiles.indexOf(profileId);

    if (index !== -1) {
      // Profile is selected, remove them
      this.selectedProfiles.splice(index, 1);
    } else {
      // Profile is not selected, add them
      this.selectedProfiles.push(profileId);
    }
  }

  /**
   * Removes whole team
   * TODO: Must have somekind of confirmation dialog
   * @memberof TeamsComponent
   */
  openModal(template: TemplateRef<any>) {
    if (this.teamId) {
      const config = {
        initialState: {
          teamId: this.teamId
        }
      };
      this.modalRef = this.modalService.show(template, config);
    }
  }

  deleteTeam() {
    if (this.teamId) {
      this.store.dispatch(new TeamActions.Delete(this.teamId));
      this.modalRef?.hide();
    }
  }
  /**
   * Returns relative link to back, if in editmode 2 steps if in create only one step
   */
  get backLink(): string {
    return this.editMode ? "/teams/" + this.teamId : "../";
  }

  seasonActive(season: ISeason): boolean {
    return !this.seasonEnded(season) && (season.begin?.toMillis() ?? 0) < Date.now();
  }

  seasonEnded(season: ISeason): boolean {
    return (season.end?.toMillis() ?? 0) < Date.now();
  }

  toggleEditMode() {
    this.editing = !this.editing;
    if (this.editing) {
      this.teamForm?.enable(); // Enable the form when toggling to edit mode
    } else {
      this.teamForm?.disable(); // Disable the form when toggling out of edit mode
    }
  }

  handleKeyDown(event: KeyboardEvent, template: TemplateRef<unknown>) {
    if (event.key === "Enter" || event.key === "Space") {
      // Trigger the same action as the click event
      this.openModal(template);
    }
  }
}
