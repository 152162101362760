import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { OnEvent } from "./events";
import { Router } from "@angular/router";
import { BaseState, BaseStateModel } from "./base-state";
import { ISeason } from "src/act-common-web/src";
import { SeasonService } from "../services/season.service";
import { StateOperator, append, compose, patch, updateItem } from "@ngxs/store/operators";
import { AuthService } from "../services/auth.service";
import { Timestamp, UpdateData } from "@angular/fire/firestore";
import { Guid } from "src/act-common-web/src/guid.utils";

//Actions
export namespace SeasonActions {
  export class Init {
    static readonly type = "[Season] Init";
    constructor() {}
  }

  export class Select {
    static readonly type = "[Season] Select";
    constructor(
      public teamId?: string,
      public seasonId?: string
    ) {}
  }

  export class Create {
    static readonly type = "[Season] Create";
    constructor(
      public teamId: string,
      public name: string,
      public startDate: Date,
      public endDate: Date,
      public redirect: boolean = true
    ) {}
  }

  export class Update {
    static readonly type = "[Season] Update";
    constructor(
      public id: string,
      public teamId: string,
      public name: string,
      public startDate?: Date,
      public endDate?: Date
    ) {}
  }

  export class Next {
    static readonly type = "[Season] Next";
    constructor() {}
  }

  export class Previous {
    static readonly type = "[Season] Previous";
    constructor() {}
  }

  export class Remove {
    static readonly type = "[Season] Remove Season";
    constructor(
      public teamId: string,
      public seasonId: string,
      public redirect: boolean = true
    ) {}
  }
}

/**
 * Nested model for saving season and session for team
 */

export interface SeasonStateModel extends BaseStateModel<ISeason> {
  list: ISeason[];
  current?: string;
  active: boolean;
  teamId: string | null;
}

@State<SeasonStateModel>({
  name: "season",
  defaults: {
    list: [],
    current: undefined,
    active: false,
    teamId: null
  }
})
@Injectable()
export class SeasonState extends BaseState<ISeason, SeasonStateModel> {
  constructor(
    private seasonService: SeasonService,
    public router: Router,
    private authService: AuthService,
    private store: Store
  ) {
    super();
  }

  @Selector()
  static list(state: SeasonStateModel): Array<ISeason> | null {
    return state.list;
  }

  @Selector()
  static current(state: SeasonStateModel) {
    return state.list.find(s => s.id === state.current);
  }

  /**
   * Selector for ongoing ISeason for selected team or undefined if not found.
   */
  @Selector()
  static ongoing(state: SeasonStateModel) {
    const now = new Date();
    return state.list.find(s => s.end && s.begin && s.begin.toDate() < now && s.end.toDate() > now);
  }

  static listByParent(parent: string) {
    return createSelector([SeasonState], (state: SeasonStateModel) => {
      return state.list
        .filter(s => s.parent === parent)
        .sort((a, b) => (a.begin?.toMillis() ?? 0) - (b.begin?.toMillis() ?? 0));
    });
  }

  /**
   * Lists pasts seasons for team, doesn't iclude seasons that will start in the future
   * @param parent
   * @returns
   */
  static pastSeasonsForTeam(parent: string) {
    return createSelector([SeasonState], (state: SeasonStateModel) => {
      return state.list
        .filter(s => s.parent === parent && (s.begin?.toMillis() ?? 0) < Date.now())
        .sort((a, b) => (a.begin?.toMillis() ?? 0) - (b.begin?.toMillis() ?? 0));
    });
  }

  /** Inits listening when something happens, Team is Selected? */
  @Action(OnEvent.UserLoggedIn)
  initSeasonState(
    ctx: StateContext<SeasonStateModel>
    //action: SeasonActions.Init
  ) {
    const state = ctx.getState();
    // Dispatch On Event Team selected on refresh case
    if (state.teamId) {
      ctx.dispatch(new OnEvent.TeamSelected(state.teamId));
    }
  }

  /** Inits listening when something happens, Team is Selected? */
  @Action(OnEvent.TeamSelected)
  onEventTeamSelected(ctx: StateContext<SeasonStateModel>, action: OnEvent.TeamSelected) {
    console.log("Season On Team Selected");
    // Patch the id
    const current = undefined;
    ctx.setState(
      patch({
        current,
        teamId: action.teamId
      })
    );
    if (action.teamId) {
      console.log("Start listening season changes for ", action.teamId);

      this.seasonService.startListenChanges(
        { teamId: action.teamId },
        seasons => {
          const state = ctx.getState();
          const listOperators: Array<StateOperator<Array<ISeason>>> = seasons.map(season => {
            return state.list.some(t => t.id === season.id)
              ? updateItem<ISeason>(t => t.id === season.id, season)
              : append<ISeason>([season]);
          });

          ctx.setState(
            patch({
              list: compose(...listOperators),
              teamId: action.teamId
            })
          );
        },
        100
      ); // 100 seasons is current limit
    }
  }

  @Action(SeasonActions.Select)
  selectSeason(ctx: StateContext<SeasonStateModel>, action: SeasonActions.Select): void {
    console.log("Season select called", action.seasonId);
    if (action.seasonId) {
      ctx.patchState({ teamId: action.teamId }); // Update teamId in the state
      this.select(ctx, action.seasonId);
      ctx.dispatch(new OnEvent.SeasonSelected(action.teamId, action.seasonId));
    }
  }

  @Action(SeasonActions.Create)
  create(ctx: StateContext<SeasonStateModel>, action: SeasonActions.Create): Promise<void> {
    //const state = ctx.getState();
    const uuid = this.authService.uuid;
    const teamId = action.teamId;

    const season: ISeason = {
      id: Guid.newGuid(),
      owner: uuid,
      sharedTo: [uuid],
      parent: teamId,
      created: Timestamp.now(),
      updated: Timestamp.now(),
      name: action.name,
      begin: Timestamp.fromDate(action.startDate),
      end: Timestamp.fromDate(action.endDate)
    };
    return this.seasonService.add(season, { teamId }).then(docRef => {
      console.log("Season id retrieved", docRef.id);
      season.id = docRef.id;
      this.modelUpdated(ctx, season);

      if (action.redirect) {
        console.log("SeasonId was found", docRef.id);
        this.router.navigate(["manage", teamId, "seasons", docRef.id]);
      }
    });
  }

  @Action(SeasonActions.Update)
  updateSeason(ctx: StateContext<SeasonStateModel>, action: SeasonActions.Update): Promise<void> {
    const fields: UpdateData<ISeason> = {
      name: action.name
    };
    // Handle Optionaly updated fields

    if (action.name) {
      fields.name = action.name;
    }

    if (action.startDate) {
      fields.begin = Timestamp.fromMillis(action.startDate.setUTCHours(0, 0, 0, 0)); // First ms of day
    }
    if (action.endDate) {
      fields.end = Timestamp.fromMillis(action.endDate.setUTCHours(23, 59, 59, 999)); // Last ms of day
    }
    return this.seasonService.update(action.id, { teamId: action.teamId }, fields).then(result => {
      this.partialUpdate(ctx, action.id, oldSeason => {
        const result = {
          ...oldSeason,
          name: fields.name as string
        };
        if (fields.end) {
          result.end = fields.end as Timestamp;
        }
        if (fields.begin) {
          result.begin = fields.begin as Timestamp;
        }
        return result;
      });
    });
  }

  @Action(SeasonActions.Next)
  nextSeason(
    ctx: StateContext<SeasonStateModel>
    //action: SeasonActions.Next
  ): void {
    const state = ctx.getState();
    const current = state.list?.find(s => s.id === state.current);
    if (state?.current && state.list.length > 1) {
      const newSeason = state.list
        ?.filter(s => (s.end?.toMillis() ?? 0) > (current?.end?.toMillis() ?? 0))
        .sort((a, b) => (a.end?.toMillis() ?? 0) - (b.end?.toMillis() ?? 0))?.[0];
      if (newSeason) {
        return this.selectSeason(
          ctx,
          new SeasonActions.Select(state.teamId ?? undefined, newSeason.id)
        );
      }
    }
  }

  @Action(SeasonActions.Previous)
  previousSeason(ctx: StateContext<SeasonStateModel>, action: SeasonActions.Previous): void {
    const state = ctx.getState();
    const current = state.list?.find(s => s.id === state.current);
    if (state?.current && state.list.length > 1) {
      const newSeason = state.list
        ?.filter(s => (s.end?.toMillis() ?? 0) < (current?.end?.toMillis() ?? 0))
        .sort((a, b) => (b.end?.toMillis() ?? 0) - (a.end?.toMillis() ?? 0))?.[0];
      if (newSeason) {
        return this.selectSeason(
          ctx,
          new SeasonActions.Select(state.teamId ?? undefined, newSeason.id)
        );
      }
    }
  }

  // Delete season and update list
  @Action(SeasonActions.Remove)
  async remove(ctx: StateContext<SeasonStateModel>, action: SeasonActions.Remove) {
    const state = ctx.getState();
    const teamId = action.teamId;
    const seasonId = action.seasonId;

    await this.seasonService.remove(seasonId, { teamId });

    // Update the state by filtering out the removed season
    const updatedList = state.list.filter(season => season.id !== seasonId);

    // Update the state with the updated list
    ctx.patchState({
      list: updatedList
    });

    if (action.redirect) {
      this.router.navigate(["manage", teamId]);
    }
  }
}
