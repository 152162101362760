<ng-container *ngIf="profiles$ | async as profiles">
  <ng-container *ngIf="teams$ | async as teams">
    <div class="container">
      <!-- Conditional display based on 'showTeamIntro' -->
      <div class="card mt-4" *ngIf="!teams?.length && profiles.length">
        <div class="card-body">
          <h1 class="card-title">Introduction to new teams feature!</h1>
          <p class="card-text">
            Try our new <b>BETA</b> feature. Start creating teams from profiles you have access to.
            This feature is available for everyone for limited time to try. Eventually accessing
            Teams and their data will require subscription.
          </p>
          <button routerLink="/manage" class="btn btn-primary">Begin</button>
        </div>
      </div>

      <!-- Add white headers for teams and profiles -->
      <ng-container *ngIf="teams.length > 0">
        <h1 class="mt-4">Your Teams</h1>
        <div class="row mt-4">
          <div
            class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4"
            *ngFor="let team of teams$ | async">
            <app-team-card [team]="team"></app-team-card>
          </div>

          <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4">
            <app-newteamcard></app-newteamcard>
          </div>
        </div>
      </ng-container>

      <h1 class="mt-4">Your Profiles</h1>
      <div class="row mt-4">
        <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12 mb-4" *ngFor="let profile of profiles">
          <app-profile-card [profile]="profile"> </app-profile-card>
        </div>
      </div>
      <div *ngIf="!profiles.length">
        You don't seem to have any profiles. Please install <b>ACT Head Impact Tracker</b> android
        application. Then create profile and add sensor for the profile.
      </div>
    </div>
  </ng-container>
</ng-container>
