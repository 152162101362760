import { Select, Store } from "@ngxs/store";
import { IProfileStatistics } from "./../../../../../../act-common-web/src/models/statistics/profile-statistics";
import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { ISeason, Statistics } from "src/act-common-web/src";
import { ProfileState } from "src/app/state/profile-state";
import { ActivatedRoute, Router } from "@angular/router";
import { TeamActions } from "src/app/state/teams.state";
import { SeasonState } from "src/app/state/season.state";

export enum PlayerListSortColumn {
  LATEST = "latest",
  HEAVIEST = "heaviest",
  HIGHEST_AV = "highestav",
  MOST = "most"
}

@Component({
  selector: "app-player-list",
  templateUrl: "./player-list.component.html",
  styleUrls: ["./player-list.component.scss"]
})
export class PlayerListComponent implements OnChanges, OnDestroy {
  @Input() statistics?: Statistics;

  shownStatistics?: Array<IProfileStatistics>;
  teamId?: string;
  private destroy$ = new Subject();

  public PlayerListSortColumn = PlayerListSortColumn; // Export to template
  profiles$: Observable<Array<IProfileStatistics>> | undefined;
  @Select(ProfileState.list) profile$?: Observable<Array<IProfileStatistics>>;
  @Select(SeasonState.current) season$?: Observable<ISeason>;
  sortBy: PlayerListSortColumn = PlayerListSortColumn.LATEST;

  constructor(
    private router: Router,
    public store: Store,
    public route: ActivatedRoute
  ) {
    this.route.params
      .pipe(
        tap(params => {
          this.teamId = params.teamId;
          this.store.dispatch(new TeamActions.Select(this.teamId));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnChanges(): void {
    // Make sure that sort order is refreshed
    this.refresh();
  }

  navClicked(column: PlayerListSortColumn) {
    this.sortBy = column;
    this.refresh();
  }

  refresh() {
    this.shownStatistics = [];

    for (const key in this.statistics?.profiles) {
      const profileStatistics: IProfileStatistics = {
        id: key,
        name: this.statistics.profiles[key].name,
        playerNumber: this.statistics.profiles[key].playerNumber,
        latestImpact: this.statistics.profiles[key].latestImpact,
        statistics: this.statistics.profiles[key].statistics, // Access the 'statistics' property directly
        entries: this.statistics.profiles[key].entries
      };

      this.shownStatistics.push(profileStatistics);
    }

    if (this.sortBy === PlayerListSortColumn.LATEST) {
      this.shownStatistics = this.shownStatistics.sort((a, b) => {
        return (
          (b.statistics?.lastTime?.toMillis() ?? 0) - (a.statistics?.lastTime?.toMillis() ?? 0)
        );
      });
    } else if (this.sortBy === PlayerListSortColumn.HEAVIEST) {
      this.shownStatistics = this.shownStatistics.sort((a, b) => {
        return this.maxValue(b.statistics?.maxForce) - this.maxValue(a.statistics?.maxForce);
      });
    } else if (this.sortBy === PlayerListSortColumn.HIGHEST_AV) {
      this.shownStatistics = this.shownStatistics.sort((a, b) => {
        return this.maxValue(b.statistics?.maxAv) - this.maxValue(a.statistics?.maxAv);
      });
    } else if (this.sortBy === PlayerListSortColumn.MOST) {
      this.shownStatistics = this.shownStatistics.sort((a, b) => {
        return (b.statistics?.totalCount ?? 0) - (a.statistics?.totalCount ?? 0);
      });
    }
  }

  maxValue(values: Array<number> | undefined): number {
    let result: number | undefined = undefined;
    if (values !== undefined) {
      for (const val of values) {
        if (result === undefined || val > result) {
          result = val;
        }
      }
    }
    return result ?? 0; // No need to support negative values
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
