import { Injectable } from "@angular/core";
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  getDocs,
  query,
  where
} from "@angular/fire/firestore";
import { IDocument } from "src/act-common-web/src/models/document";
import { from } from "rxjs";
import { BaseModelService } from "./base-model.service";

@Injectable({
  providedIn: "root"
})
export class DocumentService extends BaseModelService<IDocument, undefined> {
  protected collectionName = "docs";

  protected converter: FirestoreDataConverter<IDocument> = {
    toFirestore(document: IDocument): DocumentData {
      return document;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IDocument {
      const data = snapshot.data(options)!;
      return {
        ...BaseModelService.fromBaseModelFields(snapshot, options),
        docType: data["docType"],
        content: data["content"],
        title: data["title"],
        mimeType: data["mimeType"],
        language: data["language"],
        region: data["region"],
        hasAcceptance: data["hasAcceptance"],
        requiresAcceptance: data["requiresAcceptance"],
        shownOnStartup: data["shownOnStartup"]
      };
    }
  };

  protected extractCollectionPath(parentIds: undefined): string[] {
    return [this.collectionName];
  }

  public async listQueryDocuments(): Promise<IDocument[]> {
    const collectionRef = collection(this.afs, this.collectionName);

    const q = await getDocs(collectionRef);
    const documents: IDocument[] = [];

    q.forEach(doc => {
      const data = doc.data() as IDocument;
      documents.push(data);
    });
    console.log("Documents in listQueryDocuments", documents);

    return documents; // Return the array of documents
  }

  public fetchDocuments() {
    return from(this.listQueryDocuments());
  }
}
