import { Component } from "@angular/core";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-export-page",
  templateUrl: "./export-page.component.html",
  styleUrls: ["./export-page.component.scss"]
})
export class ExportPageComponent {
  constructor(public authService: AuthService) {}
}
