<!-- TODO: how to customize coloring? -->
<nav class="navbar sticky-top navbar-expand-sm navbar-dark">
  <!-- Brand as logo: ACT -->
  <a class="navbar-brand" routerLink="/">
    <img src="/assets/drawables/ab_logo.png" alt="ACT-logo" height="42" />
  </a>
  <!-- <a class="navbar-brand" routerLink="/dashboard">ACT-dashboard</a> -->
  <!--  <a class="navbar-brand" href="#">ACT-dashboard</a> -->

  <!-- links in the left -->
  <ul class="navbar-nav navbar-brand ml-auto pill">
    <ng-container *ngIf="authService.isAuthenticated">
      <li
        class="nav-item pill d-none d-sm-flex"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="/dashboard">Dashboard</a>
      </li>
      <ng-container *ngIf="profile$ | async as profile">
        <li
          class="nav-item pill"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link" [routerLink]="['dashboard', profile.id]"
            ><span>{{ profile.name }}</span></a
          >
        </li>
        <li
          class="nav-item pill"
          *ngIf="entry$ | async as entry"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link" [routerLink]="['dashboard', profile.id, 'entry', entry.id]"
            >#{{ entry.impactIdx }}</a
          >
        </li>
      </ng-container>

      <li
        class="nav-item pill"
        routerLinkActive="active d-block"
        [routerLinkActiveOptions]="{ exact: true }"
        style="display: none">
        <a class="nav-link" [routerLink]="['/teams']"><span>New Team</span></a>
      </li>
      <ng-container *ngIf="currentTeam$ | async as team">
        <li
          class="nav-item pill"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link" [routerLink]="['teams', team?.id]"
            ><span>{{ team?.name }}</span></a
          >
        </li>
        <ng-container *ngIf="currentSeason$ | async as season">
          <li
            class="nav-item pill"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="season">
            <a class="nav-link" [routerLink]="['manage', team?.id, 'seasons', season?.id]">
              <span>{{ season.name }}</span>
            </a>
          </li>
          <ng-container *ngIf="currentSession$ | async as session">
            <li
              class="nav-item pill"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              *ngIf="session">
              <a
                class="nav-link"
                [routerLink]="['manage', team?.id, 'seasons', season?.id, 'sessions', session?.id]">
                <span>{{ session.name }}</span>
              </a>
            </li>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="playerName">
          <li
            class="nav-item pill"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['teams', team?.id, playerId]">
              <span>{{ playerName }}</span>
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>

  <!--<ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link" href="#">Date</a>
    </li>
  </ul>-->

  <!-- links on the right -->
  <ul class="navbar-nav ml-auto">
    <li class="nav-item btn-group" dropdown placement="bottom right">
      <button
        id="button-alignment"
        dropdownToggle
        type="button"
        id="user-menu-button"
        class="nav-item btn dropdown-toggle"
        routeLinkActive="activeButton"
        aria-controls="dropdown-alignment">
        <span *ngIf="!authService.isAuthenticated"> My ACT <span class="caret"></span> </span>
        <span *ngIf="authService.currentUser | async as user">
          <span class="d-none d-md-inline">{{ user.email }}</span
          ><span class="caret"></span>
        </span>
      </button>

      <ul
        id="dropdown-alignment"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-alignment">
        <ng-container *ngIf="!authService.isAuthenticated">
          <li role="menuitem">
            <a class="dropdown-item" routerLink="/login">Login</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" routerLink="/register">Register</a>
          </li>
        </ng-container>

        <ng-container *ngIf="authService.isAuthenticated">
          <li role="menuitem">
            <a class="dropdown-item" routerLink="/settings">My Account</a>
          </li>
        </ng-container>

        <li class="divider dropdown-divider"></li>
        <li role="menuitem" *ngFor="let doc of documents$ | async">
          <a class="dropdown-item" [routerLink]="['documents', doc.id]">{{ doc.title }}</a>
        </li>

        <li role="menuitem" *ngIf="authService.isAuthenticated">
          <a class="dropdown-item" routerLink="/export">Export Tool</a>
        </li>

        <!--<li role="menuitem">
          <a class="dropdown-item" routerLink="/about">About</a>
        </li>-->
        <ng-container *ngIf="authService.isAuthenticated">
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <a class="dropdown-item" href="#" (click)="authService.logout()">Logout</a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>

  <!--
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link" href="#">Link2</a>
    </li>
  </ul>
--></nav>
