import { Pipe, PipeTransform } from "@angular/core";
import { Timestamp } from "@angular/fire/firestore";

@Pipe({
  name: "fbdate"
})
export class FbdatePipe implements PipeTransform {
  transform(timestamp: Timestamp | undefined): Date | null {
    if (timestamp) {
      return timestamp.toDate();
    } else {
      return null;
    }
  }
}
