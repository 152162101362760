import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { IProfileStatistics, ISeason, ISession } from "src/act-common-web/src/models";
import { SeasonState } from "src/app/state/season.state";
import { SessionState } from "src/app/state/session.state";
import { TeamActions } from "src/app/state/teams.state";

@Component({
  selector: "app-player-page",
  templateUrl: "./player-page.component.html",
  styleUrls: ["./player-page.component.scss"]
})
export class PlayerPageComponent implements OnDestroy {
  //OnInit ?

  seasons$?: Observable<Array<ISeason>>;
  @Select(SessionState.active) session$?: Observable<ISession | undefined>;
  @Select(SessionState.next) nextSession$?: Observable<ISession | undefined>;
  @Select(SeasonState.current) currentSeason$?: Observable<ISeason | undefined>;

  teamId: string | undefined;
  seasonId: string | undefined;

  shownStatistics?: IProfileStatistics;
  destroy$ = new Subject();

  playerId = "";

  playerName?: string;

  constructor(
    private route: ActivatedRoute,
    public store: Store
  ) {
    this.route.params
      .pipe(
        tap(params => {
          this.playerId = params.playerId;
          this.teamId = params.teamId;
          this.store.dispatch(new TeamActions.Select(this.teamId));
          // Select all seasons for Team
          if (this.teamId) {
            this.seasons$ = this.store.select(SeasonState.listByParent(this.teamId));
            /*Go trough seasons, to find whether our playerId is there to get name*/
            this.seasons$.subscribe(seasons => {
              seasons.forEach(season => {
                const player = season.seasonStats?.profiles[this.playerId];
                this.playerName = player?.name;
              });
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  //ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
